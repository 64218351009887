import firebase from "firebase/app";
import React from "react";

// Components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReactComponent as DownloadIcon } from "images/icons/icon-download.svg";
import { Stack, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import {
  YoodliCtaModal,
  YoodliCtaModalState,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { OrgProgramsQueryKeys } from "./OrgPrograms";
import { ProgramUserTable } from "./ProgramUserTable";
import { useMutation, useQuery as useApiQuery } from "@tanstack/react-query";
import { stringify as csvStringify } from "csv-stringify/sync";
import { saveAs } from "file-saver";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgProgramUsers, sendOrgProgramReminder } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseProgramProgressDate } from "lib-frontend/utils/orgUtils";
import { useIsMediumScreen, useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ProgramResponse, SendProgramReminderAction } from "lib-fullstack/api/programApiTypes";
import { CSV_STRINGIFY_DEFAULT_OPTIONS } from "lib-fullstack/utils/constants";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { ProgramState } from "lib-fullstack/utils/enums";

type ProgramMembersProps = {
  program: ProgramResponse;
};

export const ProgramMembers = ({ program }: ProgramMembersProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const [searchText, setSearchText] = React.useState<string>("");
  const [reminderModalState, setReminderModalState] =
    React.useState<YoodliCtaModalState>(undefined);
  const [previewSent, setPreviewSent] = React.useState<boolean>(false);

  const sendProgramReminderMutation = useMutation({
    mutationFn: (action: SendProgramReminderAction) =>
      sendOrgProgramReminder(defaultOrg.id, program.id, { action: action }),
  });

  const programMembersTotalQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ProgramUsers, defaultOrg.id, program.id],
    queryFn: () => listOrgProgramUsers(defaultOrg.id, program.id, { limit: "1" }),
  });

  const handleSendPreview = () => {
    sendProgramReminderMutation.mutate(SendProgramReminderAction.SendPreview);
    setPreviewSent(true);
    setTimeout(() => setPreviewSent(false), 3000);
  };

  const exportToCSV = async () => {
    const data = (await listOrgProgramUsers(defaultOrg.id, program.id, { limit: "1000" })).users;

    const rows = data.map((item) => ({
      Name: item.name,
      Email: item.email,
      "Last progress": parseProgramProgressDate(item),
      "Scenarios completed": `${item.num_steps_completed}/${program.plan_steps.length}`,
      "Minutes recorded": `${Math.round(item.total_seconds_attempts / 60)}`,
      "Overall attempts": `${item.num_attempt_started}`,
    }));

    const csv = csvStringify(rows, CSV_STRINGIFY_DEFAULT_OPTIONS);
    saveAs(new Blob([csv], { type: "text/csv;charset=utf-8" }), "program_users.csv");
  };

  const memberControls = [
    {
      title: "Send a reminder",
      icon: <NotificationsIcon style={{ width: "18px", height: "18px" }} />,
      onClick: () => setReminderModalState(YoodliCtaModalState.Cta),
    },
    {
      title: "Download CSV",
      icon: <DownloadIcon style={{ width: "18px", height: "18px" }} />,
      onClick: exportToCSV,
    },
  ];

  if (programMembersTotalQuery.isLoading) {
    return <CircularProgress />;
  } else if (programMembersTotalQuery?.data?.total === 0) {
    if (program?.state === ProgramState.Draft) {
      return null;
    }
    return (
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "poppins",
        }}
      >
        No users found
      </Typography>
    );
  }

  const renderMemberControls = () => {
    if (isSmallScreen) {
      return (
        <YoodliMenu
          type={YoodliMenuButtonType.Icon}
          menuItems={memberControls.map((control) => ({
            title: control.title,
            onClick: control.onClick,
            type: YoodliMenuItemType.Default,
          }))}
        />
      );
    } else if (isMediumScreen) {
      return memberControls.map((control) => (
        <YoodliTooltip key={control.title} title={control.title}>
          <IconButton
            sx={{ width: "24px", height: "24px", p: 0, color: getDynamicColor("primary") }}
          >
            {control.icon}
          </IconButton>
        </YoodliTooltip>
      ));
    } else {
      return memberControls.map((control) => (
        <Button
          key={control.title}
          onClick={control.onClick}
          startIcon={control.icon}
          sx={{
            whiteSpace: "nowrap",
            textDecoration: "underline",
            fontSize: "14px",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {control.title}
        </Button>
      ));
    }
  };

  return (
    <>
      <Stack gap={4}>
        <Stack
          direction="row"
          gap={1}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontFamily: "poppins",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            All members
          </Typography>
          <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
            {renderMemberControls()}
            <YoodliSearchBar
              value={searchText}
              label="Search by email"
              onChange={(e) => setSearchText(e.target.value)}
              clearSearch={() => setSearchText("")}
              sx={{ minWidth: { xs: "150px", md: "250px" } }}
            />
          </Stack>
        </Stack>
        <ProgramUserTable program={program} searchText={searchText} />
      </Stack>
      <YoodliCtaModal
        open={!!reminderModalState}
        close={() => setReminderModalState(undefined)}
        state={reminderModalState}
        setState={setReminderModalState}
        ctaBody={{
          title: "Send a reminder to all members",
          subtitle: (
            <Stack gap={3}>
              <Typography>
                You are about to send a reminder email to all{" "}
                {programMembersTotalQuery?.data?.total} program members. Preview it by sending a
                copy to your email: {firebase.auth().currentUser?.email}
              </Typography>
              <Button
                onClick={handleSendPreview}
                disabled={previewSent}
                startIcon={
                  previewSent && <CheckCircleIcon sx={{ color: getDynamicColor("greenSuccess") }} />
                }
                sx={{ px: previewSent ? 1 : 0, py: 0 }}
              >
                {previewSent ? "Preview sent" : "Send preview"}
              </Button>
            </Stack>
          ),
        }}
        successBody={{
          title: "Reminder email sent",
          subtitle: "The email has been sent to all group members",
        }}
        buttons={{
          primary: {
            text: "Send to members",
            handler: () => {
              sendProgramReminderMutation.mutate(SendProgramReminderAction.Send);
              setReminderModalState(YoodliCtaModalState.Success);
            },
          },
          secondary: {
            text: "Cancel",
            handler: () => setReminderModalState(undefined),
          },
        }}
      />
    </>
  );
};
