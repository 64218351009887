import "./wdyr"; // <--- must be first import (Why Did You Render? pkg for tracking unnecessary re-renders)

import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";

// Utils
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { envConfig } from "./utils/Constants";
import * as Sentry from "@sentry/react";
import {
  getSiteId,
  getStaticFullSiteConf,
  pLiveSiteDocsReady,
} from "lib-frontend/utils/LiveSiteDocs";
import { sharedFrontendSentryErrorsToIgnore, getAppVersion } from "lib-fullstack/client_env";
import { sanitizeForLogging } from "lib-fullstack/utils/logging";
import * as LogRocket from "logrocket";
import * as sprintf from "tiny-sprintf";

// Annoying typescript  'module augmentation' needed here,
// see https://mui.com/customization/theming/#custom-variables
declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    // mixins: {
    customToolbar: { minHeight: number };
    // };
  }
}

let logCallbackFpr: (message: string) => void;
export function setLogCallbackFpr(callback: (message: string) => void): void {
  logCallbackFpr = callback;
}

const urlParams = new URLSearchParams(window.location.search);

pLiveSiteDocsReady
  .then(() => {
    document.title = getStaticFullSiteConf().title ?? document.title;
  })
  .catch(console.error);

// "Sentry Environment" helps us to filter errors between different environmens,
//  e.g., only receive alerts on Production errors.
console.log("Environment: " + envConfig.envName);
console.log("Release: " + getAppVersion());
if (envConfig.envName === "local") {
  console.log("Localhost detected; Sentry is disabled.");
} else {
  Sentry.init({
    dsn: "https://c740e80f5939498e8ef097ef42b81a07@o1093573.ingest.sentry.io/6112898",
    integrations: [Sentry.browserTracingIntegration({})],
    environment: envConfig.envName,
    ignoreErrors: sharedFrontendSentryErrorsToIgnore,
    release: getAppVersion(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Disable sentry logs for localhost b/c developer can just see the errors
    //  in the console, and they're often one-off.
    beforeSend: (event) => {
      // Don't send events for local dev, dev server, staging server
      if (
        envConfig.envName === "local" ||
        envConfig.envName === "development" ||
        envConfig.envName === "staging"
      ) {
        return null;
      }

      event.extra = event.extra ?? {};
      event.extra["siteId"] = getSiteId("unknown-site-id");

      // Try to attach a logrocket session link to each error.
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        event.extra["LogRocket"] = logRocketSession;
      }

      return event;
    },
  });
}
// Google Tag Manager (https://www.npmjs.com/package/react-gtm-module)
const tagManagerArgs = { gtmId: envConfig.gtmContainerId };
TagManager.initialize(tagManagerArgs);

/**
 * In Production, mute all console output to console.log() and console.error().
 * However, *do* add logs as debug-level breadcrumbs that Sentry can upload.
 *
 * As an exception, allow a "debug=true" query param override if we need logging in the field
 */
let debugLoggingForceEnabled = false;
if (urlParams?.get("debug")?.toLowerCase() === "true") {
  debugLoggingForceEnabled = true;
}

const isLocal = window?.location?.host.includes("localhost:");

const cc = { ...console };
const oldLogFxn = console.log;
cc.log = (arg, ...args) => {
  if (envConfig.envName !== "production" || debugLoggingForceEnabled) {
    // if not prod, then log to console; otherwise, is muted.
    oldLogFxn.bind(console)(arg, ...args);
  }
  // log messages to our logging integrations.
  let payload: string;
  if (typeof arg === "string" || arg instanceof String) {
    // pass strings along unchanged
    payload = arg as string;
  } else {
    // JSON-ify non-string arguments.
    payload = JSON.stringify(arg);
  }

  if (!isLocal) {
    Sentry.addBreadcrumb({
      category: "console.log",
      level: "debug",
      message: payload,
    });
    LogRocket.log(payload);
  }

  logCallbackFpr?.(payload);
};

if (envConfig.envName === "production" && !debugLoggingForceEnabled) {
  // in prod, don't actually print error messages to console; just do the
  //  sentry + logrocket + GA reporting below.
  cc.error = () => {
    return;
  };
}
window.console = cc; // needed to override console.log

if (!isLocal) {
  /** Replace console.error with a custom fxn that:
   * 1) Calls Sentry.captureException and LogRocket.captureException, since
   *    otherwise I think those services only see unhandled exceptions.
   * 2) Logs a 'x_error' event to Google Analytics
   * 3) Creates an error object so that we get a stack trace.
   * When we do (3), we might lose the line number from where it was thrown,
   * so it's probably best to do console.error(new Error(...));
   */
  (() => {
    const oldErrorFxn = console.error;
    console.error = function (err, ...args) {
      if (err instanceof Error) {
        // send error to sentry
        Sentry.captureException(err);
        // Logrocket:
        LogRocket.captureException(err);
      } else {
        let errTxt;
        if (typeof err === "string" || err instanceof String) {
          // create a new error (to get stack trace) and send to Sentry
          //  sprintf emulates `console.log` functionality by formatting
          //  the `err` string with `args`. This prevents unhelpful error
          //  messages with chars like '%s' in them.

          // sanitize the arguments to prevent log injection attacks
          const argsSanitized = args.map((arg) => {
            if (typeof arg === "string" || arg instanceof String) {
              return sanitizeForLogging(arg.toString());
            } else {
              return arg;
            }
          });

          errTxt = sprintf(err, ...argsSanitized);
        } else {
          // Unknown object - let's JSON-ify and limit length to 1k chars.
          errTxt = JSON.stringify(err);
        }
        const err2 = new Error(errTxt.substring(0, 1000));
        Sentry.captureException(err2);
        // Logrocket:
        LogRocket.captureException(err2);
        logCallbackFpr?.(errTxt);
      }
      oldErrorFxn.bind(console)(err, ...args);
    };
  })();
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
