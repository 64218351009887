import firebase from "firebase/app";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// Components
import AuthInput from "../components/AuthInput";
import { Box, Stack, Button, Typography, Divider, CircularProgress } from "@mui/material";
import AuthWrapper from "auth/components/AuthWrapper";
import OAuthButtonGroup from "auth/components/OAuthButtonGroup";
import TermsAndPrivacyAgreementCopy from "auth/components/TermsAndPrivacyAgreementCopy";

// Assets
import { ReactComponent as EmailIcon } from "../../images/icons/WorkEmail.svg";

// Utils
import authUrls from "../../auth/config/authUrls";
import {
  getSignInSignUpReturnPath,
  historyPush,
  mergeReturnPathAndSearchParameters,
} from "../../utils/Utilities";
import { signInWithCustomToken, signInWithEmail } from "../providers/EmailProvider";
import { signInWithOAuthProvider } from "../providers/OAuthProvider";
import {
  completeUserLogin,
  generateAuthTokenForPoodliAndClose,
  fetchUrlSearchParams,
  ORG_ACCESS_INFO_QUERY_KEY,
  checkSsoMembershipAndUpdateDefaultOrg,
  SSO_MEMBERSHIP_ERROR_MESSAGE,
} from "../utils";
import { validateEmail } from "../utils/validator";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  userAuthenticated,
  getSignInOptions,
  optimisticSendVerificationEmail,
  getOrgAccessInfo,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { userDocsService } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import useExternalScript from "lib-frontend/hooks/useExternalScript";
import {
  AuthQueryParams,
  HubsInviteRequiredQueryParams,
  OrgInviteQueryParams,
  ReferralProgramQueryParams,
} from "lib-frontend/utils/queryParams";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM,
  KFA_PROVIDER,
  KFA_SIGN_IN_REDIRECT,
  ACCEPT_TOS_QUERY_PARAM,
} from "lib-fullstack/utils/auth";
import {
  YOODLI_INDEED_HUID,
  YOODLI_REFERRAL_PROGRAM,
  YOODLI_REFERRER,
} from "lib-fullstack/utils/constants";
import { AuthProvider, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { ReferralProgram } from "lib-fullstack/utils/referralProgramUtils";
import { WebServerInternalPath } from "utils/paths";
import { getReturnPath } from "utils/Utilities";
import { COOKIE_YES_COOKIE_BANNER_CLASS_NAME } from "utils/Constants";

// the cookie banner should be removed after the user enters the app
export const removeCookieBanner = (): void => {
  const cookieBanner = document.querySelector(`.${COOKIE_YES_COOKIE_BANNER_CLASS_NAME}`);
  if (cookieBanner) {
    cookieBanner.remove();
  }
};

export default function SignIn(): JSX.Element {
  const { invalidateDefaultOrgQuery, invalidateUserOrgQuery } = React.useContext(UserOrgContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [signinLoading, setSigninLoading] = React.useState<boolean>(false);
  const [showWorkEmailSignIn, setShowWorkEmailSignIn] = React.useState<boolean>(false);
  const [showUsernamePasswordSignUp, setShowUsernamePasswordSignUp] =
    React.useState<boolean>(false);
  const [customSsoName, setCustomSsoName] = React.useState<string>("");
  const [ssoDisplayName, setSsoDisplayName] = React.useState<string>("SSO");
  const [showOnlyOrgSso, setShowOnlyOrgSso] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const emailError = validateEmail(email);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const siteConf = getStaticFullSiteConf();
  const clientEnv = getClientEnvConfig();
  if (clientEnv.cookieYesId && clientEnv.envName !== "local") {
    useExternalScript({
      id: "cookieyes",
      type: "text/javascript",
      src: `https://cdn-cookieyes.com/client_data/${clientEnv.cookieYesId}/script.js`,
    });
  }
  const orgId =
    params.get(AuthQueryParams.ORG_ID) ?? params.get(OrgInviteQueryParams.OVERRIDE_ORG_ID);
  const hubId = params.get(AuthQueryParams.HUB_ID);

  const orgAccessInfoQuery = useApiQuery({
    queryKey: [ORG_ACCESS_INFO_QUERY_KEY, orgId ?? hubId],
    queryFn: () => getOrgAccessInfo({ orgId: orgId, hubId: hubId }),
    enabled: !!orgId || !!hubId,
    refetchOnWindowFocus: false,
  });

  const requestedReturnPath = location?.state
    ? mergeReturnPathAndSearchParameters(getReturnPath(location), location?.search)
    : null;

  // user is redirected to /login after attempting a duplicate signup with email.
  // We can't log them in automatically because they might not have typed
  // in the correct password to the signup form, and denoting "incorrect password"
  // on a sign up form would be weird.
  // We need to prompt them to sign in instead.

  React.useEffect(() => {
    // direct logged in users straight to home
    const user = firebase.auth().currentUser;
    if (user && user["uid"]) {
      const { signInReturnPath } = getSignInSignUpReturnPath(requestedReturnPath);
      removeCookieBanner();
      historyPush(history, signInReturnPath, location.state);
    }

    if (
      HubsInviteRequiredQueryParams.every((param) =>
        Cookies.get(param, { domain: clientEnv.domain })
      )
    ) {
      for (const param of HubsInviteRequiredQueryParams) {
        params.set(param, Cookies.get(param, { domain: clientEnv.domain }) as string);
        Cookies.remove(param, { domain: clientEnv.domain });
      }
      removeCookieBanner();
      historyPush(history, `${WebServerExternalPath.HOME_LOGGED_IN}?${params.toString()}`);
    }
    if (params.get(SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM)) {
      // login via custom token
      const acceptTos = Boolean(params.get(ACCEPT_TOS_QUERY_PARAM));
      void handleSignInWithCustomToken(params.get(SIGN_IN_CUSTOM_TOKEN_QUERY_PARAM), acceptTos);
    }
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNIN_PAGE_LOADED);
  }, []);

  const resolveSignIn = async (user: firebase.User, isNewUser?: boolean) => {
    const code = params.get(AuthQueryParams.OT_AUTH_CODE); // Sent from electron app redirect

    if (user?.uid) {
      // make sure the user doc service is updating - it should automatically, but this prevents race conditions
      userDocsService.resetLiveUserDocsOnAuthChange();

      const referralSlug = Cookies.get(YOODLI_REFERRER, { domain: clientEnv.domain }) ?? "";
      const indeedHuid =
        Cookies.get(YOODLI_INDEED_HUID, { domain: clientEnv.domain }) ??
        params.get(ReferralProgramQueryParams.INDEED_HUID) ??
        "";
      const referralProgram =
        Cookies.get(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain }) ??
        (indeedHuid ? ReferralProgram.INDEED : "");

      await userAuthenticated(referralProgram, indeedHuid ? { hashedUserId: indeedHuid } : null);

      // Fetch user's default org if it exists
      await invalidateDefaultOrgQuery();

      if (customSsoName) {
        const hasMembership = await checkSsoMembershipAndUpdateDefaultOrg(
          orgAccessInfoQuery?.data,
          params,
          invalidateDefaultOrgQuery,
          invalidateUserOrgQuery
        );

        if (!hasMembership && !error) {
          setError(SSO_MEMBERSHIP_ERROR_MESSAGE);
          return;
        }
      }

      const returnPaths = getSignInSignUpReturnPath(requestedReturnPath);

      if (referralSlug) {
        Cookies.remove(YOODLI_REFERRER, { domain: clientEnv.domain });
      }
      if (referralProgram) {
        Cookies.remove(YOODLI_REFERRAL_PROGRAM, { domain: clientEnv.domain });
      }
      if (indeedHuid) {
        Cookies.remove(YOODLI_INDEED_HUID, { domain: clientEnv.domain });
      }

      await completeUserLogin(user.uid);

      if (code && user?.emailVerified) {
        await generateAuthTokenForPoodliAndClose(code);
      } else {
        let returnPathForUser: string;
        if (user?.emailVerified) {
          if (isNewUser) {
            returnPathForUser = returnPaths.signUpReturnPath;
          } else {
            returnPathForUser = returnPaths.signInReturnPath;
          }
        } else {
          await optimisticSendVerificationEmail(
            getClientEnvConfig().url.WEB_SERVER +
              mergeReturnPathAndSearchParameters(
                returnPaths.signUpReturnPath,
                window.location.search
              )
          ).catch((error) => {
            if (error.message === "auth/internal-error") {
              console.log("Error sending email, too many attempts!");
            } else {
              console.error("Error sending email!", error);
            }
          });
          returnPathForUser = WebServerInternalPath.VERIFY_EMAIL;
        }
        returnPathForUser = mergeReturnPathAndSearchParameters(returnPathForUser, location.search);

        // ensure user docs have re-resolved before we continue
        await userDocsService.awaitUserDocsResolved();

        // if user exists, route back to returnPath otherwise fallback to login and continue to pass returnPath as state
        historyPush(history, returnPathForUser, location.state);
      }
    } else {
      historyPush(history, WebServerExternalPath.SIGN_IN, location.state);
    }
    removeCookieBanner();
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNIN_EMAIL_PASSWORD_OPTION_CLICKED);
    if (!emailError && email && password) {
      try {
        const user = await signInWithEmail(email, password);
        await resolveSignIn(user);
      } catch (error) {
        // special handling for Korn Ferry Advance users to redirect them to their KFA login page
        const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
        const kfaSignInMethods = signInMethods?.filter((method) => method === KFA_PROVIDER);
        if (kfaSignInMethods?.length > 0) {
          window.location.href = KFA_SIGN_IN_REDIRECT;
          return;
        }

        if (error?.code?.startsWith?.("auth/")) {
          console.warn(error?.code);
        } else {
          console.error(error?.code ?? error);
        }

        if (error?.code === "auth/internal-error") {
          setError("Sorry that's not quite right. Please try again or sign up.");
        } else {
          setError(error.message);
        }
      }
    } else if (!email.length) {
      setError("Email is required");
    } else if (!password.length) {
      setError("Password is required");
    }

    setLoading(false);
  };

  const handleWorkEmailSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const domain = email.split("@")[1];
    setSigninLoading(true);
    try {
      const ignoreSso = params.get(AuthQueryParams.IGNORE_SSO) === "true";
      const options = await getSignInOptions(domain);
      if (options.sign_in_options.length > 0 && !ignoreSso) {
        setCustomSsoName(options.sign_in_options[0].firebase_provider);
        setSsoDisplayName(options.sign_in_options[0].display_name);
        setShowUsernamePasswordSignUp(false);
      } else {
        setShowUsernamePasswordSignUp(true);
        setShowWorkEmailSignIn(false);
      }
    } catch (error) {
      setShowUsernamePasswordSignUp(true);
      setShowWorkEmailSignIn(false);
    }
    setSigninLoading(false);
  };

  const handleSignInWithOAuthProvider = async (
    e: React.MouseEvent<HTMLButtonElement>,
    provider: firebase.auth.AuthProvider
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { user, isNewUser } = await signInWithOAuthProvider(provider);
      await resolveSignIn(user, isNewUser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.code?.startsWith?.("auth/")) {
        console.warn(error?.code);
      } else {
        console.error(error?.code ?? error);
      }
      setError(error.message);
    }
  };

  const handleSignInWithCustomToken = async (customSigninToken: string, acceptTos: boolean) => {
    setLoading(true);
    try {
      const user = await signInWithCustomToken(customSigninToken);
      // custom tokens can never occur for new users so sign in!
      await resolveSignIn(user, acceptTos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.code?.startsWith?.("auth/")) {
        console.warn(error?.code);
      } else {
        console.error(error?.code ?? error);
      }
      setError(error.message);
    }
  };

  const handleSsoSignIn = async (e) => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_CUSTOM_SSO_OPTION_CLICKED);
    if (customSsoName.startsWith("saml")) {
      await handleSignInWithOAuthProvider(e, new firebase.auth.SAMLAuthProvider(customSsoName));
    } else if (customSsoName.startsWith("oidc")) {
      await handleSignInWithOAuthProvider(e, new firebase.auth.OAuthProvider(customSsoName));
    }
  };

  const expandWorkEmailOption = () => {
    setShowWorkEmailSignIn(true);
  };

  const handleChangeEmail = (email) => {
    setEmail(email);
    setError("");
  };

  const handleChangePassword = (password) => {
    setPassword(password);
    setError("");
  };

  const renderWorkEmailForm = () => {
    const ctaDisabled = !!emailError || !email;
    // showing the work email form
    if (showWorkEmailSignIn) {
      return (
        <>
          <Divider sx={{ flexShrink: "initial", width: "100%" }} />
          <Stack gap={2} width="100%">
            <Box>
              <Typography
                color={getDynamicColor("dark6")}
                fontWeight={600}
                fontSize="14px"
                fontFamily="poppins"
              >
                Sign in with work email
              </Typography>
            </Box>
            <form onSubmit={handleWorkEmailSubmit} style={{ width: "100%" }}>
              <Stack gap={1.5} alignItems="center">
                <AuthInput
                  fullWidth
                  value={email}
                  placeholder="Work Email"
                  label={email ? "Work Email" : undefined}
                  type="email"
                  error={!!emailError}
                  errorText={emailError}
                  disabled={!!customSsoName}
                  onChange={(e) => handleChangeEmail(e.target.value)}
                  inputProps={{
                    "data-testid": UITestId.WorkEmailTextField,
                  }}
                />
                <Stack width="100%" gap={1}>
                  {signinLoading ? (
                    <Stack display="flex" justifyContent="center" alignItems="center" height="48px">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    // no custom SSO option yet -- show regular sign in button
                    <>
                      {!customSsoName ? (
                        <Button
                          variant="gradient"
                          onClick={handleWorkEmailSubmit}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                          data-testid={UITestId.NextButton}
                        >
                          Next
                        </Button>
                      ) : (
                        // found SSO option -- changing sign in button to sign them in with SSO
                        <Button
                          variant="gradient"
                          onClick={handleSsoSignIn}
                          disabled={ctaDisabled}
                          sx={{
                            borderRadius: "4px",
                            height: 48,
                            width: "100%",
                          }}
                        >
                          Sign in with {ssoDisplayName}
                        </Button>
                      )}
                    </>
                  )}
                  <input type="submit" style={{ display: "none" }} />
                </Stack>
              </Stack>
            </form>
          </Stack>
        </>
      );
    }
    // show email sign in button (but not if there is an available SSO Option)
    if (
      !customSsoName &&
      (orgAccessInfoQuery.isPending ||
        orgAccessInfoQuery?.data?.sign_in_options?.includes(AuthProvider.EMAIL_PASSWORD))
    ) {
      return (
        <Button
          variant="contained"
          startIcon={
            <EmailIcon width="28px" style={{ borderRadius: "50%", backgroundColor: "#FOFOFF" }} />
          }
          onClick={expandWorkEmailOption}
          sx={{
            backgroundColor: getDynamicColor("light1"),
            width: "100%",
            "&:hover": {
              backgroundColor: getDynamicColor("dark2"),
            },
            fontWeight: 600,
            fontSize: 14,
            color: getDynamicColor("dark6"),
            height: 48,
          }}
          data-testid={UITestId.SignInWithWorkEmailButton}
        >
          Sign in with work email
        </Button>
      );
    }
  };

  const renderUsernamePasswordForm = () => (
    <>
      <Divider sx={{ flexShrink: "initial", width: "100%" }} />
      <Stack gap={2} width="100%">
        <Box>
          <Typography
            px={2}
            color={getDynamicColor("dark6")}
            fontWeight={600}
            fontSize="14px"
            fontFamily="poppins"
          >
            Sign in with work email
          </Typography>
        </Box>
        <form onSubmit={handleSignIn} style={{ width: "100%" }}>
          <Stack gap={1.5}>
            <AuthInput
              fullWidth
              value={email}
              placeholder="Email"
              label={email ? "Email" : undefined}
              type="email"
              error={!!emailError}
              errorText={emailError}
              onChange={(e) => handleChangeEmail(e.target.value)}
            />
            <AuthInput
              fullWidth
              autoFocus
              value={password}
              placeholder="Password"
              label={password ? "Password" : undefined}
              type="password"
              onChange={(e) => handleChangePassword(e.target.value)}
              inputProps={{
                "data-testid": UITestId.PasswordTextField,
              }}
            />

            <Button
              variant="gradient"
              onClick={handleSignIn}
              disabled={!!emailError || !email || !password}
              sx={{
                borderRadius: "4px",
                height: 48,
                width: "100%",
              }}
              data-testid={UITestId.SignInButton}
            >
              Sign in
            </Button>
            <Link to={authUrls.forgot_password} style={{ textDecoration: "none" }}>
              <Typography
                fontFamily="poppins"
                fontWeight={600}
                fontSize="12px"
                color={getDynamicColor("primary")}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                Forgot Password?
              </Typography>
            </Link>
            <input type="submit" style={{ display: "none" }} />
          </Stack>
        </form>
      </Stack>
    </>
  );
  if (loading) {
    return (
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }
  return orgAccessInfoQuery.isLoading ? (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <AuthWrapper
      setShowOnlyOrgSso={setShowOnlyOrgSso}
      setCustomSsoName={setCustomSsoName}
      setSsoDisplayName={setSsoDisplayName}
      orgAccessInfo={orgAccessInfoQuery?.data}
    >
      <Box
        p="20px"
        borderRadius="8px"
        width="min(385px, 100%)"
        sx={{
          ...(!showOnlyOrgSso && {
            backgroundColor: getDynamicColor("light2"),
            filter: "drop-shadow(1px 2px 5px rgba(33, 37, 41, 0.16));",
          }),
        }}
      >
        {error && (
          <Typography p={1} color="error" fontWeight={600} fontSize="12px">
            {error}
          </Typography>
        )}
        <Stack direction="column" width="100%" gap={2}>
          <OAuthButtonGroup
            buttonHandler={handleSignInWithOAuthProvider}
            showOnlyOrgSso={showOnlyOrgSso}
            customSsoName={customSsoName}
            ssoDisplayName={ssoDisplayName}
            error={!!error}
            handleRedirect={() => {
              void resolveSignIn(firebase.auth().currentUser);
            }}
            signInOptions={
              orgAccessInfoQuery?.data?.sign_in_options ?? [
                AuthProvider.EMAIL_PASSWORD,
                AuthProvider.GOOGLE,
                AuthProvider.MICROSOFT,
              ]
            }
          />
          {showUsernamePasswordSignUp ? renderUsernamePasswordForm() : renderWorkEmailForm()}
        </Stack>
      </Box>
      <Typography
        fontWeight={600}
        fontSize="14px"
        fontFamily="poppins"
        color={getDynamicColor("dark5")}
      >
        Don't have an account yet? &nbsp;
        <Link
          style={{ textDecoration: "none", color: getDynamicColor("primary") }}
          to={{
            pathname: authUrls.signup,
            search: fetchUrlSearchParams(params),
            state: location.state,
          }}
          data-testid={UITestId.SignUpLink}
        >
          Sign Up
        </Link>
      </Typography>
      {!siteConf?.featureDiffs?.privacyPolicyConsentRequired && (
        <TermsAndPrivacyAgreementCopy orgSignUp={false} />
      )}
    </AuthWrapper>
  );
}
