import React from "react";
import { Menu, MenuItem, Button, styled, Stack, Typography, Box, SxProps } from "@mui/material";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import {
  ChevronRightOutlined as ForwardIcon,
  ChevronLeftOutlined as BackIcon,
} from "@mui/icons-material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

export type StackedMenuItem = {
  title: string | JSX.Element;
  subtitle: string;
  subMenuContent: JSX.Element;
  disabled?: boolean;
  disabledTooltip?: string;
};

type StackedMenuProps = {
  menuItems: StackedMenuItem[];
  buttonContent?: string | JSX.Element;
  buttonSx?: SxProps;
};

const StyledSubMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    background: "transparent",
    border: "hidden",
    boxShadow: "none",
    justifyContent: "center",
    zIndex: 1000,
  },
}));

export const StackedMenu = ({
  menuItems,
  buttonContent,
  buttonSx,
}: StackedMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenus, setOpenMenus] = React.useState<{ [key: number]: boolean }>({
    0: false,
    ...Object.fromEntries(menuItems.map((_, index) => [index + 1, false])),
  });

  const subMenuOpen = React.useMemo(() => {
    return Object.keys(openMenus).some((key) => Number(key) !== 0 && openMenus[key]);
  }, [openMenus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenus({ ...openMenus, 0: true });
  };

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, menuInd: number) => {
    setOpenMenus({ ...openMenus, [menuInd]: true });
  };

  const handleClose = (menuInd: number) => {
    if (menuInd === 0) {
      setAnchorEl(null);
      setOpenMenus({
        0: false,
        ...Object.fromEntries(menuItems.map((_, index) => [index + 1, false])),
      });
    } else {
      setOpenMenus({ ...openMenus, [menuInd]: false });
    }
  };

  const renderSubMenu = (item: StackedMenuItem, index: number) => (
    <StyledSubMenu
      sx={{ py: 0 }}
      anchorEl={anchorEl}
      open={!!openMenus[index]}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          handleClose(0);
        } else {
          handleClose(index);
        }
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      slotProps={{
        paper: {
          sx: {
            background: "transparent",
            border: "hidden",
            boxShadow: "none",
            justifyContent: "center",
            zIndex: 1000,
          },
        },
        root: { sx: { zIndex: 150000 } },
      }}
    >
      <Box
        sx={{
          overflow: "auto",
          borderRadius: "12px",
          boxShadow: Y_SHADOWS.box_shadow_1,
          border: `1px solid ${getDynamicColor("dark3")}`,
          backgroundColor: getDynamicColor("light1"),
          color: getDynamicColor("purple3"),
          pt: 2,
        }}
        // very important, this onKeyDown is to prevent the
        // menu from receiving keypresses from inputs within the menu item
        // which can lead to confusing/unintended consequences
        onKeyDown={(e) => e.stopPropagation()}
      >
        {index != 0 && (
          <Stack
            onClick={() => {
              handleClose(index);
            }}
            direction="row"
            sx={{
              gap: 1,
              alignItems: "center",
              px: 2,
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <BackIcon sx={{ color: getDynamicColor("primary") }} />
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Back</Typography>
          </Stack>
        )}
        {item.subMenuContent}
      </Box>
    </StyledSubMenu>
  );

  // just render the submenu if there is only one item to avoid the extra click
  if (menuItems.length === 1) {
    return (
      <Box>
        <Button variant="contained" onClick={handleClick} sx={{ ...buttonSx }}>
          {buttonContent}
        </Button>
        {renderSubMenu(menuItems[0], 0)}
      </Box>
    );
  }

  return (
    <>
      <Button variant="contained" onClick={handleClick} sx={{ ...buttonSx }}>
        {buttonContent}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!openMenus[0]}
        MenuListProps={{
          sx: {
            opacity: subMenuOpen ? 0 : 1,
            transition: "opacity 380ms cubic-bezier(0.4, 0, 0.2, 1)",
          },
        }}
        onClose={() => handleClose(0)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              background: "transparent",
              border: "hidden",
              boxShadow: "none",
              justifyContent: "center",
              zIndex: 1000,
            },
          },
        }}
      >
        <Stack
          width={"100%"}
          minWidth="150px"
          maxWidth="300px"
          borderRadius="12px"
          border={`1px solid ${getDynamicColor("dark3")}`}
          boxShadow={Y_SHADOWS.box_shadow_1}
          sx={{ background: getDynamicColor("light1") }}
        >
          {menuItems.map((item, index) => {
            const menuInd = index + 1;
            return (
              <Stack key={menuInd}>
                <YoodliTooltip
                  title={item.disabled && item.disabledTooltip && item.disabledTooltip}
                >
                  <MenuItem
                    sx={{
                      fontFamily: "Poppins",
                      width: "100%",
                      gap: 1,
                      py: 1.5,
                      px: 2,
                      fontSize: "16px",
                      fontWeight: 700,
                      color: openMenus[menuInd]
                        ? getDynamicColor("primary")
                        : getDynamicColor("purple3"),
                      justifyContent: "space-between",
                    }}
                    disabled={item.disabled}
                    onClick={(event) => handleItemClick(event, menuInd)}
                  >
                    <Stack direction="column" sx={{ maxWidth: "80%" }}>
                      <Typography>{item.title}</Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "12px", textWrap: "wrap" }}>
                        {item.subtitle}
                      </Typography>
                    </Stack>
                    <ForwardIcon />
                  </MenuItem>
                </YoodliTooltip>
                {renderSubMenu(item, menuInd)}
              </Stack>
            );
          })}
        </Stack>
      </Menu>
    </>
  );
};
