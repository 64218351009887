import React from "react";

// Components
import { MailOutlineRounded as MailIcon } from "@mui/icons-material";
import { InsertLinkRounded as LinkIcon } from "@mui/icons-material";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ActionCard from "lib-frontend/components/YoodliComponents/ActionCard";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { defaultHeaderSx, ADMIN_HEADER_HEIGHT } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as CsvIcon } from "../../../images/icons/pdf-icon.svg";

// Utils
import { BulkInviteByCSVWizard } from "../BulkInviteByCSVWizard/BulkInviteByCSVWizard";
import { DefaultOrgSwitcher } from "../DefaultOrgSwitcher";
import { InviteMembers, MAX_EMAIL_INVITE_COUNT } from "../InviteMembers";
import { OrgLoading } from "../OrgLoading";
import { TableActionBar, TableTab, TableTabLabel } from "../TableActionBar";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import OrgInvitesTable from "./InvitesTable";
import { ManageRole } from "./ManageRole";
import MembersTable from "./MembersTable";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { inviteUserToHub } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getOrgInviteCount, getOrgUserCount, isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { OrgHubsQueryParams } from "lib-frontend/utils/queryParams";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { HubRole } from "lib-fullstack/utils/enums";
import { OrgMembersTabs } from "./OrgMembersTabs";
import { ReportsTab } from "../Reports/ReportsTab";
import { ReportsTabType } from "../Reports/reportsUtils";

export const MEMBER_COUNT_HEIGHT = 22;

enum OrgMembersSectionStatus {
  Default = "Default",
  ManageRole = "ManageRole",
  InviteMembers = "InviteMembers",
  BulkCsvInvite = "InviteCSV",
}

export enum OrgMembersTabEnum {
  Activity = "Activity",
  Reports = "Reports",
}

export enum OrgMembersQueryKeys {
  OrgMembers = "orgMembers",
  OrgInvites = "orgInvites",
  OrgEmailBranding = "orgEmailBranding",
  OrgUserCounts = "orgUserCounts",
  HubUserCounts = "hubUserCounts",
}

export default function OrgMembers(): JSX.Element {
  const [selectedTab, setSelectedTab] = useQueryParamState(
    OrgHubsQueryParams.TAB,
    OrgMembersTabEnum.Activity
  );

  const topContentRef = React.useRef<HTMLDivElement>(null);
  const [topContentHeight, setTopContentHeight] = React.useState<number>(
    topContentRef?.current?.clientHeight ?? 0
  );
  const [notificationData, setNotificationData] = React.useState<{
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }>(null);
  const { showNotificationBanner, notifAnchorRef } = useNotification();

  const userOrgContext = React.useContext(UserOrgContext);
  const [inviteLinkCopied, setInviteLinkCopied] = React.useState<boolean>(false);
  const [selectedTabLabel, setSelectedTabLabel] = useQueryParamState(
    OrgHubsQueryParams.TABLE,
    TableTabLabel.Members
  );
  const [sectionStatus, setSectionStatus] = useQueryParamState(
    OrgHubsQueryParams.SECTION,
    OrgMembersSectionStatus.Default
  );
  const [searchText, setSearchText] = React.useState<string>("");
  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);

  React.useEffect(() => {
    if (notificationData) {
      showNotificationBanner(notificationData.message, notificationData.options);
    }
  }, [notificationData, showNotificationBanner]);

  React.useEffect(() => {
    if (sectionStatus === OrgMembersSectionStatus.ManageRole && !selectedUser) {
      setSectionStatus(OrgMembersSectionStatus.Default);
    }
  }, [sectionStatus]);

  const { data: tableTabs, isPending: userCountLoading } = useApiQuery({
    queryKey: [OrgMembersQueryKeys.OrgUserCounts, userOrgContext.defaultOrg?.id],
    queryFn: async () => {
      const invitesCount = await getOrgInviteCount(userOrgContext.defaultOrg?.id);
      const membersCount = await getOrgUserCount(userOrgContext.defaultOrg?.id);
      return [
        {
          label: TableTabLabel.Members,
          count: membersCount,
        },
        {
          label: TableTabLabel.Invites,
          count: invitesCount,
        },
      ] as TableTab[];
    },
    enabled: !!userOrgContext.defaultOrg?.id,
  });

  const handleCopyInviteLink = () => {
    if (!inviteLinkCopied) {
      inviteUserToHub(userOrgContext.defaultOrg.default_hub_id, null, HubRole.MEMBER)
        .then((invite) => {
          setInviteLinkCopied(true);
          copyToMyClipboard(`${window.location.origin}/${invite.invite_path}`);
          showNotificationBanner("Invite link copied to clipboard", {
            variant: YoodliNotificationBannerVariant.Success,
          });
        })
        .catch((err) => {
          console.error("Error getting invite url", err);
          showNotificationBanner("There was a problem getting your invite URL.", {
            variant: YoodliNotificationBannerVariant.Danger,
          });
        });
      setTimeout(() => {
        setInviteLinkCopied(false);
      }, 2000);
    }
  };

  const recalcTopContentHeight = () => {
    setTopContentHeight(topContentRef.current.clientHeight);
  };

  const renderTable = () => {
    let content = (
      <OrgInvitesTable
        searchText={searchText}
        setSearchText={setSearchText}
        recalcTopContentHeight={recalcTopContentHeight}
      />
    );
    if (selectedTabLabel === TableTabLabel.Members) {
      content = (
        <MembersTable
          searchText={searchText}
          openManageRoleWizard={(user) => {
            setSelectedUser(user);
            setSectionStatus(OrgMembersSectionStatus.ManageRole);
          }}
          recalcTopContentHeight={recalcTopContentHeight}
        />
      );
    }
    return (
      <Box
        sx={{
          position: "relative",
          height: `calc(100% - ${topContentHeight + MEMBER_COUNT_HEIGHT}px)`,
        }}
      >
        {content}
      </Box>
    );
  };

  const actionCards = [
    {
      icon: <MailIcon />,
      title: "Invite members",
      subtitle: `Send invitations via email`,
      handler: () => {
        setSectionStatus(OrgMembersSectionStatus.InviteMembers);
      },
    },
    {
      icon: <LinkIcon />,
      title: "Copy invite link",
      subtitle: "Copy a link to the default group",
      handler: handleCopyInviteLink,
    },
    {
      icon: <CsvIcon />,
      title: "Bulk invite by CSV",
      subtitle: "Upload a CSV to bulk invite members",
      handler: () => setSectionStatus(OrgMembersSectionStatus.BulkCsvInvite),
    },
  ];

  const loading = userOrgContext.loading || !userOrgContext.defaultOrg;

  if (loading) {
    return <OrgLoading prompts={["Loading organization members..."]} />;
  }

  const renderHeader = () => {
    return (
      <Stack
        direction="column"
        sx={{
          backgroundColor: getDynamicColor("purple3"),
          ...defaultHeaderSx,
        }}
        ref={notifAnchorRef}
      >
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            ...defaultHeaderSx,
            px: { xs: 2, md: 3, lg: 4, xl: 5 },
            width: "100%",
          }}
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Typography
              color={getDynamicColor("light1")}
              sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 600 }}
            >
              Organization members
            </Typography>
            {isOrgTrialEnded(userOrgContext.defaultOrg) && <TrialEndedBanner />}
          </Stack>
          <DefaultOrgSwitcher />
        </Stack>
        <OrgMembersTabs
          selectedTab={selectedTab as OrgMembersTabEnum}
          setSelectedTab={setSelectedTab}
          shouldBlockNav={false}
          setShouldBlockNav={() => null}
        />
      </Stack>
    );
  };

  const renderPage = () => {
    switch (selectedTab) {
      case OrgMembersTabEnum.Activity:
        return (
          <>
            <Stack
              gap={4}
              sx={{
                height: "100%",
              }}
            >
              <Stack gap={4} ref={topContentRef} onLoad={recalcTopContentHeight}>
                <Stack
                  direction="row"
                  gap={{ xs: 2, lg: 4 }}
                  flexWrap={{ xs: "nowrap", sm: "wrap" }}
                  justifyContent={{ xs: "unset", sm: "center" }}
                  overflow={{ xs: "auto", sm: "unset" }}
                >
                  {actionCards.map((card) => (
                    <ActionCard
                      key={card.title}
                      icon={card.icon}
                      title={card.title}
                      subtitle={card.subtitle}
                      handler={card.handler}
                    />
                  ))}
                </Stack>
                {!userCountLoading && (
                  <TableActionBar
                    tableTabs={tableTabs}
                    selectedTabLabel={selectedTabLabel as TableTabLabel}
                    setSelectedTabLabel={setSelectedTabLabel}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                )}
              </Stack>
              {!userCountLoading &&
                !userOrgContext.loading &&
                userOrgContext.defaultOrg &&
                renderTable()}
              {userCountLoading && (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}
            </Stack>
          </>
        );
      case OrgMembersTabEnum.Reports:
        return (
          <ReportsTab
            type={ReportsTabType.Members}
            wrapperSx={{
              p: "0px !important",
              pb: "48px !important",
            }}
          />
        );
    }
  };

  const renderContent = () => {
    switch (sectionStatus) {
      case OrgMembersSectionStatus.ManageRole:
        if (selectedUser) {
          return (
            <ManageRole
              handleShowNotificationBanner={setNotificationData}
              org={userOrgContext.defaultOrg}
              handleBack={() => setSectionStatus(OrgMembersSectionStatus.Default)}
              user={selectedUser}
            />
          );
        } else {
          setSectionStatus(OrgMembersSectionStatus.Default);
          break;
        }
      case OrgMembersSectionStatus.InviteMembers:
        return (
          <InviteMembers
            title="Invite people to join your organization"
            subtitle={`Add up to ${MAX_EMAIL_INVITE_COUNT} emails separated by commas, select one role for all new users, and add them to at least one group. Yoodli will send invitations to all new users and exclude any already-existing members. `}
            backCopy="Members"
            handleBack={() => {
              setSectionStatus(OrgMembersSectionStatus.Default);
            }}
            org={userOrgContext.defaultOrg}
          />
        );
      case OrgMembersSectionStatus.BulkCsvInvite:
        return (
          <BulkInviteByCSVWizard
            org={userOrgContext.defaultOrg}
            handleBack={() => setSectionStatus(OrgMembersSectionStatus.Default)}
          />
        );
      case OrgMembersSectionStatus.Default:
      default:
        return (
          <Stack
            direction="column"
            sx={{
              background: getDynamicColor("light1"),
              minHeight: "100dvh",
              gap: { xs: 0, md: 2 },
            }}
          >
            {renderHeader()}
            <Stack
              sx={{
                p: 4,
                position: "relative",
                height: `calc(100dvh - ${ADMIN_HEADER_HEIGHT}px)`,
                width: "100%",
                maxWidth: "xxl",
                mx: "auto",
              }}
            >
              {isOrgTrialEnded(userOrgContext.defaultOrg) ? (
                <TrialEnded org={userOrgContext.defaultOrg} />
              ) : (
                renderPage()
              )}
            </Stack>
          </Stack>
        );
    }
  };

  return renderContent();
}
