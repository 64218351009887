import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

// Utils
import { hasSiteAccess } from "auth";
import { generateAuthTokenForPoodliAndClose, useURLQueryString } from "auth/utils";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { AuthQueryParams, OrgInviteQueryParams } from "lib-frontend/utils/queryParams";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { getUserDocMain } from "lib-fullstack/db";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { getSiteConfigBySiteId } from "lib-fullstack/workspaces";
import { PUBLIC_WEBSITE_PATHS, WebServerInternalPath } from "utils/paths";
import { determineHomePagePath, getReturnPath, historyPush } from "utils/Utilities";

const EmailVerificationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [verificationComplete, setVerificationComplete] = React.useState(false);
  const [oobCodeApplied, setOobCodeApplied] = React.useState(false);

  // History/Location
  const history = useHistory();
  const location = useLocation();
  const returnPath = getReturnPath(location) ?? location.pathname + (location.search ?? "");
  const hasHubInvite =
    new URLSearchParams(location?.search).has(OrgInviteQueryParams.ACCEPT_INVITE) ||
    location.pathname === WebServerInternalPath.ACCEPT_INVITE;
  const hasOverrideOrgId = new URLSearchParams(location?.search).has(
    OrgInviteQueryParams.OVERRIDE_ORG_ID
  );
  const allowUnverified = [...PUBLIC_WEBSITE_PATHS, WebServerInternalPath.VERIFY_EMAIL].some(
    (path) => location.pathname.includes(path)
  );

  // User
  const { currentUser } = firebase.auth() ?? {};
  const userExists = currentUser && currentUser.uid;
  const verifiedUserExists = userExists && currentUser.emailVerified;

  // Query Parameters
  const URLQueryString = useURLQueryString();
  const oobCode = URLQueryString.get("oobCode");
  const otAuthCode = URLQueryString.get("ot-auth-code");

  // Deal with query parameters if they exist before anything else
  // otAuthCode sent from electron app redirect
  React.useLayoutEffect(() => {
    if (currentUser?.emailVerified && otAuthCode) {
      generateAuthTokenForPoodliAndClose(otAuthCode).catch((err) =>
        console.error("Error completing poodli login", err)
      );
    }
  }, [otAuthCode]);
  const clearOobCodeFromURL = (overrideOnboarding: boolean) => {
    console.log(
      `Clearing oobCode from the url and redirecting: clearOobCodeFromURL(${overrideOnboarding})`
    );
    // clear the oobCode from the url once the firebase user is refreshed but leave any other query parameters
    const urlParams = new URLSearchParams(location.search);
    // specifically do not delete oobCode if path is reset password (coming from reset password email redirect)
    // since its used for the password reset submission.
    if (location.pathname !== WebServerExternalPath.RESET_PASSWORD) {
      urlParams.delete("oobCode");
    }
    history.replace({
      pathname:
        overrideOnboarding && location.pathname === WebServerInternalPath.ONBOARDING
          ? determineHomePagePath()
          : location.pathname,
      state: { returnPath },
      search: urlParams.toString(),
    });
  };
  // oobCode from firebase verify email link
  React.useEffect(() => {
    if (oobCode) {
      console.log("oobCode code found");
      if (userExists) {
        if (!currentUser.emailVerified) {
          // optimistically set oobCodeApplied to true, and revert if error
          setOobCodeApplied(true);
          firebase
            .auth()
            .applyActionCode(oobCode)
            .then(async () => {
              // code applied - now refresh the firebase user
              await currentUser.reload();
              if (currentUser?.emailVerified) {
                // purge cached ID token
                await currentUser?.getIdTokenResult(true /*forceRefresh*/);
              }

              if (currentUser?.emailVerified && otAuthCode) {
                await generateAuthTokenForPoodliAndClose(otAuthCode);
              } else {
                clearOobCodeFromURL(false);
              }
            })
            .catch((err) => {
              console.error(err.message);
              setOobCodeApplied(false);

              // replace here so the user doesnt add a page they don't have access to to their browser history stack (avoid redirect loop)
              history.replace(WebServerInternalPath.VERIFY_EMAIL, { returnPath });
            });
        } else {
          clearOobCodeFromURL(true);
        }
      } else if (!PUBLIC_WEBSITE_PATHS.includes(location.pathname)) {
        // user might have switched browser, so the current user doesnt exist but the oobCode does
        // so redirect to login, which will then apply the oobCode
        history.replace(WebServerExternalPath.SIGN_IN + location.search, { returnPath });
      }
    }
  }, [oobCode, userExists]);

  React.useEffect(() => {
    const allowPublic = PUBLIC_WEBSITE_PATHS.some((path) => {
      return location.pathname.includes(path);
    });

    const checkVerification = async () => {
      // if oobCode exists just skip this effect because the above effect will take care of the redirect for us, and we dont want a race condition to see who redirects first
      if (oobCode) return;
      // - If user exists
      //   - If email is not verified (or currently being verified with oobCodeApplied) push directly to verify-email page
      //   - Else If email is verified, not viewing a speech summary, and not a whitelabel check if onboarding is complete
      //     - If onboarding is not complete, push to onboarding page
      //   - If user does not have site access, replace the page they tried to access with restricted page
      // - Else If user does not exist and not viewing a publicly accessible route
      //   - Redirect to login route
      if (userExists) {
        // If user is on toastmasters, skip email verification - this is for legacy TMI users who are still unverified
        // if user exists but is not verified and not already on verify email page, redirect to verify email page
        // upon successful verification, user will be redirected to the page they had tried to access
        const { siteConf } = await getSiteConfigBySiteId(getSiteId());
        if (
          !isToastmasters(siteConf) &&
          !currentUser.emailVerified &&
          !oobCodeApplied &&
          !location.pathname.includes(WebServerInternalPath.VERIFY_EMAIL)
        ) {
          historyPush(history, WebServerInternalPath.VERIFY_EMAIL, {
            returnPath,
          });
        }
        // if user is verified, not on speech summary page, and not a whitelabel, check if onboarding complete
        else if (!location.pathname.includes(WebServerExternalPath.SHARE) && !isWhiteLabel()) {
          const userDocMain: db.UserDocMain = await getUserDocMain(getSiteId(), currentUser.uid);
          const pathsToIgnore = [
            WebServerInternalPath.ONBOARDING,
            WebServerInternalPath.VERIFY_EMAIL,
          ];
          // if user is not on onboarding/verify email page and has not completed onboarding, redirect to onboarding
          // replace instead of push so that user can go back if they want (avoid redirect loop)
          if (!userDocMain.onboardingAnswers && !pathsToIgnore.includes(location.pathname)) {
            history.replace(WebServerInternalPath.ONBOARDING, {
              returnPath,
            });
          }
        }
        // if user is not on restricted page and does not have site access, redirect to restricted page
        // replace instead of push so that user can go back if they want (avoid redirect loop)
        if (!(await hasSiteAccess(currentUser))) {
          history.replace(WebServerInternalPath.RESTRICTED, { returnPath });
        }
        // if user doesn't exist and not on a publicly allowed page, redirect to login page
        // upon successful login, user will be redirected to the page they had tried to access
      } else if (!allowPublic) {
        // if it's a hubslink, we should navigate the user to sign up since most won't have an account
        let redirectPath =
          hasHubInvite || hasOverrideOrgId
            ? WebServerExternalPath.SIGN_UP.toString()
            : WebServerExternalPath.SIGN_IN.toString();

        if (hasHubInvite || hasOverrideOrgId) {
          const urlParams = new URLSearchParams(location.search);
          if (location.pathname === WebServerInternalPath.ACCEPT_INVITE) {
            urlParams.append(AuthQueryParams.V2, "true");
          }
          redirectPath += "?" + urlParams.toString();
        }

        history.replace(redirectPath, { returnPath });
      }
    };

    checkVerification()
      .then(() => {
        setVerificationComplete(true);
      })
      .catch(console.error);
  }, [location.pathname]);
  // make sure either we complete the verification check or the user is already verified before rendering children
  if ((!verificationComplete || !allowUnverified) && !verifiedUserExists && !isToastmasters())
    return <></>;
  return <>{children}</>;
};

export default EmailVerificationProvider;
