import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router";

// Components
import BoltIcon from "@mui/icons-material/BoltRounded";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import Calendar from "components/Calendar/Calendar";
import ItemCarousel from "components/Home/ItemCarousel";
import PoodliCalConnectProvider from "components/Home/PoodliCalConnectProvider";
import { ProductTips } from "components/Home/ProductTips/ProductTips";
import { RecentSessions } from "components/Home/RecentSessions";
import ZoodliCard from "components/Home/ZoodliCard";
import { VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY } from "components/Orgs/MyLearning";
import { MyLearningTabs } from "components/Orgs/MyLearning";
import { OrgProgramsQueryKeys } from "components/Orgs/Programs/OrgPrograms";
import videoLightbox from "ui/VideoLightbox";

// Utils
import CreateOrgBanner, { CREATE_ORG_BANNER_DISMISSED } from "./CreateOrgBanner";
import Checklist from "./OnboardingChecklist/Checklist";
import { ProgramsCarousel } from "./ProgramsCarousel";
import { ReportCard } from "./ReportCard/ReportCard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useOnAll, useOnQuery } from "@typesaurus/react";
import useCalendars from "hooks/useCalendars";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePricingUsage } from "lib-frontend/hooks";
import { getSpeechThumbnails } from "lib-fullstack/utils/speechUtils";
import {
  AuthorizationAPIHeaders,
  getOrgContentMemberViewV2,
  listProgramsMemberView,
} from "lib-frontend/modules/AxiosInstance";
import { currentUserFirstName, currentUserName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { determineDailyStreak } from "lib-frontend/utils/DbUtils";
import { getSiteId, getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import {
  markAllOnboardingTasksComplete,
  OnboardingChecklistDoc,
} from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ProductTipsDoc } from "lib-frontend/utils/productTipsUtils";
import { MyLearningQueryParams, OrgInviteQueryParams } from "lib-frontend/utils/queryParams";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import {
  useIsMediumScreen,
  useIsScreenSmallerThanPx,
  useIsSmallScreen,
} from "lib-frontend/utils/themeUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { CourseResponse, DemoVideoResponse } from "lib-fullstack/api/hubApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { AudienceType, LifecycleState, updateUserDocMain } from "lib-fullstack/db";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import {
  EventsCalHows,
  EventsCalWheres,
  HomePageChecklistEvents,
} from "lib-fullstack/utils/productAnalyticEvents";
import seedrandom from "seedrandom";
import { MOBILE_TOP_NAVBAR_HEIGHT } from "utils/Constants";
import { envConfig } from "utils/Constants";
import { WebServerInternalPath } from "utils/paths";
import { getCoachTutorialSlug, isCoach, shouldShowCoachChecklist } from "utils/Utilities";

type FilteredFeaturedContent = db.SampleSpeech | db.SampleVideo;

export type BaseCarouselVideoContent = {
  data: {
    id: string;
    title: string;
    videoURL: string;
    thumbnailPath: string;
    audience: AudienceType;
  };
};

type BaseCarouselCourseContent = {
  data: {
    id: string;
    items: db.CourseListItem[];
    description: string;
    title: string;
    creator?: db.CourseCreator;
    thumbnail: string;
    isLocked?: boolean;
    slug?: string;
  };
};

export type CarouselContent =
  | db.Doc<db.Speech>
  | db.Doc<db.SampleVideo>
  | db.Doc<db.CourseInfo>
  | BaseCarouselVideoContent
  | BaseCarouselCourseContent;

export enum CarouselContentType {
  VIDEO = "video",
  SPEECH = "speech",
  FEATURED_COURSE = "featured_course",
  HUB_COURSE = "hub_course",
}

export enum HomePageEls {
  ZOODLI_CARD = "yoodli-for-your-team",
}

const HOME_PAGE_ITEM_PADDING = {
  pb: { xs: 4, md: 5 },
};

/**
 * Filter speeches by audience
 * Can't filter in useOnQuery because db.where and db.sort have to be on same key
 */
const fetchItemsByAudience = (
  items: db.Doc<FilteredFeaturedContent>[]
): db.Doc<FilteredFeaturedContent>[] => {
  const filterParameters: AudienceType[] = [AudienceType.ALL];

  if (!isWhiteLabel()) {
    if (isCoach()) {
      filterParameters.push(AudienceType.COACH);
    } else {
      filterParameters.push(AudienceType.USER);

      const userDocMain: db.UserDocMain = getLiveUserDocMain();
      const onboardingEventsAnswer: string = userDocMain.onboardingAnswers?.eventsFocus ?? "SPEECH";

      // Filter by specific OBQ1 end user parameters if there are videos specific to these types
      if (onboardingEventsAnswer) {
        switch (onboardingEventsAnswer) {
          case "SPEECH":
            filterParameters.push(AudienceType.SPEECH);
            break;
          case "EXPLORING":
            filterParameters.push(AudienceType.EXPLORING);
            break;
          case "INTERVIEW":
            filterParameters.push(AudienceType.INTERVIEW);
            break;
          default:
            filterParameters.push(AudienceType.SPEECH);
            break;
        }
      }
    }
  }

  const filteredItems: db.Doc<FilteredFeaturedContent>[] = items?.filter((i) =>
    filterParameters.includes(i.data.audience as AudienceType)
  );

  return filteredItems;
};

export default function Home(): JSX.Element {
  // #region State

  const isSmallScreen = useIsSmallScreen();
  const location = useLocation();
  const { defaultOrgPrimaryLogo } = React.useContext(UserOrgContext);
  const history = useHistory();
  const qp = new URLSearchParams(location.search);

  const { dbCalendars, dbCalendarsMeta } = useCalendars();
  const showCalConnectSidebar = dbCalendars?.length > 0;
  const user = firebase.auth().currentUser;
  const userDoc = useUserDocsState();

  const pricingUsage = usePricingUsage();

  const [displayName, setDisplayName] = React.useState("");
  const [dailyStreak, setDailyStreak] = React.useState(undefined);
  const [showOnboardingChecklist, setShowOnboardingChecklist] = React.useState<boolean>(false);
  const [googleAuthProviderReady, setGoogleAuthProviderReady] = React.useState(false);
  // #endregion

  const { lightbox, setLightboxURL } = videoLightbox(
    "",
    "",
    "",
    () => null,
    () => null,
    () => null,
    () => null,
    () => null,
    true
  );

  const zoodliCardScrollRef = React.useRef<HTMLDivElement>(null);
  const {
    orgModuleAccess: { zoodliForUsersEnabled },
    userInCoachOrganization,
    userInEnterpriseOrganization,
    userInOrg,
    defaultOrg,
    isOrgOwnerAdmin,
  } = React.useContext(UserOrgContext);

  // #region Effects

  React.useLayoutEffect(() => {
    if (qp.has(OrgInviteQueryParams.ACCEPT_INVITE)) {
      history.replace(
        `${WebServerInternalPath.ACCEPT_INVITE}?${OrgInviteQueryParams.HUB_ID}=${qp.get(
          OrgInviteQueryParams.HUB_ID
        )}&${OrgInviteQueryParams.INVITE_ID}=${qp.get(OrgInviteQueryParams.INVITE_ID)}`
      );
    }
  }, []);

  React.useEffect(() => {
    if (zoodliForUsersEnabled && location?.hash?.includes(HomePageEls.ZOODLI_CARD)) {
      zoodliCardScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [zoodliCardScrollRef.current]);

  const [dbCourses, dbCoursesMeta] = useOnAll<db.CourseInfo>(db.courseList(getSiteId()));
  React.useEffect(() => {
    const error = dbCoursesMeta?.error;
    if (error) {
      console.error(error);
    }
  }, [dbCoursesMeta?.error]);

  const latestSpeechesQueryLimit = 25;
  const [_dbLatestSpeeches, _dbLatestSpeechesMeta] = useOnQuery<db.Speech>(
    db.userSpeeches([getSiteId(), firebase.auth().currentUser?.uid]),
    [
      db.where("lifecycleState", "in", [LifecycleState.CREATED, LifecycleState.REDACTED]),
      db.order("recordedDate", "desc"),
    ]
  );
  const dbLatestSpeeches = (_dbLatestSpeeches ?? [])
    .filter((dbSpeech) => !dbSpeech.data.usageDecremented)
    .slice(0, latestSpeechesQueryLimit);

  const programMemberViewQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.MemberView, { userId: user?.uid }],
    queryFn: () => listProgramsMemberView(),
    enabled: !!userInOrg,
  });

  // removing + completing checklist if more than 5 recorded speeches or if user is a program member
  React.useEffect(() => {
    if (
      (dbLatestSpeeches.length >= 5 || programMemberViewQuery.data?.length > 0) &&
      showOnboardingChecklist
    ) {
      setShowOnboardingChecklist(false);
      if (!onboardingChecklistCompleted) {
        markAllOnboardingTasksComplete().catch((er) => {
          console.error("failed to mark all onboarding tasks complete", er);
        });
      }
    }
  }, [programMemberViewQuery.data, dbLatestSpeeches]);

  // Since we're using a limit query, we need to manually count the number of long speeches (>= 30 seconds).
  const numLongSpeeches = React.useMemo(() => {
    if (!dbLatestSpeeches) {
      return 0;
    }
    const longSpeechCount = dbLatestSpeeches?.filter(
      (speech) => speech.data.totalTimeS >= 30
    ).length;
    // If all of the users recent speeches are less than 30 seconds,
    // assume they have 5 long speeches in their whole library to make render logic simple.
    if (longSpeechCount === 0 && dbLatestSpeeches.length === latestSpeechesQueryLimit) {
      return 5;
    }
    return longSpeechCount;
  }, [dbLatestSpeeches]);

  const onboardingChecklistDoc = userDoc[db.UserDocTypes.MAIN]
    .onboardingChecklist as OnboardingChecklistDoc;
  const onboardingChecklistCompleted =
    !onboardingChecklistDoc || !!onboardingChecklistDoc?.completedTimestamp;
  const productTipsDoc = userDoc[db.UserDocTypes.MAIN].productTips as ProductTipsDoc;

  const userDocPublic = userDoc[db.UserDocTypes.PUBLIC];
  const coachWelcomeSlug = getCoachTutorialSlug();
  const showCoachChecklist = shouldShowCoachChecklist();

  React.useEffect(() => {
    const currentName = currentUserName();
    const firstName = currentUserFirstName();

    if (firstName) {
      setDisplayName(firstName);
    } else if (currentName) {
      setDisplayName(currentName);
    }

    Instrumentation.logAmplitudeEvent(HomePageChecklistEvents.VIEWED);
  }, []);

  React.useEffect(() => {
    if (user && userDocPublic) {
      const updatedDailyStreak = determineDailyStreak();
      setDailyStreak(updatedDailyStreak);
    }
  }, [userDocPublic]);

  React.useEffect(() => {
    if (onboardingChecklistCompleted) {
      // Wait 3 seconds so user can see checklist complete state first.
      setTimeout(() => {
        setShowOnboardingChecklist(false);
      }, 3000);
    } else if (onboardingChecklistDoc) {
      !isToastmasters() && setShowOnboardingChecklist(true);
    }
  }, [onboardingChecklistCompleted, !!onboardingChecklistDoc]);

  // #endregion

  const memberContentQuery = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY],
    queryFn: () => getOrgContentMemberViewV2(defaultOrg?.id),
    enabled: !!defaultOrg,
  });

  // #region Sample Speeches

  // Sample speeches have analytics
  const [siteSampleSpeechItems, setSiteSampleSpeechItems] = React.useState<db.Doc<db.Speech>[]>([]);
  const [sampleSpeechesLoading, setSampleSpeechesLoading] = React.useState<boolean>(true);
  const [poodliCalConnectError, setPoodliCalConnectError] = React.useState<boolean>(false);

  const [dbSampleSpeeches, dbSampleSpeechesMeta] = useOnQuery<db.SampleSpeech>(
    db.sampleSpeeches(getSiteId()),
    [db.order("id", "asc")]
  );

  const fetchSiteSampleSpeeches = async (): Promise<void> => {
    let _dbSpeeches: db.Doc<db.Speech>[] = [];
    if (isWhiteLabel()) {
      const filteredSampleSpeeches = fetchItemsByAudience(
        dbSampleSpeeches
      ) as db.Doc<db.SampleSpeech>[];
      const authHeaders = await AuthorizationAPIHeaders();
      _dbSpeeches = await Promise.all(
        _.map(filteredSampleSpeeches, async (sampleSpeechItem: db.Doc<db.SampleSpeech>) => {
          // get corresponding Speech object
          const speech = await db.get(db.pathToRef<db.Speech>(sampleSpeechItem.data.path));
          if (speech) {
            // get corresponding thumbnail URL and set it to a field of speech object.
            // It is a lazy design to insert such information as a part of Firestore doc schema,
            // but it is taken because feature speeches are used only by toastmasters and
            // we expect they are gone soon-ish. At that time, this entire stuff may go away.
            // FeaturedSpeech is designed to take db.Speech and there are several layers
            // from this point and such intermediate layers handle other data types in parallel.
            // It would be quite a work to define a new wrapper type and pipe through all layers.
            const { userId, speechId } = db.pathToIds(sampleSpeechItem.data.path);
            const thumbnailsResult = await getSpeechThumbnails(
              [
                {
                  speechId: speechId,
                  ownerId: userId,
                },
              ],
              authHeaders
            );
            speech.data.featuredSpeechThumbnail = thumbnailsResult[0];
          }
          return speech;
        })
      );

      // Remove speeches that don't have documents and are not visible
      _dbSpeeches = _.filter(
        _dbSpeeches,
        (item) => !!item?.data && item.data.lifecycleState === LifecycleState.CREATED
      );
    }

    const userDocMain = getLiveUserDocMain();

    if (!userDocMain.sampleSpeechesVisited && _dbSpeeches.length) {
      setSiteSampleSpeechItems(_dbSpeeches);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateUserDocMain(getSiteId(), user.uid, { sampleSpeechesVisited: true });
    } else {
      const isoDate = new Date().toISOString().slice(0, 10);
      seedrandom(isoDate, { global: true });
      setSiteSampleSpeechItems(_dbSpeeches.sort(() => Math.random() - 0.5));
    }
    setSampleSpeechesLoading(false);
  };

  React.useEffect(() => {
    if (dbSampleSpeeches) {
      fetchSiteSampleSpeeches().catch(console.error);
    }
  }, [dbSampleSpeeches]);

  if (dbSampleSpeechesMeta.error) {
    console.error(dbSampleSpeechesMeta.error);
    throw new Error(dbSampleSpeechesMeta.error.toString());
  }

  // Sample videos show up in lightboxes
  const [siteSampleVideos, setSiteSampleVideos] = React.useState<db.Doc<db.SampleVideo>[]>([]);
  const [sampleVideosLoading, setSampleVideosLoading] = React.useState<boolean>(true);

  const [createOrgBannerDismissed, setCreateOrgBannerDismissed] = React.useState<boolean>(
    !!localStorage.getItem(CREATE_ORG_BANNER_DISMISSED)
  );

  const [dbSampleVideos, dbSampleVideosMeta] = useOnQuery<db.SampleVideo>(
    db.sampleVideos(getSiteId()),
    [db.order("id", "asc")]
  );

  React.useEffect(() => {
    if (dbSampleVideos) {
      setSiteSampleVideos(fetchItemsByAudience(dbSampleVideos) as db.Doc<db.SampleVideo>[]);
      setSampleVideosLoading(false);
    }
  }, [dbSampleVideos]);

  if (dbSampleVideosMeta.error) {
    console.error(dbSampleVideosMeta.error);
    throw new Error(dbSampleVideosMeta.error.toString());
  }

  // #endregion

  // #region Download Poodli

  const mapDemoVideosToCarouselContent = (
    demoVideos: DemoVideoResponse[]
  ): BaseCarouselVideoContent[] => {
    return demoVideos.map((video) => {
      return {
        data: {
          id: video.id,
          title: video.title,
          videoURL: video.view_url,
          thumbnailPath: video.thumbnail_url,
          audience: AudienceType.ALL,
        },
      };
    });
  };
  const mapCoursesToCarouselContent = (courses: CourseResponse[]): BaseCarouselCourseContent[] => {
    if (!courses) return [];
    return courses.map((course) => {
      return {
        data: {
          id: course.id,
          items: course.videos.map((video) => {
            return {
              title: video.title,
              description: video.description,
              videoURL: video.view_url,
              thumbnail: video.thumbnail_url,
              isLocked: false, // TODO: update appropriately #11547
            };
          }),
          description: course.description,
          title: course.title,

          thumbnail: course?.videos[0]?.thumbnail_url ?? null,
        },
      };
    });
  };

  // #endregion

  // #region Render

  const showOrgBrandLogo = isMobile && !!defaultOrgPrimaryLogo;
  const showDailyStreak = !showOnboardingChecklist && dbLatestSpeeches?.length >= 3;

  const showTipsCarousel =
    !showOnboardingChecklist &&
    (productTipsDoc?.currentWebclientTipGroup || pricingUsage?.thresholdHit) &&
    !isToastmasters() &&
    !(userInCoachOrganization || userInEnterpriseOrganization);

  const showRecentSessions = numLongSpeeches >= 3 && !isToastmasters();
  const showCoursesCarousel = !showOnboardingChecklist && !isToastmasters();
  const showWelcomeVideos =
    !showOnboardingChecklist &&
    !!memberContentQuery?.data?.demo_videos?.length &&
    !isToastmasters();
  const showCreateOrgBanner =
    !showOnboardingChecklist &&
    !isToastmasters() &&
    !userInOrg &&
    isCoach() &&
    !createOrgBannerDismissed;

  const isContentBelowChecklist =
    showDailyStreak ||
    showTipsCarousel ||
    showRecentSessions ||
    showCoursesCarousel ||
    showOnboardingChecklist;

  const showMobileView = showCalConnectSidebar
    ? useIsScreenSmallerThanPx(1350)
    : useIsMediumScreen();

  const pageContentTitle = () => {
    if (showOnboardingChecklist || showCreateOrgBanner) {
      return isSmallScreen || !displayName
        ? "Finish Setting Up"
        : `Hi ${displayName}! Finish Setting Up`;
    } else {
      return displayName ? `Hi ${displayName}!` : "Hi!";
    }
  };

  // Don't mark loading if loading the course content, as that could
  // be slower & should not hang the entire page waiting for it.
  const loading =
    dbCoursesMeta.loading ||
    sampleSpeechesLoading ||
    sampleVideosLoading ||
    programMemberViewQuery.isFetching;

  const renderCoursesCarousel = () => {
    // hide homepage courses if we shouldn't show them, if they're loading, or if we errored out while loading them
    // its important we "fail closed" for admin branding reasons
    if (!showCoursesCarousel || memberContentQuery.isPending) {
      return null;
    }

    const showHubCourses = memberContentQuery.data.courses.length;
    // always hide Yoodli courses for coaching or ENT organizations
    if ((userInCoachOrganization || userInEnterpriseOrganization) && !showHubCourses) {
      return null;
    }

    const qp = new URLSearchParams({ [MyLearningQueryParams.TAB]: MyLearningTabs.Courses });

    const carouselItems = showHubCourses
      ? mapCoursesToCarouselContent(memberContentQuery.data.courses)
      : dbCourses;
    const carouselContent = {
      items: carouselItems,
      title: `${showHubCourses ? `${defaultOrg.name} ` : ""}Courses`,
      // only show the "view in settings" CTA if the user is an owner/admin of the hub
      titleCta: showHubCourses &&
        isOrgOwnerAdmin && {
          copy: "View in settings",
          onClick: () => {
            // Redirect to the org content page
            history.push({
              pathname: WebServerExternalPath.MY_LEARNING,
              search: qp.toString(),
            });
          },
        },
      type: CarouselContentType[
        memberContentQuery?.data?.courses.length ? "HUB_COURSE" : "FEATURED_COURSE"
      ],
      itemOnClick: (courseId: string) => {
        if (showHubCourses) {
          history.push({
            pathname: WebServerExternalPath.MY_LEARNING,
            search: qp.toString(),
            hash: `course-${courseId}`,
          });
        } else {
          window.location.href = `${getLandingPageExternalUrl(
            getClientEnvConfig(),
            LandingPageExternalPath.COURSES
          )}/${courseId}`;
        }
      },
    };

    const loading =
      !carouselItems?.length ||
      memberContentQuery.isPending ||
      dbCoursesMeta?.loading ||
      !dbCourses?.length;
    return (
      <Box sx={{ ...HOME_PAGE_ITEM_PADDING, minHeight: 200 }}>
        <ItemCarousel {...carouselContent} loadingContent={loading} />
      </Box>
    );
  };

  const renderVideoCarousel = () => {
    if (
      (!showWelcomeVideos ||
        (showWelcomeVideos && !memberContentQuery?.data?.demo_videos?.length)) &&
      !isToastmasters()
    ) {
      return null;
    }
    return (
      <HomepageContainer>
        {showWelcomeVideos ? (
          <Box sx={HOME_PAGE_ITEM_PADDING}>
            <ItemCarousel
              items={mapDemoVideosToCarouselContent(memberContentQuery?.data?.demo_videos)}
              title={`${defaultOrg ? `${defaultOrg?.name} ` : ""}Welcome Videos`}
              setLightboxURL={setLightboxURL}
              type={CarouselContentType.VIDEO}
              showCoachChecklist={showCoachChecklist}
              loadingContent={memberContentQuery.isPending}
              coachWelcomeSlug={coachWelcomeSlug}
            />
          </Box>
        ) : (
          <>
            <ItemCarousel
              items={siteSampleVideos}
              title="Tutorials"
              type={CarouselContentType.VIDEO}
              setLightboxURL={setLightboxURL}
              showCoachChecklist={showCoachChecklist}
              coachWelcomeSlug={coachWelcomeSlug}
            />
            <ItemCarousel
              items={siteSampleSpeechItems}
              title="Today's featured speeches"
              type={CarouselContentType.SPEECH}
              showCoachChecklist={showCoachChecklist}
              coachWelcomeSlug={coachWelcomeSlug}
            />
          </>
        )}
      </HomepageContainer>
    );
  };

  const siteConfig = getStaticFullSiteConf();

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      height={`calc(100vh - ${isSmallScreen ? MOBILE_TOP_NAVBAR_HEIGHT : "0px"})`}
      alignItems="center"
    >
      <CircularProgress size={100} />
    </Box>
  ) : (
    <>
      {isToastmasters() && (
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: siteConfig?.colors?.primary ?? getDynamicColor("purple3"),
            px: 2,
            py: 1,
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              color: getDynamicColor("light1"),
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Looking for advanced AI feedback? Visit{" "}
            <Link
              sx={{
                fontWeight: 700,
                color: getDynamicColor("bannerLinkColor"),
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              href="https://www.yoodli.ai"
              target="_blank"
            >
              www.yoodli.ai
            </Link>
          </Typography>
        </Stack>
      )}
      <Grid
        container
        spacing={0}
        sx={{
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          sm={12}
          md={showCalConnectSidebar ? 8 : 12}
          sx={{
            backgroundColor: getDynamicColor("light1"),
            width: "100%",
            pb: 8,
          }}
        >
          {showOnboardingChecklist && onboardingChecklistDoc && (
            <Stack
              sx={{
                background: getDynamicColor("gradient.slantedLight"),
                height: isContentBelowChecklist ? "fit-content" : "100%",
                mb: isContentBelowChecklist ? 1 : 0,
              }}
            >
              {showOrgBrandLogo && (
                <img
                  src={defaultOrgPrimaryLogo}
                  style={{
                    maxWidth: "287px",
                    maxHeight: "88px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                />
              )}
              <Checklist
                data-logrocket-hidden
                displayName={displayName}
                onboardingChecklistDoc={onboardingChecklistDoc}
              />
            </Stack>
          )}
          {(!showOnboardingChecklist || showDailyStreak || showOrgBrandLogo) && (
            <HomepageContainer
              sx={{
                mb: 2,
              }}
            >
              {!showOnboardingChecklist && (
                <Typography
                  data-logrocket-hidden
                  sx={{ fontWeight: 700, fontSize: 24, fontFamily: "poppins" }}
                >
                  {pageContentTitle()}
                </Typography>
              )}
              {showDailyStreak && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  alignItems="flex-end"
                  sx={{
                    position: "relative",
                    top: { xs: -30, md: -10 },
                    right: 0,
                    height: 8,
                  }}
                >
                  <Tooltip
                    title={isMobile ? "" : "Use Yoodli everyday to build your streak!"}
                    placement="bottom-end"
                  >
                    <Stack
                      direction="row"
                      gap={0.5}
                      alignItems="center"
                      sx={{
                        cursor: "default",
                        ml: { xs: 0, sm: "auto" },
                        mr: { xs: 0, sm: 2 },
                        py: 0.5,
                      }}
                    >
                      <Typography sx={{ fontFamily: "poppins", fontSize: 14, fontWeight: 700 }}>
                        {dailyStreak} Day Streak
                      </Typography>
                      <BoltIcon
                        style={{
                          height: 22,
                          width: 22,
                        }}
                      />
                    </Stack>
                  </Tooltip>
                </Stack>
              )}

              {showOrgBrandLogo && (
                <img
                  src={defaultOrgPrimaryLogo}
                  style={{
                    maxWidth: "287px",
                    maxHeight: "88px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                />
              )}
            </HomepageContainer>
          )}
          <HomepageContainer>
            <ProgramsCarousel programMemberViewQuery={programMemberViewQuery} />
          </HomepageContainer>
          {showCreateOrgBanner && (
            <HomepageContainer>
              <CreateOrgBanner
                setCreateOrgBannerDismissed={setCreateOrgBannerDismissed}
                showDismissButton
              />
            </HomepageContainer>
          )}
          {renderVideoCarousel()}
          {numLongSpeeches >= 1 && (
            <div
              style={{
                paddingBottom: "16px",
              }}
            >
              <ReportCard
                dbLatestSpeeches={dbLatestSpeeches}
                setLightboxURL={setLightboxURL}
                userInCoachOrg={userInCoachOrganization}
                showCalConnectSidebar={showCalConnectSidebar}
                userInEnterpriseOrg={userInEnterpriseOrganization}
                hubContentLoadingComplete={!memberContentQuery.isPending}
              />
            </div>
          )}
          {(showTipsCarousel ||
            showRecentSessions ||
            isWhiteLabel() ||
            showCoursesCarousel ||
            !showOnboardingChecklist) && (
            <HomepageContainer>
              {showRecentSessions && (
                <Box sx={HOME_PAGE_ITEM_PADDING}>
                  <RecentSessions dbLatestSpeeches={dbLatestSpeeches} />
                </Box>
              )}
              {renderCoursesCarousel()}

              {showTipsCarousel && (
                <Box sx={HOME_PAGE_ITEM_PADDING}>
                  <ProductTips productTipsDoc={productTipsDoc} showMobileView={showMobileView} />
                </Box>
              )}

              {/* Always show zoodli card */}
            </HomepageContainer>
          )}
          {zoodliForUsersEnabled && (
            <HomepageContainer
              sx={{
                pt: isContentBelowChecklist ? 0 : 2,
              }}
            >
              <ZoodliCard />
            </HomepageContainer>
          )}
          {lightbox}
        </Grid>

        {showCalConnectSidebar && (
          <Grid item sm={12} md={4}>
            <Stack
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: getDynamicColor("light1"),
                alignItems: "center",
                borderLeft: `1px solid ${getDynamicColor("dark3")}`,
              }}
            >
              <Box
                sx={{
                  position: { md: "fixed" },
                }}
              >
                <Stack
                  gap={3}
                  sx={{
                    pt: 1,
                    pb: 2,
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Calendar
                    dbCalendars={dbCalendars}
                    dbCalendarsMeta={dbCalendarsMeta}
                    isHomePage
                  />
                  {poodliCalConnectError && (
                    <Alert severity="error" sx={{ justifyContent: "center" }}>
                      Error: Could not connect calendar
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Grid>
        )}
        <GoogleOAuthProvider
          clientId={envConfig.gCloudClientId}
          onScriptLoadSuccess={() => setGoogleAuthProviderReady(true)}
        >
          <PoodliCalConnectProvider
            googleAuthProviderReady={googleAuthProviderReady}
            setPoodliCalConnectError={setPoodliCalConnectError}
            handleCalConnectComplete={() =>
              Instrumentation.logCalConnectedCheckpoint(EventsCalHows.GOOGLE, EventsCalWheres.HOME)
            }
          />
        </GoogleOAuthProvider>
      </Grid>
    </>
  );

  // #endregion
}

const HomepageContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
  return (
    <Container
      maxWidth="lg"
      className="homepage-"
      sx={{
        position: "relative",
        px: "32px !important",
        mt: 2,
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
