/**
 * List of available voices
 */

import { Language, RecordingSupportedLanguage } from "./enums";

/**
 * Metadata of individual voices
 * This type definition helps automatic interpolation in IDE.
 */
export type VoiceMetadata = {
  voiceId: string;
  name: string;
  sampleUrl: string;
  defaultProfilePictureId: string;
  /**
   * Alternative voices for different languages. Currently, this is unused.
   * TODO, when we decide how to handle language voices, use or delete
   */
  altLanguageVoices?: Map<RecordingSupportedLanguage, string>;
};

/**
 * List of voices as a map
 * Current implementation uses the same ID for our ID (key of the map)
 * and the provider's voice ID (stored in the metadata)
 */
export const voices = new Map<string, VoiceMetadata>([
  [
    "iP95p4xoKVk53GoZ742B",
    {
      voiceId: "iP95p4xoKVk53GoZ742B",
      name: "Chris",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/chris.mp3",
      defaultProfilePictureId: "avatar_person12",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "Nh2zY9kknu6z4pZy6FhD", // David Martin 1
        ],
        [
          Language.French,
          "aQROLel5sQbj1vuIVi6B", // Nicolas
        ],
      ]),
    },
  ],
  [
    "kW8nczNUtnUazCRdJWnf",
    {
      voiceId: "kW8nczNUtnUazCRdJWnf",
      name: "Esha",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/esha.mp3",
      defaultProfilePictureId: "priyaAnand",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "2Lb1en5ujrODDIqmp7F3", // Jhenny Antiques
        ],
        [
          Language.French,
          "FvmvwvObRqIHojkEGh5N", // Adina
        ],
      ]),
    },
  ],
  [
    "pFZP5JQG7iQjIQuC4Bku",
    {
      voiceId: "pFZP5JQG7iQjIQuC4Bku",
      name: "Lily",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/lily.mp3",
      defaultProfilePictureId: "agnesPotts",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "2Lb1en5ujrODDIqmp7F3", // Jhenny Antiques
        ],
        [
          Language.French,
          "FvmvwvObRqIHojkEGh5N", // Adina
        ],
      ]),
    },
  ],
  [
    "EXAVITQu4vr4xnSDxMaL",
    {
      voiceId: "EXAVITQu4vr4xnSDxMaL",
      name: "Sarah",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/sarah.mp3",
      defaultProfilePictureId: "avatar_person8",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "KHCvMklQZZo0O30ERnVn", // Sara Martin 1
        ],
        [
          Language.French,
          "McVZB9hVxVSk3Equu8EH", // Audrey
        ],
      ]),
    },
  ],
  [
    "x3gYeuNB0kLLYxOZsaSh",
    {
      voiceId: "x3gYeuNB0kLLYxOZsaSh",
      name: "Shrey",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/shrey.mp3",
      defaultProfilePictureId: "kevinBrown",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "W5JElH3dK1UYYAiHH7uh", // Martin Osborne 2
        ],
        [
          Language.French,
          "j9RedbMRSNQ74PyikQwD", // Louis Boutin
        ],
      ]),
    },
  ],

  [
    "fw990JFw2cwzIf7uWevV",
    {
      voiceId: "fw990JFw2cwzIf7uWevV",
      name: "Summer",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/summer.mp3",
      defaultProfilePictureId: "avatar_person1",
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "2Lb1en5ujrODDIqmp7F3", // Jhenny Antiques
        ],
        [
          Language.French,
          "FvmvwvObRqIHojkEGh5N", // Adina
        ],
      ]),
    },
  ],
]);
