import { Moment } from "moment";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Components
import { ChevronLeft } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { UpdateOrgModal } from "./UpdateOrgModal";
import { AxiosError } from "axios";
import {
  adminGetOrgById,
  getOrgV2,
  adminListScenarios,
  adminCopyScenario,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { AdminGetOrgFieldType, AdminGetOrgResponse } from "lib-fullstack/api/adminApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { getAppEnv, getHubSpotFrontendEnvConfig } from "lib-fullstack/client_env";
import { WebServerInternalPath } from "utils/paths";
import { ListScenariosResponse } from "lib-fullstack/api/hubApiTypes";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

const hubSpotEnv = getHubSpotFrontendEnvConfig(getAppEnv());

const labelStyles = {
  fontWeight: "600",
  fontSize: "14px",
  my: 1,
  color: getDynamicColor("dark5"),
  fontFamily: "Poppins",
};

const getDataRedactionText = (days: number) => {
  if (days === 0) {
    return "Let members choose";
  }

  return `Every ${days} day${days > 1 ? "s" : ""}`;
};

export type OrgDetailCard = {
  label: string;
  items: Array<OrgDetailCardItem>;
  editable?: boolean;
  tooltip?: string;
};

type OrgDetailCardItem = {
  label: string;
  value: string | JSX.Element;
  data: boolean | number | string | string[] | Moment;
  tooltip?: string;
};

export enum OrgDetailCardLabel {
  ORG = "Organization",
  OWNER_ADMINS = "Owner and Admins",
  SUBSCRIPTION = "Subscription",
  HUBSPOT = "HubSpot Integration",
  QUOTAS = "Quotas",
  FEATURE_FLAGS = "Feature Flags",
}

export enum OrgDetailCardItemLabel {
  ORG_ID = "Org ID",
  CUSTOMER_TYPE = "Customer Type",
  OBQ1 = "Default member experience",
  HUBS = "Groups",
  DATA_REDACTION = "Data Retention",
  OWNER = "Owner",
  ADMINS = "Admins",
  STRIPE_ID = "Stripe ID",
  SUBSCRIPTION_STATUS = "Status",
  SUBSCRIPTION_TYPE = "Type",
  SEATS_LICENSED = "Seats Licensed",
  LICENSE_END_DATE = "End Date",
  PAYMENT_METHOD_ADDED = "Payment Method Added",
  HUBSPOT_INSTANCE_ID = "Instance ID",
  COURSE_QUOTA = "Courses",
  VIDEO_QUOTA = "Videos per course",
  DEMO_QUOTA = "Demo videos",
  HUB_QUOTA = "Groups in org",
  COACHBOT_ENABLED = "Coach Bot enabled",
  INTERVIEW_ENABLED = "Interview enabled",
  INACTIVE_SEAT_DAYS = "Days until a seat becomes inactive",
}

export const AdminConfigOrgDetails = (): JSX.Element => {
  const history = useHistory();
  const { orgId } = useParams<Record<string, string | undefined>>();
  const [orgDescriptors, setOrgDescriptors] = React.useState<OrgV2Response>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cards, setCards] = React.useState<Array<OrgDetailCard>>([]);
  const [scenarios, setScenarios] = React.useState<ListScenariosResponse>(null);
  const [selectedScenario, setSelectedScenario] = React.useState<string>(null);
  const [newScenarioOrgId, setNewScenarioOrgId] = React.useState<string>(null);
  const [scenarioMessage, setScenarioMessage] = React.useState<string>("");
  const [cardToUpdate, setCardToUpdate] = React.useState<OrgDetailCard>();
  const [rerenderKey, setRerenderKey] = React.useState(0);

  React.useEffect(() => {
    const fetchOrgDetails = async () => {
      setLoading(true);

      const [orgDet, orgDesc, orgScenarios] = await Promise.all([
        adminGetOrgById(orgId, {
          fields: [
            AdminGetOrgFieldType.FEATURES,
            AdminGetOrgFieldType.HUBSPOT,
            AdminGetOrgFieldType.SUBSCRIPTION,
            AdminGetOrgFieldType.QUOTAS,
            AdminGetOrgFieldType.OWNERS_AND_ADMINS,
          ],
        }),
        getOrgV2(orgId),
        adminListScenarios(orgId),
      ]);

      setOrgDescriptors(orgDesc);

      const newCards: Array<OrgDetailCard> = [
        populateOrgCard(orgDesc),
        populateOwnerAdminsCard(orgDet),
        populateSubscriptionCard(orgDet),
        populateHubspotCard(orgDet),
        populateQuotasCard(orgDet),
        populateFeaturesCard(orgDet),
      ];
      setCards(newCards);
      setScenarios(orgScenarios);
      setLoading(false);
    };
    if (orgId) {
      fetchOrgDetails().catch((e) => {
        console.log("failed to fetch org info", e);
        // If not authorized, redirect to home page (which presents as 404).
        if ((e as AxiosError)?.response?.status === 403) {
          history.push(WebServerInternalPath.HOME);
        }
        setLoading(false);
      });
    }
  }, [rerenderKey]);

  const populateOrgCard = (data: OrgV2Response): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.ORG_ID,
        value: <a href={`${WebServerInternalPath.ORG_SETTINGS}?org=${data.id}`}>{data.id}</a>,
        data: data.id,
      },
      {
        label: OrgDetailCardItemLabel.CUSTOMER_TYPE,
        value: data.customer_type,
        data: data.customer_type,
      },
      {
        label: OrgDetailCardItemLabel.OBQ1,
        value: data.default_onboarding,
        data: data.default_onboarding,
      },
      {
        label: OrgDetailCardItemLabel.HUBS,
        value: data.hubs.length.toString(),
        data: data.hubs.length,
      },
      {
        label: OrgDetailCardItemLabel.DATA_REDACTION,
        value: getDataRedactionText(data.data_redaction_days),
        data: data.data_redaction_days,
      },
    ];
    return {
      label: OrgDetailCardLabel.ORG,
      items: cardItems,
    } as OrgDetailCard;
  };

  const populateOwnerAdminsCard = (data: AdminGetOrgResponse): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.OWNER,
        value: data.ownersAndAdmins?.ownerEmails[0] || "N/A",
        data: data.ownersAndAdmins?.ownerEmails[0],
      },
      {
        label: OrgDetailCardItemLabel.ADMINS,
        value: renderEmailList(data.ownersAndAdmins?.adminEmails) || "N/A",
        data: data.ownersAndAdmins?.adminEmails,
      },
    ];
    return {
      label: OrgDetailCardLabel.OWNER_ADMINS,
      items: cardItems,
      editable: true,
    } as OrgDetailCard;
  };

  const populateSubscriptionCard = (data: AdminGetOrgResponse): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.STRIPE_ID,
        value: data.subscription?.stripeId ? (
          <a
            href={`https://dashboard.stripe.com/customers/${data.subscription?.stripeId}`}
            target="_blank"
          >
            {data.subscription?.stripeId}
          </a>
        ) : (
          "N/A"
        ),
        data: data.subscription?.stripeId,
      },
      {
        label: OrgDetailCardItemLabel.SUBSCRIPTION_STATUS,
        value: data.subscription?.subscriptionStatus && data.subscription?.subscriptionStatus,
        data: data.subscription?.subscriptionStatus,
      },
      {
        label: OrgDetailCardItemLabel.SUBSCRIPTION_TYPE,
        value:
          data.subscription?.subscriptionType && data.subscription?.subscriptionType.toUpperCase(),
        data: data.subscription?.subscriptionType,
      },
      {
        label: OrgDetailCardItemLabel.SEATS_LICENSED,
        value: data.subscription?.seatsLicensed && data.subscription?.seatsLicensed.toString(),
        data: data.subscription?.seatsLicensed,
      },
      {
        label: OrgDetailCardItemLabel.LICENSE_END_DATE,
        value:
          data.subscription?.licenseEndDate &&
          new Date(data.subscription?.licenseEndDate).toLocaleDateString(),
        data: data.subscription?.licenseEndDate,
      },
      {
        label: OrgDetailCardItemLabel.PAYMENT_METHOD_ADDED,
        value: data.subscription?.paymentMethodAdded + "",
        data: data.subscription?.paymentMethodAdded,
      },
      {
        label: OrgDetailCardItemLabel.INACTIVE_SEAT_DAYS,
        value:
          data.subscription?.numSeatInactiveDays > 0
            ? data.subscription?.numSeatInactiveDays.toString()
            : "Never",
        data: data.subscription?.numSeatInactiveDays,
        tooltip:
          "Members are marked inactive (and no longer take up an org seat) after this many days",
      },
    ];
    return {
      label: OrgDetailCardLabel.SUBSCRIPTION,
      items: cardItems,
      editable: true,
    } as OrgDetailCard;
  };

  const populateHubspotCard = (data: AdminGetOrgResponse): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.HUBSPOT_INSTANCE_ID,
        value: data.hubSpot?.instanceId ? (
          <a
            href={`https://app.hubspot.com/contacts/${hubSpotEnv.portalId}/record/${hubSpotEnv.yoodliInstanceModelId}/${data.hubSpot?.instanceId}`}
            target="_blank"
          >
            {data.hubSpot?.instanceId}
          </a>
        ) : (
          "N/A"
        ),
        data: data.hubSpot?.instanceId,
        tooltip: "Click to view the Yoodli instance in HubSpot.",
      },
    ];
    return {
      label: OrgDetailCardLabel.HUBSPOT,
      items: cardItems,
      editable: false,
    } as OrgDetailCard;
  };

  const populateQuotasCard = (data: AdminGetOrgResponse): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.COURSE_QUOTA,
        value: data.quotas?.courseQuota.toString(),
        data: data.quotas?.courseQuota,
      },
      {
        label: OrgDetailCardItemLabel.VIDEO_QUOTA,
        value: data.quotas?.courseVideoQuota.toString(),
        data: data.quotas?.courseVideoQuota,
      },
      {
        label: OrgDetailCardItemLabel.DEMO_QUOTA,
        value: data.quotas?.demoVideoQuota.toString(),
        data: data.quotas?.demoVideoQuota,
      },
      {
        label: OrgDetailCardItemLabel.HUB_QUOTA,
        value: data.quotas?.hubQuota.toString(),
        data: data.quotas?.hubQuota,
      },
    ];
    return {
      label: OrgDetailCardLabel.QUOTAS,
      items: cardItems,
      editable: true,
    } as OrgDetailCard;
  };

  const populateFeaturesCard = (data: AdminGetOrgResponse): OrgDetailCard => {
    const cardItems: OrgDetailCardItem[] = [
      {
        label: OrgDetailCardItemLabel.COACHBOT_ENABLED,
        value: data.features?.coachBotEnabled.toString(),
        data: data.features?.coachBotEnabled,
      },
      {
        label: OrgDetailCardItemLabel.INTERVIEW_ENABLED,
        value: data.features?.interviewEnabled.toString(),
        data: data.features?.interviewEnabled,
      },
    ];
    return {
      label: OrgDetailCardLabel.FEATURE_FLAGS,
      items: cardItems,
      editable: true,
    } as OrgDetailCard;
  };

  const handleBack = () => {
    history.push(`${WebServerInternalPath.ADMIN_CONFIG}`);
  };

  const renderEmailList = (emails: Array<string>) => {
    return (
      <List component="span" sx={{ maxHeight: "150px", overflow: "auto", p: 0 }}>
        {emails.map((email) => (
          <ListItem key={email} sx={{ pl: 0, pt: 0 }}>
            {email}
          </ListItem>
        ))}
      </List>
    );
  };

  const copyScenario = async () => {
    const message = await adminCopyScenario(orgId, selectedScenario, newScenarioOrgId);
    setScenarioMessage(message);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: getDynamicColor("light1"),
      }}
      p={3}
    >
      <Stack>
        <Button
          onClick={handleBack}
          sx={{
            color: getDynamicColor("primary"),
            position: "relative",
            left: "-1rem",
          }}
          variant="text"
          startIcon={<ChevronLeft />}
        >
          Organizations
        </Button>
      </Stack>
      {loading ? (
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            component="h1"
            sx={{
              fontWeight: 600,
              fontSize: 20,
              color: getDynamicColor("purple3"),
              pt: 3,
              fontFamily: "poppins",
              maxWidth: { xs: "100%", md: "calc(100% - 140px)" },
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {orgDescriptors?.name}
          </Typography>
          <Stack p={2} gap={2}>
            {cards &&
              cards.map((card) => {
                if (card.items.some((item) => item.value)) {
                  return (
                    <Stack
                      key={card.label}
                      className="card"
                      sx={{ border: "1px solid lightgray", borderRadius: "8px" }}
                    >
                      <Stack
                        className="card-header"
                        borderBottom="1px solid lightgray"
                        p={2}
                        sx={{
                          backgroundColor: getDynamicColor("dark1"),
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction="row">
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "16px",
                              color: getDynamicColor("purple3"),
                            }}
                          >
                            {card.label}
                          </Typography>
                          {card.tooltip && (
                            <YoodliTooltip title={card.tooltip}>
                              <InfoOutlinedIcon sx={{ width: "16px" }} />
                            </YoodliTooltip>
                          )}
                        </Stack>
                        {card.editable && (
                          <IconButton
                            sx={{
                              color: getDynamicColor("purple3"),
                            }}
                            onClick={() => setCardToUpdate(card)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Stack>
                      <Stack
                        className="card-body"
                        display="flex"
                        flexDirection="row"
                        gap={3}
                        p={2}
                        flexWrap="wrap"
                      >
                        {card.items.map((item) => {
                          return (
                            <Stack key={item.label} display="flex">
                              <Stack direction="row">
                                <Typography sx={{ ...labelStyles }}>{item.label}</Typography>
                                {item.tooltip && (
                                  <Tooltip
                                    title={item.tooltip}
                                    placement="right"
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          backgroundColor: getDynamicColor("light1"),
                                          boxShadow: Y_SHADOWS.box_shadow_1,
                                          color: getDynamicColor("purple3"),
                                          border: `1px solid ${getDynamicColor("dark3")}`,
                                          borderRadius: "12px",
                                          fontSize: "14px",
                                          p: 2,
                                          width: "250px",
                                        },
                                      },
                                    }}
                                    sx={{ color: getDynamicColor("purple3") }}
                                  >
                                    <InfoOutlinedIcon sx={{ width: "20px" }} />
                                  </Tooltip>
                                )}
                              </Stack>
                              <Typography
                                sx={{
                                  ...labelStyles,
                                  color: getDynamicColor("primary"),
                                  fontSize: "16px",
                                  m: 0,
                                }}
                              >
                                {item.value ?? "N/A"}
                              </Typography>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                }
              })}
            {/* a scenario card. Scenarios are a dropdown list of scenario names, with a header. Next to that is a text input box and a button to add a new scenario. */}
            {scenarios?.contentArray?.length > 0 && (
              <Stack
                className="card"
                sx={{ border: "1px solid lightgray", borderRadius: "8px" }}
                key="scenarios"
              >
                <Stack
                  className="card-header"
                  borderBottom="1px solid lightgray"
                  p={2}
                  sx={{
                    backgroundColor: getDynamicColor("dark1"),
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "16px",
                        color: getDynamicColor("purple3"),
                      }}
                    >
                      Scenarios
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  className="card-body"
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  p={2}
                  flexWrap="wrap"
                >
                  <Stack direction="row" gap={2} alignItems="center">
                    <Stack>
                      <Typography sx={{ ...labelStyles }}>Scenario to copy</Typography>
                      <YoodliSelect
                        value={selectedScenario}
                        renderValue={(value) =>
                          scenarios.contentArray.find((scenario) => scenario.id === value)?.title
                        }
                        onChange={(e) => setSelectedScenario(e.target.value as string)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                      >
                        {scenarios.contentArray.map((scenario) => (
                          <MenuItem key={scenario.id} value={scenario.id}>
                            <Typography
                              sx={{
                                maxWidth: 500,
                                lineHeight: 1.3,
                                whiteSpace: "normal",
                              }}
                            >
                              {scenario.title}
                            </Typography>
                          </MenuItem>
                        ))}
                      </YoodliSelect>
                    </Stack>
                    <Stack>
                      <Typography sx={{ ...labelStyles }}>Org ID to copy to</Typography>
                      <YoodliTextfield
                        value={newScenarioOrgId}
                        onChange={(e) => setNewScenarioOrgId(e.target.value)}
                        maxChars={22}
                      />
                    </Stack>
                    <Stack>
                      <Typography sx={{ ...labelStyles }}>&nbsp;</Typography>
                      <Button
                        variant="contained"
                        sx={{ maxHeight: "50%", alignSelf: "center" }}
                        disabled={!selectedScenario || !newScenarioOrgId}
                        onClick={copyScenario}
                      >
                        Copy Scenario
                      </Button>
                    </Stack>
                    <Stack>
                      <Typography sx={{ ...labelStyles }}>&nbsp;</Typography>
                      <Typography
                        sx={{
                          color: scenarioMessage.includes("Error")
                            ? getDynamicColor("redError")
                            : getDynamicColor("greenSuccess"),
                        }}
                      >
                        {scenarioMessage}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          <UpdateOrgModal
            orgId={orgId}
            card={cardToUpdate}
            open={!!cardToUpdate}
            close={() => setCardToUpdate(null)}
            setRerenderKey={setRerenderKey}
          />
        </>
      )}
    </Stack>
  );
};
