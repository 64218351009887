import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Utils
import * as Sentry from "@sentry/react";
import {
  createAuthToken,
  getUser,
  listOrgsV2,
  patchUser,
} from "lib-frontend/modules/AxiosInstance";
import { getScopedUserId } from "lib-frontend/utils/AccountUtils";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import {
  Instrumentation,
  setGAUserIdAndProperties,
} from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  AuthQueryParams,
  HubsInviteRequiredQueryParams,
  OrgInviteQueryParams,
} from "lib-frontend/utils/queryParams";
import { GetUserFieldType } from "lib-fullstack/api/apiTypes";
import { GetOrgAccessInfoResponse } from "lib-fullstack/api/authApiTypes";
import { delay } from "lib-fullstack/utils/helperFunctions";

export function useURLQueryString(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export async function generateAuthToken(code: string): Promise<void> {
  const token = await firebase.auth().currentUser.getIdToken();

  await createAuthToken(code, token);
}

export async function completeUserLogin(userId: string): Promise<void> {
  let dbUser = await db.get(db.users(getSiteId()), userId);

  for (let i = 0; i < 3; i++) {
    // Potential race condition, poll db again
    if (!dbUser) {
      await delay(250);
      dbUser = await db.get(db.users(getSiteId()), userId);
    } else {
      break;
    }
  }

  if (!dbUser) {
    console.warn("completeUserLogin: dbUser not found!");
  }

  await setGAUserIdAndProperties();

  Sentry.setUser({
    id: getScopedUserId(),
  });
}

export async function generateAuthTokenForPoodliAndClose(code: string): Promise<void> {
  const finalPromises = [];
  finalPromises.push(generateAuthToken(code));
  finalPromises.push(Instrumentation.flush());
  await Promise.all(finalPromises);
  window.close();
}

export function fetchUrlSearchParams(params: URLSearchParams): string {
  const searchParams = new URLSearchParams();
  if (params.has("ot-auth-code")) {
    searchParams.set("ot-auth-code", params.get("ot-auth-code"));
  }
  if (params.has(OrgInviteQueryParams.OVERRIDE_ORG_ID)) {
    searchParams.set(
      OrgInviteQueryParams.OVERRIDE_ORG_ID,
      params.get(OrgInviteQueryParams.OVERRIDE_ORG_ID)
    );
  }
  if (params.has(OrgInviteQueryParams.ORG_ID)) {
    searchParams.set(OrgInviteQueryParams.ORG_ID, params.get(OrgInviteQueryParams.ORG_ID));
  }
  if (HubsInviteRequiredQueryParams.some((param) => params.has(param))) {
    HubsInviteRequiredQueryParams.forEach((param) => {
      if (params.has(param)) {
        searchParams.set(param, params.get(param));
      }
    });
  }
  return searchParams.toString();
}

export const ORG_ACCESS_INFO_QUERY_KEY = "orgAccessInfo";
export const SSO_MEMBERSHIP_ERROR_MESSAGE =
  "You currently aren't in a group that has access to this organization. Click below to continue to the free version of Yoodli.";

/**
 * Checks the user's SSO membership and updates the default organization if necessary.
 * Returns true if a membership in the org is found, otherwise false.
 */
export async function checkSsoMembershipAndUpdateDefaultOrg(
  orgAccessInfo: GetOrgAccessInfoResponse,
  params: URLSearchParams,
  invalidateDefaultOrgQuery: () => Promise<void>,
  invalidateUserQuery: () => Promise<void>
): Promise<boolean> {
  // Don't check SSO membership if user doesn't provide an org or invite id is provided
  if (
    (!params.has(OrgInviteQueryParams.ORG_ID) &&
      !params.has(OrgInviteQueryParams.OVERRIDE_ORG_ID)) ||
    params.has(AuthQueryParams.V2) ||
    params.has(AuthQueryParams.INVITE_ID)
  ) {
    return true;
  }

  const { default_org_id } = await getUser([GetUserFieldType.DEFAULT_ORG_ID]);

  // If the user's default org is not the org they are trying to access,
  // check list of memberships and update the default org if membership is found
  if (default_org_id !== orgAccessInfo.org_id) {
    const orgList = await listOrgsV2();
    const org = orgList.orgs.find((org) => org.id === orgAccessInfo.org_id);
    if (org) {
      // Membership found, update default org and return true
      await patchUser({ default_org_id: org.id });
      await invalidateDefaultOrgQuery();
      await invalidateUserQuery();
      return true;
    } else {
      // Membership not found, return false
      return false;
    }
  } else {
    // If the user's default org is the org they are trying to access, return true
    return true;
  }
}
