import React from "react";
import { useHistory, useLocation } from "react-router";

// Components
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CustomizePracticeTab } from "components/Orgs/ManageContent/CustomizePractice/CustomizePracticeTab";
import { CoachBotLibrary } from "components/Orgs/ManageContent/ManageCoachBotTab/CoachBotLibrary";
import OrgManageContentTabs from "components/Orgs/ManageContent/OrgManageContentTabs";
import { VideosAndCoursesTab } from "components/Orgs/ManageContent/VideosAndCourses/VideosAndCoursesTab";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { DefaultOrgSwitcher } from "../DefaultOrgSwitcher";
import { OrgLoading } from "../OrgLoading";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import { OrgManageContentModal } from "./OrgManageContentModal";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useCourseContentAndDemoVideosV2 from "hooks/useCourseContentAndDemoVideosV2";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createOrgInterviewBank,
  deleteOrgInterviewBank,
  getOrgContentView,
  listOrgInterviewBanks,
  updateOrgInterviewBank,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgContentQueryParams } from "lib-frontend/utils/queryParams";
import { GetScenarioResponse, InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { EventHubsWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { HubModalStatus } from "utils/Enums";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
// import { useQueryParamState } from "hooks/useQueryParamState";

export enum OrgSettingsTabs {
  CUSTOMIZE_PRACTICE = "customize-practice",
  COACHBOT = "coachbot",
  VIDEOS_COURSES = "welcome-videos-and-courses",
}

export enum OrgSettingsTabLabel {
  CUSTOMIZE_PRACTICE = "Customize Practice",
  COACHBOT = "Coach Bot",
  VIDEOS_COURSES = "Welcome videos & courses",
}

export const VIDEOS_AND_COURSES_QUERY_KEY = "videosAndCourses";
export const INTERVIEW_BANKS_QUERY_KEY = "interviewBanks";

export default function OrgManageContent(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const userOrgContext = React.useContext(UserOrgContext);

  const [selectedTab, setSelectedTab] = React.useState<OrgSettingsTabs>(
    OrgSettingsTabs.CUSTOMIZE_PRACTICE
  );
  const [modalStatus, setModalStatus] = React.useState<HubModalStatus>(HubModalStatus.CLOSED);
  const [navGuardModalOpen, setNavGuardModalOpen] = React.useState<boolean>(false);

  const [shouldBlockNav, setShouldBlockNav] = React.useState<boolean>(false);
  const [selectedInterviewBank, setSelectedInterviewBank] =
    React.useState<InterviewBankResponse>(undefined);

  const [showScenarioTemplates, setShowScenarioTemplates] = React.useState(false);
  const [templateSelected, setTemplateSelected] = React.useState<GetScenarioResponse>(null);

  const [showBackHeader, setShowBackHeader] = React.useState<boolean>(false);
  const [hideOrgSwitcher, setHideOrgSwitcher] = React.useState<boolean>(false);

  React.useEffect(() => {
    // if there is a query param of a tab, set the tab
    const qp = new URLSearchParams(window.location.search);
    const tab = qp.get(OrgContentQueryParams.TAB);

    if (tab && userOrgContext.defaultOrg) {
      if (tab === OrgSettingsTabs.COACHBOT && !userOrgContext.defaultOrg?.coachbot_enabled) {
        qp.set(OrgContentQueryParams.TAB, OrgSettingsTabs.CUSTOMIZE_PRACTICE);
        history.replace(`${location.pathname}?${qp.toString()}`);
      } else {
        setSelectedTab(tab as OrgSettingsTabs);
      }
    }
  }, [userOrgContext.defaultOrg]);

  const handleBack = (fromNavGuardModal?: boolean) => {
    if (shouldBlockNav && !fromNavGuardModal) {
      setNavGuardModalOpen(true);
    } else {
      setShowBackHeader(false);
      setShowScenarioTemplates(false);
      setTemplateSelected(null);
    }
  };

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, userOrgContext.orgId],
    queryFn: async () => getOrgContentView(userOrgContext.orgId),
    enabled: !!userOrgContext.defaultOrg,
  });

  const videosAndCourses = videosAndCoursesQueryResult.data;

  const interviewBanksQueryResult = useApiQuery({
    queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
    queryFn: async () => listOrgInterviewBanks(userOrgContext.orgId),
    enabled: !!userOrgContext.defaultOrg,
  });

  const interviewBanks = interviewBanksQueryResult.data;

  const {
    handleUpdateCourseVideos,
    handleCreateCourseVideos,
    handleCreateCourse,
    handleUpdateCourseVideo,
    handleDeleteCourseVideo,
    clearUploadingVideos,
    handleCreateDemoVideos,
    handleUpdateDemoVideo,
    handleDeleteDemoVideo,
    uploading,
    uploadingVideos,
    setSelectedCourse,
    selectedCourse,
    handleUpdateCourse,
    handleDeleteCourse,
  } = useCourseContentAndDemoVideosV2(
    userOrgContext.orgId,
    userOrgContext.defaultOrg?.hubs.find((hub) => hub.org_default).id
  );

  // If there is a processing video, poll for updates
  React.useEffect(() => {
    if (
      !videosAndCourses ||
      (!videosAndCourses.demo_videos.some(
        (video) => video.state === ContentVideoState.PROCESSING
      ) &&
        !videosAndCourses.courses.some((course) =>
          course.videos.some((video) => video.state === ContentVideoState.PROCESSING)
        ))
    ) {
      return;
    }
    const intervalId = setInterval(() => {
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, userOrgContext.orgId],
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [videosAndCourses]);

  const { mutateAsync: handleCreateInterviewBank } = useMutation({
    mutationFn: async ({ name, interviewQs }: { name: string; interviewQs: string[] }) => {
      await createOrgInterviewBank(
        userOrgContext.orgId,
        name,
        interviewQs,
        userOrgContext.defaultOrg?.hubs.map((hub) => hub.id) ?? []
      );
      Instrumentation.logOrgInterviewBankCreated(
        userOrgContext.orgId,
        name,
        interviewQs,
        userOrgContext.defaultOrg?.hubs.map((hub) => hub.id) ?? [],
        EventHubsWheres.ORG_SETTINGS
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
      });
    },
  });

  const { mutateAsync: handleDeleteInterviewBank } = useMutation({
    mutationFn: async () => {
      await deleteOrgInterviewBank(userOrgContext.orgId, selectedInterviewBank.id);
      Instrumentation.logOrgInterviewBankDeleted(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        EventHubsWheres.ORG_SETTINGS
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateInterviewBankName } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      await updateOrgInterviewBank(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        name,
        selectedInterviewBank.interview_questions,
        selectedInterviewBank.available_hubs
      );
      Instrumentation.logOrgInterviewBankUpdated(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        name,
        selectedInterviewBank.interview_questions,
        selectedInterviewBank.available_hubs,
        EventHubsWheres.ORG_SETTINGS
      );
      setModalStatus(HubModalStatus.CLOSED);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateInterviewBankQuestions } = useMutation({
    mutationFn: async ({ interviewQuestions }: { interviewQuestions: string[] }) => {
      await updateOrgInterviewBank(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        selectedInterviewBank.name,
        interviewQuestions,
        selectedInterviewBank.available_hubs
      );
      Instrumentation.logOrgInterviewBankUpdated(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        selectedInterviewBank.name,
        interviewQuestions,
        selectedInterviewBank.available_hubs,
        EventHubsWheres.ORG_SETTINGS
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
      });
    },
  });

  const { mutateAsync: handleUpdateInterviewBankAvailableHubs } = useMutation({
    mutationFn: async ({ hubs }: { hubs: string[] }) => {
      await updateOrgInterviewBank(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        selectedInterviewBank.name,
        selectedInterviewBank.interview_questions,
        hubs
      );
      Instrumentation.logOrgInterviewBankUpdated(
        userOrgContext.orgId,
        selectedInterviewBank.id,
        selectedInterviewBank.name,
        selectedInterviewBank.interview_questions,
        hubs,
        EventHubsWheres.ORG_SETTINGS
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [INTERVIEW_BANKS_QUERY_KEY, userOrgContext.orgId],
      });
    },
  });

  const renderTab = () => {
    if (isOrgTrialEnded(userOrgContext.defaultOrg)) {
      return (
        <Box sx={{ pt: 4 }}>
          <TrialEnded org={userOrgContext.defaultOrg} />
        </Box>
      );
    }
    switch (selectedTab) {
      case OrgSettingsTabs.CUSTOMIZE_PRACTICE:
      default:
        return (
          <CustomizePracticeTab
            selectedOrg={userOrgContext.defaultOrg}
            selectedOrgInterviewBanks={interviewBanks}
            setModalStatus={setModalStatus}
            setSelectedInterviewBank={setSelectedInterviewBank}
            setShouldBlockNav={setShouldBlockNav}
            setShowBackHeader={setShowBackHeader}
            showBackHeader={showBackHeader}
            showScenarioTemplates={showScenarioTemplates}
            setShowScenarioTemplates={setShowScenarioTemplates}
            templateSelected={templateSelected}
            setTemplateSelected={setTemplateSelected}
          />
        );
      case OrgSettingsTabs.VIDEOS_COURSES:
        return (
          <VideosAndCoursesTab
            setModalStatus={setModalStatus}
            contentView={videosAndCourses}
            selectedOrgId={userOrgContext.orgId}
            setSelectedCourse={setSelectedCourse}
            // This variable seems to have been just mysteriously not set but...compiled somehow??
            isHubAdmin={true}
          />
        );
      case OrgSettingsTabs.COACHBOT:
        if (userOrgContext.defaultOrg?.coachbot_enabled) {
          return (
            <CoachBotLibrary
              setShouldBlockNav={setShouldBlockNav}
              setHideOrgSwitcher={setHideOrgSwitcher}
            />
          );
        }
        break;
    }
  };

  const loading =
    userOrgContext.loading ||
    userOrgContext.defaultOrgLoading ||
    videosAndCoursesQueryResult.isLoading ||
    interviewBanksQueryResult.isLoading;
  if (loading) {
    return <OrgLoading prompts={["Loading organization content..."]} />;
  }

  const renderBackHeader = () => {
    const labelKey = Object.entries(OrgSettingsTabs).find(([_, value]) => value === selectedTab)[0];
    return (
      <Button
        variant="text"
        sx={{
          color: getDynamicColor("light1"),
          fontSize: 14,
        }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => {
          handleBack();
        }}
      >
        Back to {OrgSettingsTabLabel[labelKey].toLowerCase()}
      </Button>
    );
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          background: getDynamicColor("light1"),
          minHeight: "100dvh",
        }}
      >
        {showBackHeader ? (
          <Box
            sx={{
              ...defaultHeaderSx,
              px: { xs: 2, md: 3, lg: 4, xl: 5 },
              flexWrap: "wrap",
              alignItems: "center",
              display: "flex",
            }}
          >
            {renderBackHeader()}
          </Box>
        ) : (
          <>
            <Stack
              direction="row"
              gap={{ xs: 0, md: 1 }}
              sx={{
                ...defaultHeaderSx,
                alignItems: "center",
                justifyContent: "space-between",
                px: { xs: 2, md: 3, lg: 4, xl: 5 },
                flexWrap: "wrap",
              }}
            >
              <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                <Typography
                  color={getDynamicColor("light1")}
                  sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 600 }}
                >
                  Manage content
                </Typography>
                {isOrgTrialEnded(userOrgContext.defaultOrg) && <TrialEndedBanner />}
              </Stack>
              {!hideOrgSwitcher && <DefaultOrgSwitcher showConfirmation={shouldBlockNav} />}
            </Stack>
            {!isOrgTrialEnded(userOrgContext.defaultOrg) && (
              <OrgManageContentTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                shouldBlockNav={shouldBlockNav}
                setShouldBlockNav={setShouldBlockNav}
                coachBotEnabled={userOrgContext.defaultOrg?.coachbot_enabled}
              />
            )}
          </>
        )}
        <Stack sx={{ maxWidth: "xxl", width: "100%", mx: "auto" }}> {renderTab()}</Stack>
      </Stack>
      <OrgManageContentModal
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        allHubs={userOrgContext.defaultOrg?.hubs ?? []}
        contentView={videosAndCourses}
        handleDeleteDemoVideo={handleDeleteDemoVideo}
        handleUpdateDemoVideo={handleUpdateDemoVideo}
        handleCreateDemoVideos={handleCreateDemoVideos}
        uploadingVideos={uploadingVideos}
        uploading={uploading}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        handleCreateCourseVideos={handleCreateCourseVideos}
        handleDeleteCourseVideo={handleDeleteCourseVideo}
        handleUpdateCourseVideo={handleUpdateCourseVideo}
        handleUpdateCourseVideos={handleUpdateCourseVideos}
        handleCreateCourse={handleCreateCourse}
        handleUpdateCourse={handleUpdateCourse}
        handleDeleteCourse={handleDeleteCourse}
        clearUploadingVideos={clearUploadingVideos}
        selectedInterviewBank={selectedInterviewBank}
        handleCreateInterviewBank={handleCreateInterviewBank}
        handleDeleteInterviewBank={handleDeleteInterviewBank}
        handleUpdateInterviewBankName={handleUpdateInterviewBankName}
        handleUpdateInterviewBankQuestions={handleUpdateInterviewBankQuestions}
        handleUpdateInterviewBankAvailableHubs={handleUpdateInterviewBankAvailableHubs}
      />
      <YoodliCtaModal
        ctaBody={{
          title: "Are you sure you want to leave this page?",
          subtitle: "Your changes might not have been saved!",
        }}
        open={navGuardModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setNavGuardModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Discard changes",
              handler: () => {
                handleBack(true);
              },
            },
            secondary: { text: "Keep editing", handler: () => setNavGuardModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
}
