import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
// Components
import {
  ExpandMore as ExpandMoreIcon,
  EditOutlined as EditOutlinedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalItemWithUsage, CustomGoalResponse } from "lib-fullstack/api/scenarioApiTypes";
import { GoalKind } from "lib-fullstack/db";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { getCustomGoalbyId, updateCustomGoal } from "lib-frontend/modules/AxiosInstance";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

type CustomScenarioCardProps = {
  goal: CustomGoalItemWithUsage;
  setSelectedGoal: (goal: CustomGoalResponse) => void;
  setGoalToDelete: (goal: CustomGoalItemWithUsage) => void;
};

export const CustomGoalCard = ({
  goal,
  setSelectedGoal,
  setGoalToDelete,
}: CustomScenarioCardProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { defaultOrg } = React.useContext(UserOrgContext);
  const [descriptionOpen, setDescriptionOpen] = React.useState(false);
  const [updatedName, setUpdatedName] = React.useState<string>(undefined);

  const getGoalType = () => {
    switch (goal.goalKind) {
      case GoalKind.BinaryGoal:
        return "Binary Goal";
      case GoalKind.ScoreGoal:
        return "Rated Goal";
      case GoalKind.CompoundGoal:
        return "Compound Goal";
    }
  };

  const updateNameMutation = useMutation({
    mutationFn: async () => {
      const fullGoal = await getCustomGoalbyId(defaultOrg?.id, goal.id);
      fullGoal.name = updatedName;
      await updateCustomGoal(defaultOrg.id, goal.id, fullGoal);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
      });
      setUpdatedName(undefined);
    },
  });

  return (
    <Stack
      alignItems={{ xs: "flex-start", sm: "center" }}
      justifyContent="space-between"
      direction={{ xs: "column", sm: "row" }}
      gap={1}
      sx={{
        position: "relative",
        fontFamily: "poppins",
        p: 2.5,
        border: `1px solid ${getDynamicColor("dark2")}`,
        boxShadow: Y_SHADOWS.box_shadow_1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Stack
          gap={2}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <Stack
            direction="row"
            sx={{ gap: { xs: 1, md: 4 }, alignItems: "center", justifyContent: "space-between" }}
          >
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              {updatedName !== undefined ? (
                <YoodliTextfield
                  disabled={updateNameMutation.isPending}
                  value={updatedName}
                  onChange={(e) => setUpdatedName(e.target.value)}
                  placeholder="e.g. Hooli sales training"
                  maxChars={100}
                  InputProps={{ sx: { height: "35px", fontWeight: 600 } }}
                  fullWidth
                />
              ) : (
                <Typography
                  onClick={async () => {
                    const fullGoal = await getCustomGoalbyId(defaultOrg?.id, goal.id);
                    setSelectedGoal(fullGoal);
                  }}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.3,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {goal.name}
                </Typography>
              )}
              {updateNameMutation.isPending ? (
                <CircularProgress size={18} />
              ) : (
                <IconButton
                  disabled={updateNameMutation.isPending}
                  sx={{
                    color: getDynamicColor("primary"),
                    width: 20,
                    height: 20,
                  }}
                  onClick={() => {
                    if (updatedName !== undefined) {
                      if (updatedName !== goal.name && updatedName.length > 0) {
                        updateNameMutation.mutate();
                      } else {
                        setUpdatedName(undefined);
                      }
                    } else {
                      setUpdatedName(goal.name);
                    }
                  }}
                >
                  {updatedName !== undefined ? (
                    <CheckIcon sx={{ width: 18, height: 18 }} />
                  ) : (
                    <EditOutlinedIcon sx={{ width: 18, height: 18 }} />
                  )}
                </IconButton>
              )}
            </Stack>
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              buttonSx={{
                mx: 0,
                p: 0,
              }}
              menuItems={[
                {
                  title: "Edit",
                  type: YoodliMenuItemType.Primary,
                  onClick: async () => {
                    const fullGoal = await getCustomGoalbyId(defaultOrg?.id, goal.id);
                    setSelectedGoal(fullGoal);
                  },
                  keepOpen: true,
                },
                {
                  title: "Delete",
                  type: YoodliMenuItemType.Warning,
                  disabled: goal.usage > 0,
                  disabledTooltip: "Goal is in use",
                  onClick: () => {
                    setGoalToDelete(goal);
                  },
                },
              ]}
            />
          </Stack>
          {goal.userDescription?.length > 100 ? (
            <Accordion
              sx={{
                boxShadow: "none",
                fontSize: 12,
                mb: "0px !important",
                color: getDynamicColor("purple3"),
                maxWidth: "100%",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  my: 0,
                },
              }}
              onChange={(e, expanded) => {
                setDescriptionOpen(expanded);
              }}
            >
              <AccordionSummary
                sx={{
                  my: 0,
                  px: 0,
                  width: "fit-content",
                  minHeight: "unset !important",
                  ".MuiAccordionSummary-content, .Mui-expanded": {
                    my: "0px !important",
                    mr: 0.5,
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: getDynamicColor("primary"),
                      height: 20,
                      width: 20,
                    }}
                  />
                }
              >
                {descriptionOpen ? "Hide" : "Show"} description
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: 1.4,
                  pb: "8px !important",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {goal.userDescription}
              </AccordionDetails>
            </Accordion>
          ) : goal.userDescription?.length > 0 ? (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.4,
              }}
            >
              {goal.userDescription}
            </Typography>
          ) : null}
          <Stack
            direction="row"
            columnGap={2}
            rowGap={1}
            alignItems="center"
            sx={{
              flexWrap: "wrap",
              fontSize: "12px",
            }}
          >
            <Stack
              direction="row"
              sx={{
                gap: 1,
                color: getDynamicColor("purple3"),
                textTransform: "uppercase",
              }}
            >
              <Typography>{getGoalType()}</Typography>
              {goal.usage > 0 && (
                <>
                  <Typography>•</Typography>
                  <Typography>
                    Used by {goal.usage} scenario
                    {goal.usage > 1 && "s"}
                  </Typography>
                </>
              )}
            </Stack>
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
              }}
            >
              Created by: {goal.createdByEmail ?? "unknown"} (
              {getHumanReadableDate(goal.createdDate)})
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
