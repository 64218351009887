import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { GoalItem, getCustomGoalKindDescription } from "./GoalSelector";
import { Box, Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { DefaultGoal, GoalKind } from "lib-fullstack/db";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { GoalTalkTime } from "components/ConvoScenarios/convoScenarioUtils";
import { truncateText } from "utils/Utilities";

type GoalListProps = {
  allGoals: (GoalItem | CustomGoalItemWithUsage)[];
  selectedGoals: string[];
  customGoals: CustomGoalItemWithUsage[];
  handleGoalSelectionChange: (goalId: string, action: "add" | "remove") => void;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleEditCustomGoal: (goal: CustomGoalItemWithUsage) => Promise<void>;
  handleUpdateCustomGoalType: (goalKind: GoalKind) => void;
  showSearchBar?: boolean;

  // talking points and target time goals
  specialGoalIdSelected?: string;
  setSpecialGoalIdSelected?: (goalId: string) => void;
  talkingPoints: string[];
  goalTalkTime?: GoalTalkTime;
  handleUpdateGoalTalkTime?: (goalTalkTime: GoalTalkTime) => void;
  talkTimeMinutes?: number;
  talkTimeSeconds?: number;
  goalsExtraDetails: Record<string, JSX.Element>;
  goalsExtraTitleInfo: Record<string, JSX.Element>;
};

export const GoalList = ({
  allGoals,
  selectedGoals,
  customGoals,
  talkingPoints,
  handleGoalSelectionChange,
  setCurrStepIndex,
  handleEditCustomGoal,
  handleUpdateCustomGoalType,
  showSearchBar,
  handleUpdateGoalTalkTime,
  talkTimeMinutes,
  talkTimeSeconds,
  goalsExtraDetails,
  goalsExtraTitleInfo,
}: GoalListProps): JSX.Element => {
  const [hoveringIconGoalId, setHoveringIconGoalId] = React.useState<DefaultGoal | string | null>(
    null
  );
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [specialGoalIdSelected, setSpecialGoalIdSelected] = React.useState<string>(null);

  const getIconBackgroundColor = (goalId: DefaultGoal | string, isSelected: boolean) => {
    if (hoveringIconGoalId === goalId && isSelected && !blockIconHover[goalId]) {
      return getDynamicColor("dark4");
    }
    if (specialGoalIdSelected === goalId) {
      return getDynamicColor("yellowWarning");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    return getDynamicColor("primary");
  };

  const renderAddRemoveIcons = (goalId: DefaultGoal | string, isSelected: boolean) => {
    if (hoveringIconGoalId === goalId && isSelected && !blockIconHover[goalId]) {
      return <RemoveIcon />;
    }
    if (specialGoalIdSelected === goalId) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };

  const renderCustomGoalEdit = (goal: CustomGoalItemWithUsage): JSX.Element => (
    <IconButton
      sx={{
        color: getDynamicColor("primary"),
      }}
      onClick={(event) => {
        event.stopPropagation();
        handleEditCustomGoal(goal)
          .then(() => {
            handleUpdateCustomGoalType(goal.goalKind as GoalKind);
            setCurrStepIndex((prev) => prev + 1);
            handleGoalSelectionChange(goal.id, "add");
          })
          .catch((e) => console.error("Error editing custom goal: ", e));
      }}
    >
      <EditIcon sx={{ width: 24, height: 24 }} />
    </IconButton>
  );

  const renderSpecialGoalControls = (goalId: string): JSX.Element => {
    const specialGoals = [DefaultGoal.HitTalkingPoints, DefaultGoal.HitTimeTarget];
    if (!specialGoals.includes(goalId as DefaultGoal)) {
      return null;
    }
    if (specialGoalIdSelected === goalId) {
      return (
        <Button
          variant="text_small"
          disabled={goalId === DefaultGoal.HitTalkingPoints && talkingPoints?.length === 0}
          onClick={() => {
            setSpecialGoalIdSelected(undefined);
            if (goalId === DefaultGoal.HitTimeTarget) {
              handleUpdateGoalTalkTime({
                minutes: talkTimeMinutes,
                seconds: talkTimeSeconds,
              });
            }
            handleGoalSelectionChange(goalId, "add");
          }}
        >
          Save
        </Button>
      );
    }
    if (specialGoals.includes(goalId as DefaultGoal)) {
      return (
        <IconButton
          sx={{
            color: getDynamicColor("primary"),
          }}
          onClick={() => {
            setSpecialGoalIdSelected(goalId);
          }}
        >
          <EditIcon sx={{ width: 24, height: 24 }} />
        </IconButton>
      );
    }
  };

  const searchFilteredGoals = React.useMemo(() => {
    return allGoals.filter((goal) => {
      return goal.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [allGoals, searchText]);

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        pt: 1,
        gap: 2,
        width: "800px",
        maxWidth: "100%",
      }}
    >
      {showSearchBar && (
        <YoodliSearchBar
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearSearch={() => setSearchText("")}
          placeholder="Search and select from your library"
          InputProps={{
            sx: {
              height: "40px",
            },
          }}
          sx={{
            width: "100%",
            maxWidth: "unset",
            px: 1,
          }}
        />
      )}
      <Stack
        sx={{
          gap: 2,
          overflow: "auto",
          maxHeight: "500px",
          transform: "max-height 0.3s ease-out",
          pb: 2,
        }}
      >
        {searchFilteredGoals.map((goal) => {
          const customGoalItem = customGoals?.find((customGoal) => goal.id === customGoal.id);
          const isSelected = selectedGoals?.includes(goal.id);
          const specialGoalDescriptions = {
            [DefaultGoal.HitTalkingPoints]:
              talkingPoints.length > 0 ? `Rated goal: Scored 1-${talkingPoints.length}` : undefined,
            [DefaultGoal.HitTimeTarget]: "Binary goal: Achieved/Missed",
          };
          const goalKindDescription = customGoalItem
            ? getCustomGoalKindDescription(customGoalItem)
            : specialGoalDescriptions[goal.id as DefaultGoal];
          return (
            <Stack key={goal.id} direction="column" sx={{ width: "100%", px: 2 }}>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  gap: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  px: 2,
                }}
                onClick={() => {
                  if (
                    goal.id === DefaultGoal.HitTalkingPoints ||
                    goal.id === DefaultGoal.HitTimeTarget
                  ) {
                    if (isSelected) {
                      handleGoalSelectionChange(goal.id, "remove");
                      return;
                    }
                    if (specialGoalIdSelected === goal.id) {
                      setSpecialGoalIdSelected(null);
                    } else {
                      setSpecialGoalIdSelected(goal.id);
                    }
                    return;
                  }
                  if (isSelected) {
                    handleGoalSelectionChange(goal.id, "remove");
                  } else {
                    handleGoalSelectionChange(goal.id, "add");
                  }
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    gap: 2,
                    alignItems: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      height: 28,
                      width: 28,
                      cursor: "pointer",
                      alignSelf: "flex-start",
                    }}
                    onMouseEnter={() => {
                      setHoveringIconGoalId(goal.id);
                    }}
                    onMouseLeave={() => {
                      setHoveringIconGoalId(null);
                      setBlockIconHover({ ...blockIconHover, [goal.id]: false });
                    }}
                    onClick={() => {
                      if (isSelected) {
                        setBlockIconHover({ ...blockIconHover, [goal.id]: false });
                      } else {
                        setBlockIconHover({ ...blockIconHover, [goal.id]: true });
                      }
                    }}
                  >
                    <Box
                      sx={{
                        height: 20,
                        width: 20,
                        left: 4,
                        top: 4,
                        borderRadius: "50%",
                        position: "relative",
                        backgroundColor: getIconBackgroundColor(goal.id, isSelected),
                        color: getDynamicColor("light1"),
                        transition: "background-color 0.2s ease-out",
                        svg: {
                          position: "absolute",
                          display: "block",
                          top: 1,
                          left: 1,
                          height: 18,
                          width: 18,
                          strokeWidth: 0.5,
                          stroke: getDynamicColor("light1"),
                        },
                      }}
                    >
                      {renderAddRemoveIcons(goal.id, isSelected)}
                    </Box>
                  </Box>
                  <Stack direction="column" sx={{ gap: 0.5, overflow: "hidden" }}>
                    <Stack direction="row" sx={{ gap: 1 }}>
                      <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{goal.name}</Typography>
                      {!!goalsExtraTitleInfo[goal.id] && goalsExtraTitleInfo[goal.id]}
                    </Stack>
                    {customGoalItem?.userDescription && (
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {truncateText(customGoalItem.userDescription, 100)}
                      </Typography>
                    )}
                    {goalKindDescription && (
                      <Typography
                        sx={{ fontSize: 10, fontWeight: 400, textTransform: "uppercase" }}
                      >
                        {goalKindDescription}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {customGoalItem && renderCustomGoalEdit(goal as CustomGoalItemWithUsage)}
                {renderSpecialGoalControls(goal.id)}
              </Stack>
              {!!goalsExtraDetails[goal.id] && (
                <Collapse
                  in={specialGoalIdSelected === goal.id}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      pt: 0.5,
                      px: 2,
                    }}
                  >
                    {goalsExtraDetails[goal.id]}
                  </Box>
                </Collapse>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
