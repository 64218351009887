import firebase from "firebase/app";

// Components
import { Button, Stack } from "@mui/material";

// Assets
import { ReactComponent as GoogleIcon } from "../../images/icons/GoogleIcon.svg";
import { ReactComponent as LinkIcon } from "../../images/icons/LinkIcon.svg";
import { ReactComponent as MicrosoftIcon } from "../../images/icons/MicrosoftIcon.svg";

// Utils
import { OAuthButton } from "./OAuthButton";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { CUSTOM_SSO_QUERY_PARAM } from "lib-fullstack/utils/auth";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { AuthProvider } from "lib-fullstack/utils/enums";

const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
const MicrosoftAuthProvider = new firebase.auth.OAuthProvider("microsoft.com");

type OAuthButtonGroupProps = {
  isSignUp?: boolean;
  showOnlyOrgSso?: boolean;
  customSsoName?: string;
  ssoDisplayName?: string;
  buttonHandler: (
    e: React.MouseEvent<HTMLButtonElement>,
    provider: firebase.auth.AuthProvider
  ) => Promise<void>;
  error: boolean;
  handleRedirect: () => void;
  signInOptions: AuthProvider[];
};
export default function OAuthButtonGroup({
  isSignUp,
  showOnlyOrgSso,
  buttonHandler,
  customSsoName,
  ssoDisplayName,
  error,
  handleRedirect,
  signInOptions,
}: OAuthButtonGroupProps): JSX.Element {
  // get samlid from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const customSsoId = urlParams.get(CUSTOM_SSO_QUERY_PARAM) || customSsoName;

  const handleCustomSsoSignin = async (e) => {
    Instrumentation.logAmplitudeEvent(
      isSignUp
        ? AuthAnalyticsEvents.SIGNUP_CUSTOM_SSO_OPTION_CLICKED
        : AuthAnalyticsEvents.SIGNIN_CUSTOM_SSO_OPTION_CLICKED
    );

    if (customSsoId.startsWith("saml")) {
      await buttonHandler(e, new firebase.auth.SAMLAuthProvider(customSsoId));
    } else if (customSsoId.startsWith("oidc")) {
      await buttonHandler(e, new firebase.auth.OAuthProvider(customSsoId));
    }
  };

  const renderOrgSsoButton = () => {
    const signedInNoMembership = error && firebase.auth().currentUser;
    return (
      <Button
        variant="contained"
        onClick={signedInNoMembership ? handleRedirect : handleCustomSsoSignin}
        sx={{
          width: "100%",
          fontWeight: 600,
          fontSize: 14,
          color: getDynamicColor("light1"),
          height: 48,
        }}
      >
        {signedInNoMembership
          ? "Continue"
          : `${isSignUp ? "Continue" : "Sign in"} with ${ssoDisplayName ?? "SSO"}`}
      </Button>
    );
  };

  const renderNormalOauthButtonOptions = () => (
    <>
      {signInOptions.includes(AuthProvider.GOOGLE) && (
        <OAuthButton
          title="Google"
          logo={<GoogleIcon width="28px" />}
          onClick={async (e) => {
            Instrumentation.logAmplitudeEvent(
              isSignUp
                ? AuthAnalyticsEvents.SIGNUP_GOOGLE_OPTION_CLICKED
                : AuthAnalyticsEvents.SIGNIN_GOOGLE_OPTION_CLICKED
            );
            await buttonHandler(e, GoogleAuthProvider);
          }}
          isSignUp={isSignUp}
        />
      )}
      {signInOptions.includes(AuthProvider.MICROSOFT) && (
        <OAuthButton
          title="Microsoft"
          logo={<MicrosoftIcon width="28px" />}
          onClick={async (e) => {
            Instrumentation.logAmplitudeEvent(
              isSignUp
                ? AuthAnalyticsEvents.SIGNUP_OUTLOOK_OPTION_CLICKED
                : AuthAnalyticsEvents.SIGNIN_OUTLOOK_OPTION_CLICKED
            );
            await buttonHandler(e, MicrosoftAuthProvider);
          }}
          isSignUp={isSignUp}
        />
      )}
      {customSsoId && (
        <OAuthButton
          title="SSO"
          logo={<LinkIcon width="28px" />}
          onClick={handleCustomSsoSignin}
          isSignUp={isSignUp}
        />
      )}
    </>
  );

  return (
    // If the show only SSO option flag is checked, do not display any other sign up option
    <Stack direction="column" width="100%" gap={2}>
      {!showOnlyOrgSso ? renderNormalOauthButtonOptions() : renderOrgSsoButton()}
    </Stack>
  );
}
