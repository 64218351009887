import React from "react";
import { isMobile } from "react-device-detect";

export function useIsMobile(): boolean {
  //copied over from the useIsChrome. we want to avoid hydration errors when server doesn't match the client
  const [stateIsMobile, stateSetIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      stateSetIsMobile(isMobile);
    }
  }, []);
  return stateIsMobile;
}
