// Components
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { TemplateHeading } from "components/ConvoScenarios/convoScenarioUtils";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

type ScenarioSubtypeFilterProps = {
  subtypeFilter: TemplateHeading;
  setSubtypeFilter: (value: TemplateHeading) => void;
};

export const ScenarioSubtypeFilter = ({
  subtypeFilter,
  setSubtypeFilter,
}: ScenarioSubtypeFilterProps): JSX.Element => {
  return (
    <YoodliMenu
      type={YoodliMenuButtonType.Text}
      menuItems={[
        {
          title: "All",
          onClick: () => setSubtypeFilter(undefined),
          type: !subtypeFilter ? YoodliMenuItemType.Primary : YoodliMenuItemType.Default,
        },
        ...Object.values(TemplateHeading).map((val) => ({
          title: val,
          onClick: () => setSubtypeFilter(val as TemplateHeading),
          type: subtypeFilter === val ? YoodliMenuItemType.Primary : YoodliMenuItemType.Default,
        })),
      ]}
      buttonText="Filter by"
      buttonProps={{ startIcon: <FilterAltOutlinedIcon /> }}
      buttonSx={{ whiteSpace: "nowrap", fontSize: "14px" }}
    />
  );
};
