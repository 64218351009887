import React from "react";

// Components
import { Box, Button, Stack } from "@mui/material";

// Utils
import { CustomizePracticeTabEnum, CustomizePracticeTabLabels } from "./CustomizePracticeTab";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

type CustomizePracticeTabSelectorProps = {
  tabs: CustomizePracticeTabEnum[];
  activeTab: CustomizePracticeTabEnum;
  setActiveTab: (tab: CustomizePracticeTabEnum) => void;
};

export const CustomizePracticeTabSelector = ({
  tabs,
  activeTab,
  setActiveTab,
}: CustomizePracticeTabSelectorProps): JSX.Element => {
  const tabStyles = {
    [3]: {
      containerWidth: "min(570px, calc(100% - 16px))",
      shadowWidth: "min(33.333%, 196px)",
    },
    [4]: {
      containerWidth: "min(640px, calc(100% - 16px))",
      shadowWidth: "min(25%, 180px)",
    },
  };
  return (
    <Stack
      direction="row"
      sx={{
        boxShadow: Y_SHADOWS.dark_elevation,
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
        width: tabStyles[tabs.length].containerWidth,
        mx: { xs: 1, sm: "auto", md: 0 },
        mt: { xs: 2, md: 0 },
      }}
    >
      <Box
        sx={{
          width: tabStyles[tabs.length].shadowWidth,
          height: "100%",
          boxShadow: Y_SHADOWS.box_shadow_1,
          backgroundColor: getDynamicColor("light2"),
          borderRadius: "4px",
          position: "absolute",
          left: tabs.findIndex((tab) => tab === activeTab) * (100 / tabs.length) + "%",
          transition: "left 0.5s cubic-bezier(.47,1.64,.41,.8)",
        }}
      />
      {tabs.map((tab) => {
        return (
          <Button
            key={tab}
            onClick={() => setActiveTab(tab)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              backgroundColor: "transparent",
              borderRadius: "4px",
              width: 196,
              minHeight: 48,
              py: { xs: 1, sm: 2 },
              fontWeight: activeTab === tab ? 700 : 500,
              fontSize: 14,
              lineHeight: 1.2,
              color: getDynamicColor(activeTab === tab ? "primary" : "purple3"),
              transition: "color 0.3s, font-weight 0s",
              "&:hover": {
                color: getDynamicColor("primary"),
                backgroundColor: "transparent",
              },
            }}
          >
            {CustomizePracticeTabLabels[tab]}
          </Button>
        );
      })}
    </Stack>
  );
};
