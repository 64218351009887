// Components
import SortIcon from "@mui/icons-material/Sort";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";

export enum ScenarioSortOption {
  Name = "Name",
  CreatedAt = "CreatedAt",
  UpdatedAt = "UpdatedAt",
}

type ScenarioSortMenuProps = {
  sortBy: ScenarioSortOption;
  setSortBy: (option: ScenarioSortOption) => void;
};

export const ScenarioSortMenu = ({ sortBy, setSortBy }: ScenarioSortMenuProps): JSX.Element => {
  return (
    <YoodliMenu
      type={YoodliMenuButtonType.Text}
      menuItems={[
        {
          title: "Name (A-Z)",
          onClick: () => setSortBy(ScenarioSortOption.Name),
          type:
            sortBy === ScenarioSortOption.Name
              ? YoodliMenuItemType.Primary
              : YoodliMenuItemType.Default,
        },
        {
          title: "Last created",
          onClick: () => setSortBy(ScenarioSortOption.CreatedAt),
          type:
            sortBy === ScenarioSortOption.CreatedAt
              ? YoodliMenuItemType.Primary
              : YoodliMenuItemType.Default,
        },
        {
          title: "Last Edited",
          onClick: () => setSortBy(ScenarioSortOption.UpdatedAt),
          type:
            sortBy === ScenarioSortOption.UpdatedAt
              ? YoodliMenuItemType.Primary
              : YoodliMenuItemType.Default,
        },
      ]}
      buttonText="Sort by"
      buttonProps={{ startIcon: <SortIcon /> }}
      buttonSx={{ whiteSpace: "nowrap", fontSize: "14px" }}
    />
  );
};
