import React from "react";

// Components
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DEFAULT_PERSONA, getDemeanorColor } from "components/ConvoScenarios/convoScenarioUtils";
import { CreatePersonaForm } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreatePersonaForm";
import {
  YoodliSelect,
  YoodliSelectOption,
  SELECT_VALUE_STYLES,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { WizardType } from "./CreateConvoScenarioWizard";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  CreatePersonaRequest,
  PersonaMemberViewResponse,
  UpdatePersonaRequest,
} from "lib-fullstack/api/scenarioApiTypes";

type PersonaSelectorProps = {
  showCreatePersona: boolean;
  setShowCreatePersona: (value: boolean) => void;
  personas: PersonaMemberViewResponse[];
  loadingPersonas: boolean;
  defaultPersonaId: string;
  handleSetDefaultPersona: (value: string) => void;
  currStepIndex: number;
  setPersonaToCreate: React.Dispatch<React.SetStateAction<CreatePersonaRequest>>;
  wizardType: WizardType;
};

export const PersonaSelector = ({
  showCreatePersona,
  setShowCreatePersona,
  personas,
  loadingPersonas,
  defaultPersonaId,
  handleSetDefaultPersona,
  currStepIndex,
  setPersonaToCreate,
  wizardType,
}: PersonaSelectorProps): JSX.Element => {
  const [newPersona, setNewPersona] = React.useState<UpdatePersonaRequest>(DEFAULT_PERSONA);
  React.useEffect(() => {
    setNewPersona(DEFAULT_PERSONA);
  }, [currStepIndex]);

  React.useEffect(() => {
    // if newPersona is valid, set personaToCreate in parent, so it can validate the step
    if (newPersona.name && newPersona.job_title && newPersona.voice_id && newPersona.demeanor) {
      // cast as CreatePersonaRequest since it sometimes has an id, which fails typing
      setPersonaToCreate(newPersona as CreatePersonaRequest);
    }
  }, [newPersona]);

  React.useEffect(() => {
    if (!showCreatePersona) {
      setNewPersona(DEFAULT_PERSONA);
    }
  }, [showCreatePersona]);

  const options: YoodliSelectOption[] = personas?.length
    ? [
        {
          label: "Select a persona",
          value: "placeholder",
          optionSx: {
            display: "none",
          },
        },
        ...personas.map((persona) => ({
          label: `${persona.name}, ${persona.job_title}`,
          subLabel: persona.demeanor,
          value: persona.id,
          showDivider: true,
        })),
      ]
    : [{ label: "No personas found", value: "n/a" }];
  const selectedPersona = personas.find((persona) => persona.id === defaultPersonaId);
  const selectedOption = options.find((option) => option.value === defaultPersonaId) ?? options[0];
  const handleCreatePersonaClicked = () => {
    setShowCreatePersona(true);
  };
  const handleUpdateNewPersona = (key: keyof CreatePersonaRequest, value: string | string[]) => {
    setNewPersona((prev) => ({ ...prev, [key]: value }));
  };

  const demeanorChipColor = React.useMemo(() => {
    return getDemeanorColor(selectedPersona?.demeanor);
  }, [selectedPersona]);

  const renderPersonaValue = (option: YoodliSelectOption, persona?: PersonaMemberViewResponse) => {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {persona && (
          <Avatar
            alt={persona?.name}
            src={persona?.profile_picture_signed_url}
            key={persona?.profile_picture_signed_url}
            sx={{
              height: 36,
              width: 36,
            }}
          />
        )}
        <Stack
          direction="row"
          columnGap={1}
          rowGap={0.5}
          alignItems="center"
          flexWrap="wrap"
          sx={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              ...SELECT_VALUE_STYLES,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            {option?.label}
          </Typography>
          {option?.subLabel && (
            <Chip
              className="subLabel"
              sx={{
                fontSize: 10,
                fontWeight: 400,
                color: getDynamicColor("purple3"),
                backgroundColor: demeanorChipColor,
                textTransform: "uppercase",
                letterSpacing: "1px",
                p: 0,
                height: "unset",
                span: {
                  lineHeight: 1,
                  py: 1,
                },
              }}
              label={option.subLabel}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const renderPersonaSelector = () => {
    return (
      <YoodliSelect
        value={selectedOption.value}
        options={options}
        onChange={(e) => {
          handleSetDefaultPersona(e.target.value as string);
        }}
        sx={{
          minHeight: 60,
        }}
        MenuProps={{
          sx: {
            maxHeight: { xs: 400, lg: 500 },
          },
          MenuListProps: {
            autoFocusItem: false,
          },
        }}
        disabled={loadingPersonas}
        renderValue={() => {
          if (loadingPersonas) {
            return (
              <Stack direction="row" gap={2} alignItems="center">
                <CircularProgress size={16} />
              </Stack>
            );
          } else {
            return renderPersonaValue(selectedOption, selectedPersona);
          }
        }}
      >
        <MenuItem
          value="create-new"
          sx={{
            fontSize: "14px !important",
            fontWeight: "700 !important",
            py: 1,
            color: `${getDynamicColor("primary")} !important`,
            height: 52,
          }}
          onClick={handleCreatePersonaClicked}
        >
          Create my own
        </MenuItem>
        <Divider />
        {options.map((option, optionIdx) => {
          const persona = personas.find((p) => p.id === option.value);
          return (
            <MenuItem value={option.value} key={option.value} sx={{ ...option?.optionSx }}>
              <Box
                sx={{
                  fontWeight: 500,
                  color: getDynamicColor("purple3"),
                  fontSize: "14px !important",
                  px: 1.5,
                  py: 1,
                }}
              >
                {renderPersonaValue(option, persona)}
              </Box>
              {option.showDivider && options[optionIdx + 1] && <Divider />}
            </MenuItem>
          );
        })}
      </YoodliSelect>
    );
  };

  return (
    <Stack justifyContent="flex-start" gap={3}>
      {showCreatePersona ? (
        <CreatePersonaForm
          newPersona={newPersona}
          handleUpdateNewPersona={handleUpdateNewPersona}
          currStepIndex={currStepIndex}
          wizardType={wizardType}
        />
      ) : (
        renderPersonaSelector()
      )}
    </Stack>
  );
};
