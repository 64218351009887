import React from "react";

// Components
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";
import { Button, Container, Fade } from "@mui/material";

// Utils
import { CoachBotSectionStatus } from "./CoachBotLibrary";
import { CoachBotContent } from "./CoachBotContent";
import { CoachBotSettings } from "./CoachBotSettings";
import { actCoachBotContent, patchCoachBotContent } from "lib-frontend/modules/AxiosInstance";
import {
  GetAllCoachBotContentResponse,
  GetCoachBotResponse,
  GetScenarioResponse,
} from "lib-fullstack/api/hubApiTypes";
import { AnalyticProcessingState, CoachBotContentAction } from "lib-fullstack/utils/enums";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { CoachBotUploadedFile } from "./SelfServeCoachBotWizard/CoachBotTypes";
import {
  CoachBotUploadContent,
  MAX_FILES,
} from "./SelfServeCoachBotWizard/CoachBotUploadContent/CoachBotUploadContent";
import { CoachBotCard } from "./CoachBotCard";

type ManageCoachBotTabProps = {
  setCoachBot: React.Dispatch<React.SetStateAction<GetCoachBotResponse>>;
  coachBot: GetCoachBotResponse;
  orgId: string;
  handleDeleteCoachBotContent: (contentId: string) => Promise<void>;
  handleUpdateCoachBotContentFilename: (contentId: string, newFileName: string) => Promise<void>;
  newlyUploadedCoachBotContent: CoachBotUploadedFile[];
  setNewlyUploadedCoachBotContent: React.Dispatch<React.SetStateAction<CoachBotUploadedFile[]>>;
  newlyUploadedCoachBotContentRef: React.MutableRefObject<CoachBotUploadedFile[]>;
  coachBotContent: GetAllCoachBotContentResponse;
  setCoachBotContent: React.Dispatch<React.SetStateAction<GetAllCoachBotContentResponse>>;
  scenarioInfo: GetScenarioResponse[];
  setDeleteCoachBotOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSectionStatus: React.Dispatch<React.SetStateAction<CoachBotSectionStatus>>;
};
export const ManageCoachBotTab = ({
  setCoachBot,
  coachBot,
  orgId,
  handleDeleteCoachBotContent,
  handleUpdateCoachBotContentFilename,
  newlyUploadedCoachBotContent,
  setNewlyUploadedCoachBotContent,
  newlyUploadedCoachBotContentRef,
  coachBotContent,
  setCoachBotContent,
  scenarioInfo,
  setDeleteCoachBotOpen,
  setSectionStatus,
}: ManageCoachBotTabProps): JSX.Element => {
  const [showManageContent, setShowManageContent] = React.useState<boolean>(false);
  const [_loadingContentUpload, setLoadingContentUpload] = React.useState<boolean>(false);
  // TODO @dwiegand: this would be nice to be a flag on thew coachbot itself, instead of having to check all the contents
  const isCoachBotProcessing = React.useMemo(
    () =>
      (coachBotContent?.botContent ?? []).some(
        (content) => content.status === AnalyticProcessingState.PROCESSING
      ),
    [coachBotContent]
  );

  const handleSetCoachBotContentIsActive = async (contentId: string, isActive: boolean) => {
    try {
      await patchCoachBotContent(orgId, coachBot?.botId, contentId, { isActive });
      // optimistically update the local state on success to avoid having to refetch everything
      setCoachBotContent((prev) => {
        const updatedContent = !prev
          ? []
          : prev.botContent.map((content) => {
              if (content.id === contentId) {
                return { ...content, isActive };
              }
              return content;
            });
        return { ...prev, botContent: updatedContent };
      });
    } catch (er) {
      console.log(`Error updating coachbot content for org ${orgId}: ${er}`);
    }
  };

  const handleGenerateScenariosFromCoachBotContent = async (contentId: string) => {
    if (!coachBot?.botId) {
      console.error("No coach bot id found");
      return;
    }
    try {
      await actCoachBotContent(
        orgId,
        coachBot.botId,
        contentId,
        CoachBotContentAction.GenerateScenarios
      );
    } catch (er) {
      console.error(
        `Error CoachBotContentAction.GenerateScenarios: ${orgId}/${coachBot.botId}/${contentId}: ${er}`
      );
    }
  };

  const handleGenerateGoalsFromCoachBotContent = async (contentId: string) => {
    if (!coachBot?.botId) {
      console.error("No coach bot id found");
      return;
    }
    try {
      await actCoachBotContent(
        orgId,
        coachBot.botId,
        contentId,
        CoachBotContentAction.GenerateGoals
      );
    } catch (er) {
      console.error(
        `Error CoachBotContentAction.GenerateGoals: ${orgId}/${coachBot.botId}/${contentId}: ${er}`
      );
    }
  };

  return (
    <Fade in>
      <Container
        maxWidth="xxxl"
        sx={{
          width: "100%",
          ml: "unset",
          p: { xs: 3, md: 4, lg: 6 },
          pt: { xs: 8, md: 8, lg: 8 },
          display: "flex",
          flexDirection: "column",
          gap: showManageContent ? 4 : 6,
          position: "relative",
        }}
      >
        {showManageContent ? (
          <>
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setShowManageContent(false)}
              sx={{
                position: "absolute",
                top: 16,
                left: 16,
                fontSize: 14,
                zIndex: 2,
              }}
            >
              Content
            </Button>
            <CoachBotUploadContent
              coachBotName={coachBot?.name}
              setLoading={setLoadingContentUpload}
              orgId={orgId}
              botId={coachBot?.botId}
              uploadedFiles={newlyUploadedCoachBotContent}
              setUploadedFiles={setNewlyUploadedCoachBotContent}
              getCurrentUploadedFiles={() => newlyUploadedCoachBotContentRef.current}
              coachBotContent={coachBotContent?.botContent ?? []}
              overrideSx={{
                py: 0,
              }}
              maxFilesReached={coachBotContent?.botContent?.length >= MAX_FILES}
              filesLeftToUpload={
                MAX_FILES -
                (coachBotContent?.botContent?.length + newlyUploadedCoachBotContent?.length)
              }
              hideTitle
              hideUploadedFiles
              handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilename}
            />
          </>
        ) : (
          <>
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setSectionStatus(CoachBotSectionStatus.Library)}
              sx={{
                position: "absolute",
                top: 16,
                left: 16,
                fontSize: 14,
                zIndex: 2,
              }}
            >
              Library
            </Button>
            <CoachBotCard
              botName={coachBot?.name}
              isDefault={coachBot?.isDefault}
              dateBuilt={getHumanReadableDate(coachBot?.createdAt, {
                month: "short",
              })}
              isProcessing={isCoachBotProcessing}
              scenarios={scenarioInfo}
              handleDelete={() => setDeleteCoachBotOpen(true)}
            />
            <CoachBotSettings
              coachBot={coachBot}
              setCoachBot={setCoachBot}
              orgId={orgId}
              currentSelectedScenarios={scenarioInfo}
            />
          </>
        )}
        <CoachBotContent
          coachBotContent={coachBotContent}
          newlyUploadedCoachBotContent={newlyUploadedCoachBotContent}
          showManageContent={showManageContent}
          setShowManageContent={setShowManageContent}
          handleSetCoachBotContentIsActive={handleSetCoachBotContentIsActive}
          handleDeleteCoachBotContent={handleDeleteCoachBotContent}
          handleUpdateCoachBotContentFilename={handleUpdateCoachBotContentFilename}
          handleGenerateScenariosFromCoachBotContent={handleGenerateScenariosFromCoachBotContent}
          handleGenerateGoalsFromCoachBotContent={handleGenerateGoalsFromCoachBotContent}
        />
      </Container>
    </Fade>
  );
};
