import React from "react";

// Components
import { ForumRounded as ForumRoundedIcon } from "@mui/icons-material";
import { Stack, Typography, Grid, Card, Button } from "@mui/material";
import {
  TEMPLATE_SUB_TYPE_DATA,
  templateHeadingMap,
} from "components/ConvoScenarios/convoScenarioUtils";
import { TemplateHeading } from "components/ConvoScenarios/convoScenarioUtils";

// Utils
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { ScenarioTypeIdEnum } from "lib-fullstack/db";

const templateHeadingOrder = [
  TemplateHeading.SALES,
  TemplateHeading.MANAGER_TRAINING,
  TemplateHeading.OTHER,
];

type OrgScenarioTemplateGridProps = {
  scenarios: GetScenarioResponse[];
  handleSelectTemplate: (scenario: GetScenarioResponse) => void;
};

export const OrgScenarioTemplateGrid = ({
  scenarios,
  handleSelectTemplate,
}: OrgScenarioTemplateGridProps): JSX.Element => {
  const templatesByType = (scenarios ?? []).reduce((result, element) => {
    const heading = templateHeadingMap[element.scenarioTypeId] ?? TemplateHeading.OTHER;
    if (!result[heading]) {
      result[heading] = [];
    }
    result[heading].push(element);
    return result;
  }, {});

  return (
    <>
      {templateHeadingOrder.map((heading, i) => {
        let templates = templatesByType[heading];

        if (!getEnabledFlag("patient-counseling") && heading === TemplateHeading.OTHER) {
          templates = (templates ?? []).filter(
            (template: GetScenarioResponse) =>
              template.scenarioTypeId !== ScenarioTypeIdEnum.PATIENT_COUNSELING
          );
        }
        if (!templates || templates.length === 0) {
          return null;
        }
        return (
          <Stack gap={1} key={templates[0]?.templateSubType + i.toString()}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {heading}
            </Typography>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {templates.map((scenario: GetScenarioResponse) => {
                return (
                  <Grid item xs={12} sm={6} lg={4} key={scenario.path}>
                    <Card
                      sx={{
                        p: 2.5,
                        boxShadow: Y_SHADOWS.box_shadow_1,
                        borderRadius: "8px",
                        border: `1px solid ${getDynamicColor("dark2")}`,
                        minHeight: 170,
                        height: "100%",
                        display: "flex",
                        gap: 1.5,
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        {TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.icon ?? (
                          <ForumRoundedIcon />
                        )}
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: { xs: 14, md: 16 },
                          }}
                        >
                          {scenario.title}
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                          color: getDynamicColor("purple3"),
                          minHeight: 42,
                        }}
                      >
                        {scenario.description}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => handleSelectTemplate(scenario)}
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: getDynamicColor("primary"),
                          borderColor: getDynamicColor("primary"),
                          borderWidth: "1px !important",
                          mt: "auto",
                        }}
                      >
                        Choose Template
                      </Button>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        );
      })}
    </>
  );
};
