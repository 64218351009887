// Utils
/**
 * Environment config
 * Note: This file is improperly named.
 * This is actually included in server code, too.
 */
import { AppEnv } from "./utils/enums";

export const REACT_APP_ENVIRONMENTS = ["local", "development", "staging", "production"] as const;
export type ReactAppEnvironment = (typeof REACT_APP_ENVIRONMENTS)[number];

export const dbUrl = "/api" as const;

export const LOCAL_LANDING_PAGE_PORT = 3000 as const;
export const LOCAL_WEB_SERVER_PORT = 3001 as const;
export const LOCAL_WEBPACK_SERVER_PORT = 3002 as const;
export const LOCAL_PYTHON_SERVER_PORT = 8081 as const;
export const LOCAL_WEBSOCKET_PORT = 8000 as const;

export const productionDomain = "yoodli.ai" as const;
export const stagingDomain = "staging.yoodli.ai" as const;
export const devDomain = "dev.yoodli.ai" as const;
export const localDomain = "localhost" as const;

const productionLogRocketServerUrl = `https://api3.${productionDomain}/i` as const;
const stagingLogRocketServerUrl = `https://api3.${stagingDomain}/i` as const;
const devLogRocketServerUrl = `https://api3.${devDomain}/i` as const;
const localLogRocketServerUrl = devLogRocketServerUrl;

export const productionWebServerUrl = `https://app.${productionDomain}` as const;
export const stagingWebServerUrl = `https://app.${stagingDomain}` as const;
export const devWebServerUrl = `https://app.${devDomain}` as const;
export const localWebServerUrl = `http://${localDomain}:${LOCAL_WEB_SERVER_PORT}` as const;

export const productionLandingPageUrl = `https://${productionDomain}` as const;
export const stagingLandingPageUrl = `https://${stagingDomain}` as const;
export const devLandingPageUrl = `https://${devDomain}` as const;
export const localLandingPageUrl = `http://${localDomain}:${LOCAL_LANDING_PAGE_PORT}` as const;

// TODO: Change these Python server URLs to the desired Python server URLs with "api" subdomain.
export const productionPythonServerUrl = `https://app.${productionDomain}` as const;
export const stagingPythonServerUrl = `https://app.${stagingDomain}` as const;
export const devPythonServerUrl = `https://app.${devDomain}` as const;
export const localPythonServerUrl = `http://${localDomain}:${LOCAL_PYTHON_SERVER_PORT}` as const;

const websocketProdUrl = `https://audio-websocket.${productionDomain}` as const;
const websocketStagingUrl = `https://audio-websocket.${stagingDomain}` as const;
const websocketDevUrl = `https://audio-websocket.${devDomain}` as const;
const websocketLocalUrl = `http://127.0.0.1:${LOCAL_WEBSOCKET_PORT}` as const;

const productionKfaDataExportUrl = undefined;
const stagingKfaDataExportUrl = "https://api.kfadvance-stage.com/vn/integrations/yoodli" as const;
const devKfaDataExportUrl = "https://api.kfadvance-dev.com/vn/integrations/yoodli" as const;
const localKfaDataExportUrl = devKfaDataExportUrl;

const azureClientId = "39dd0dd9-b313-4fa3-bf17-28c456e738e2" as const;

const gtmContainerIdProd = "GTM-PVBLZB6" as const; // yoodli-web-prod
const gtmContainerIdDev = "GTM-WJGQC4L" as const; // yoodli-web-dev

type StringLiteral<T> = T extends string ? (string extends T ? never : T) : never;

export interface IEnvSpec<EnvName extends ReactAppEnvironment> {
  envName: StringLiteral<`${EnvName}`>;
  domain: string;
  logRocketRootHostname: string;
  logRocketServerUrl: string;
  url: {
    LANDING_PAGE: string;
    WEB_SERVER: string;
    PYTHON_SERVER: string;

    WEBSOCKET: string;
    DB: string;
    UNLEASH_PROXY?: string;

    KFA_DATA_EXPORT: string;
  };
  endUserTutorialSlug: string; // Slug of tutorial for end users (on checklist). Link will break if this slug changes.
  demoSlug: string; // Slug of Varun's demo speech for End users. Needed for end of onboarding flow. Flow will break if slug changes.
  coachTutorialSlug: string; // Slug of tutorial for coaches (on checklist). Link will break if this slug changes.
  coachDemoSlug: string; // Slug of Varun's demo speech for Coaches. Needed for end of onboarding flow. Flow will break if slug changes.
  logRocketAppId?: string;
  logRocketTracePercent: number; // [0,1] probablility of if we should trace with logrocket. Use this combined with unleash to determine tracing
  amplitudeId: string;
  gCloudClientId: string;
  azureClientId: string;
  gtmContainerId: string;
  cookieYesId?: string;
}

const productionEnv: IEnvSpec<"production"> = {
  envName: "production",
  domain: productionDomain,
  logRocketRootHostname: productionDomain,
  logRocketServerUrl: productionLogRocketServerUrl,
  url: {
    LANDING_PAGE: productionLandingPageUrl,
    WEB_SERVER: productionWebServerUrl,
    PYTHON_SERVER: productionPythonServerUrl,

    WEBSOCKET: websocketProdUrl,
    DB: dbUrl,

    KFA_DATA_EXPORT: productionKfaDataExportUrl,
  },
  endUserTutorialSlug: "NHCkSatH",
  demoSlug: "Vh7rshS1",
  coachTutorialSlug: "HaGmU6aj",
  coachDemoSlug: "WizrmFmj",
  logRocketAppId: "x1ezuu/yoodli-prod",
  logRocketTracePercent: 0.25,
  amplitudeId: "15feb2d1081dd7b5d315bdb00af9260e",
  gCloudClientId: "906567247661-ho29uesm9vkjousvv1p41gpkanum5ler.apps.googleusercontent.com",
  azureClientId: azureClientId,
  gtmContainerId: gtmContainerIdProd,
  cookieYesId: "737981da358c5c91f91588d9",
} as const;

const stagingEnv: IEnvSpec<"staging"> = {
  envName: "staging",
  domain: stagingDomain,
  logRocketRootHostname: "web.app",
  logRocketServerUrl: stagingLogRocketServerUrl,
  url: {
    LANDING_PAGE: stagingLandingPageUrl,
    WEB_SERVER: stagingWebServerUrl,
    PYTHON_SERVER: stagingPythonServerUrl,

    WEBSOCKET: websocketStagingUrl,
    DB: dbUrl,

    KFA_DATA_EXPORT: stagingKfaDataExportUrl,
  },
  endUserTutorialSlug: "NHCkSatH",
  demoSlug: "Vh7rshS1",
  coachTutorialSlug: "HaGmU6aj",
  coachDemoSlug: "WizrmFmj",
  logRocketAppId: "x1ezuu/yoodli-staging",
  logRocketTracePercent: 0.5, // 50% tracing to detect issues in staging
  amplitudeId: "7342aabcf4846873df61da8fb1513d7b",
  gCloudClientId: "832023462754-or9j12ehf8cu5mh3g7hdfp88rrluajfj.apps.googleusercontent.com",
  azureClientId: azureClientId,
  gtmContainerId: gtmContainerIdDev,
  cookieYesId: "c4bbf48313141fa34322f42d",
} as const;

const devEnv: IEnvSpec<"development"> = {
  envName: "development",
  domain: devDomain,
  logRocketRootHostname: "web.app",
  logRocketServerUrl: devLogRocketServerUrl,
  url: {
    LANDING_PAGE: devLandingPageUrl,
    WEB_SERVER: devWebServerUrl,
    PYTHON_SERVER: devPythonServerUrl,

    WEBSOCKET: websocketDevUrl,
    DB: dbUrl,

    KFA_DATA_EXPORT: devKfaDataExportUrl,
  },
  endUserTutorialSlug: "NHCkSatH",
  demoSlug: "Vh7rshS1",
  coachTutorialSlug: "HaGmU6aj",
  coachDemoSlug: "WizrmFmj",
  logRocketAppId: "x1ezuu/yoodli-dev",
  logRocketTracePercent: 0.5, // 50% tracing to detect issues in dev
  amplitudeId: "a57f5e9e35df04f2fa6b2b9308f2f865",
  gCloudClientId: "736296308109-q86l0854bjm3rker0ka8p2ivm9ki2ejr.apps.googleusercontent.com",
  azureClientId: azureClientId,
  gtmContainerId: gtmContainerIdDev,
  cookieYesId: "c4bbf48313141fa34322f42d",
} as const;

const localEnv: IEnvSpec<"local"> = {
  envName: "local",
  domain: localDomain,
  logRocketRootHostname: "localhost",
  logRocketServerUrl: localLogRocketServerUrl,
  url: {
    LANDING_PAGE: localLandingPageUrl,
    WEB_SERVER: localWebServerUrl,
    PYTHON_SERVER: localPythonServerUrl,

    WEBSOCKET: websocketLocalUrl,
    DB: dbUrl,
    // Unleash proxy needs to be named differently on the local emulator.
    UNLEASH_PROXY: dbUrl.replace("/api", "/unleashProxy/unleash/proxy"),

    KFA_DATA_EXPORT: localKfaDataExportUrl,
  },
  endUserTutorialSlug: "NHCkSatH",
  demoSlug: "Vh7rshS1",
  coachTutorialSlug: "HaGmU6aj",
  coachDemoSlug: "WizrmFmj",
  logRocketAppId: "x1ezuu/yoodli-dev",
  logRocketTracePercent: 0.0, // o% for local usage (no need to trace)
  amplitudeId: "a57f5e9e35df04f2fa6b2b9308f2f865",
  gCloudClientId: "736296308109-q86l0854bjm3rker0ka8p2ivm9ki2ejr.apps.googleusercontent.com",
  azureClientId: azureClientId,
  gtmContainerId: gtmContainerIdDev,
  cookieYesId: "c4bbf48313141fa34322f42d",
} as const;

const clientEnvs: { [name in ReactAppEnvironment]: IEnvSpec<name> } = {
  local: localEnv,
  development: devEnv,
  staging: stagingEnv,
  production: productionEnv,
} as const;

export const inBrowser = typeof window !== "undefined";

/**
 * Get client config. By default, looks at `process` and `window` properties
 * that should exist in React and NextJS apps; but, these can be passed
 * in if it is called in other contexts.
 * @param options
 */
// Fixme: define return type. Currently ignoring "Missing return type on function"
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getClientEnvConfig<EnvName extends ReactAppEnvironment>(
  options: {
    reactAppEnvironment?: EnvName;
    host?: string;
  } = {}
) {
  let reactAppEnvironment = getReactAppEnvironment(options.reactAppEnvironment);
  const host = options.host ?? (inBrowser ? window.location.host : "");
  if (host.includes("localhost:") && reactAppEnvironment !== "development") {
    reactAppEnvironment = "local";
  }
  return clientEnvs[reactAppEnvironment];
}

// This function has a long name and lines tend to be longer.
// Some Prettier auto fomrats causes worse readability.
// prettier-ignore
export function getReactAppEnvironment(
  reactAppEnvironment?: ReactAppEnvironment
): ReactAppEnvironment {
  // If reactAppEnvironment is not passed (most of the cases), we get it from another source.
  if (!reactAppEnvironment) {
    // eslint-disable-next-line no-prototype-builtins
    if (!globalThis.hasOwnProperty("inElectronRenderer")) {
      // Use process.env.REACT_APP_ENVIRONMENT except Electron rendering process.
      // In node.js processes (backend and Electron main process), this is a real property.
      // In web client, Next.js build embeds it as a literal string (though it's tricky where
      // local envrionment generates process object somehow by the framework.)
      reactAppEnvironment = process.env.REACT_APP_ENVIRONMENT as ReactAppEnvironment;
    } else {
      // Electron rendering process gets it from the attribute which is passed
      // as a query parameter of URL from the main process.

      // Becuase this method is called very frequently, we do this only once.
      // Combining two tricks here:
      // https://stackoverflow.com/a/1535650/2615326 (a property of a funciton object)
      // https://stackoverflow.com/a/12832307/2615326 (skipping TS type check)

      if (typeof getReactAppEnvironment["defaultEnvironment"] === "undefined") {
        const searchParams = new URL(location.href).searchParams;
        const reactAppEnvironmentFromParams = searchParams.get("reactAppEnvironment");
        if (reactAppEnvironmentFromParams) {
          console.log(`Set defaultEnvironment as ${reactAppEnvironmentFromParams} which was passed by Electron main process`);
          getReactAppEnvironment["defaultEnvironment"] = reactAppEnvironmentFromParams as ReactAppEnvironment;
        } else {
          // This should not happen, but use production for the timebeing
          // in order to avoid an immediate crash in the field if we miss any
          // corner case during our test. We should remove this in the future.
          console.warn(`reactAppEnvironment is unavailable in ${location}. Fallback to production.`);
          console.warn(new Error().stack);
          getReactAppEnvironment["defaultEnvironment"] = "production";
        }
      }
      reactAppEnvironment = getReactAppEnvironment["defaultEnvironment"];
    }
  }

  switch (reactAppEnvironment) {
    case "local":
    case "development":
    case "staging":
    case "production":
      return reactAppEnvironment;

    default:
      throw Error(
        `Could not determine environment. Please set ` +
          `REACT_APP_ENVIRONMENT to one of "local" | "development" ` +
          `| "staging" | "production". Current val is ` +
          ` ${reactAppEnvironment}.`
      );
  }
}

export function getAppEnv(reactAppEnvironment?: ReactAppEnvironment): AppEnv {
  if (!reactAppEnvironment) {
    reactAppEnvironment = getReactAppEnvironment();
  }
  switch (reactAppEnvironment) {
    case "local":
      return AppEnv.Local;
    case "development":
      return AppEnv.Development;
    case "staging":
      return AppEnv.Staging;
    case "production":
      return AppEnv.Production;
    default:
      throw Error(`Could not determine environment from ${reactAppEnvironment}`);
  }
}

export const getFirebaseConfig = (environment?: ReactAppEnvironment): Record<string, string> => {
  if (!environment) {
    environment = getClientEnvConfig().envName;
  }
  if (environment === "development" || environment === "local") {
    return {
      apiKey: "AIzaSyAt1YQXrxLZxpXAd9e3rLbPcR_-tlruBPI",
      authDomain: "identityplatform-dev.yoodli.ai",
      projectId: "yoodli-web-dev",
      storageBucket: "yoodli-web-dev.appspot.com",
      messagingSenderId: "736296308109",
      appId: "1:736296308109:web:7a663ebb71e5ff6e01c1c4",
      measurementId: "G-495M41GT3S",
    } as const;
  } else if (environment === "staging") {
    return {
      apiKey: "AIzaSyCMMauFaN8433WMhLM2ZgqPLO30X0uCatU",
      authDomain: "identityplatform-staging.yoodli.ai",
      databaseURL: "https://yoodli-web-default-rtdb.firebaseio.com",
      projectId: "yoodli-web",
      storageBucket: "yoodli-web.appspot.com",
      messagingSenderId: "832023462754",
      appId: "1:832023462754:web:0b7d84f46655e12e10223b",
      measurementId: "G-8QT0YRLH6W",
    } as const;
  } else if (environment === "production") {
    return {
      apiKey: "AIzaSyD2gcCD-odsbJj4QLWs_RB4pgWkHybD7Xg",
      authDomain: `identityplatform.${productionDomain}`,
      projectId: "yoodli-web-prod",
      storageBucket: "yoodli-web-prod.appspot.com",
      messagingSenderId: "906567247661",
      appId: "1:906567247661:web:951aa597a67ef8935295a4",
      measurementId: "G-S3MG6QR1T6",
    } as const;
  }
};

export function isLocal(): boolean {
  return getReactAppEnvironment() === "local";
}

export function isDev(): boolean {
  return getReactAppEnvironment() === "development";
}

export function isStaging(): boolean {
  return getReactAppEnvironment() === "staging";
}

export function isProd(): boolean {
  return getReactAppEnvironment() === "production";
}

// See documentation at: https://docs.sentry.io/clients/javascript/config/
// Either strings or RegExp can be provided, strings work for partial matches
export const sharedFrontendSentryErrorsToIgnore: (string | RegExp)[] = [
  /Network Error/i,
  /Fetch Error/i,
];

/**
 * Gets the app version (git commit hash) from the environment variables
 * Not applicable for Poodli (electron) - for that, use the electron version
 * @returns App version string
 */
export function getAppVersion(): string {
  // eslint-disable-next-line no-prototype-builtins
  if (globalThis.hasOwnProperty("inElectronRenderer")) {
    throw "getAppVersion is not compatible with electron";
  }
  return process.env.REACT_APP_VERSION ?? "unknown";
}

export enum HubSpotFormName {
  ContactSales = "contact_sales",
  CoachDirectoryApplication = "coach_directory_application",
}

type HubSpotForms = {
  [name in HubSpotFormName]: string;
};

/**
 * Environment-specific HubSpot configuration.
 */
export type HubSpotFrontendEnvironmentConfiguration = {
  /**
   * HubSpot portal ID. This is our HubSpot account number.
   */
  portalId: string;
  /**
   * HubSpot form IDs.
   */
  forms: HubSpotForms;
  /**
   * Yoodli instance model ID.
   */
  yoodliInstanceModelId: string;
};

const sandBoxHubSpotEnv: HubSpotFrontendEnvironmentConfiguration = {
  portalId: "46885677",
  yoodliInstanceModelId: "2-32593122",
  forms: {
    [HubSpotFormName.ContactSales]: "8f54615d-36b0-45a2-b902-3b0dba5c36ef",
    [HubSpotFormName.CoachDirectoryApplication]: "bbb9ba8b-62bb-4ab5-9c4f-8d62d19a1a8d",
  },
};

const HUBSPOT_FRONTEND_ENVIRONMENTS: {
  [environment in AppEnv]: HubSpotFrontendEnvironmentConfiguration;
} = {
  // HubSpot development and staging sandbox environment.
  [AppEnv.Local]: sandBoxHubSpotEnv,
  [AppEnv.Development]: sandBoxHubSpotEnv,
  [AppEnv.Staging]: sandBoxHubSpotEnv,
  // HubSpot production environment.
  [AppEnv.Production]: {
    portalId: "24205305",
    yoodliInstanceModelId: "2-30807738",
    forms: {
      [HubSpotFormName.ContactSales]: "d24ea434-9798-41bb-901d-4efa5ad036d4",
      [HubSpotFormName.CoachDirectoryApplication]: "cb1dc0ca-e5dd-4e3e-9c3a-014027f25b95",
    },
  },
};

/**
 * Get environment-specific HubSpot configuration.
 *
 * @param env Environment.
 * @returns Environment-specific HubSpot configuration.
 */
export function getHubSpotFrontendEnvConfig(env: AppEnv): HubSpotFrontendEnvironmentConfiguration {
  return HUBSPOT_FRONTEND_ENVIRONMENTS[env];
}
