import firebase from "firebase/app";
import React from "react";

// Components
import { Box, Stack, Typography, Pagination, CircularProgress, Link } from "@mui/material";
import { TableFilter } from "components/VideoJournal/TableComponents/TableFilter";
import { TableSortServer } from "components/VideoJournal/TableComponents/TableSortServer";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import { EventSeat } from "@mui/icons-material";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { MEMBER_COUNT_HEIGHT, OrgMembersQueryKeys } from "./OrgMembers";
import {
  keepPreviousData,
  useQuery as useApiQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
  PaginationState,
} from "@tanstack/react-table";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  deleteOrgUserV2,
  listOrgUsersV2,
  updateOrgUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { SupportPages } from "lib-frontend/utils/paths";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import {
  GetOrgMemberListField,
  GetOrgMemberListSortOption,
  HubMemberResponse,
  OrgMemberResponse,
} from "lib-fullstack/api/orgApiTypes";
import { MAX_LICENSED_USERS_RESPONSE, ORG_MEMBER_FETCH_LIMIT } from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { HubRole, OrgRole, EffectiveRole } from "lib-fullstack/utils/enums";
import { useHistory } from "react-router";
import { WebServerInternalPath } from "utils/paths";
import { OrgHubsQueryParams } from "lib-frontend/utils/queryParams";
import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type OrgMembersTableProps = {
  searchText: string;
  openManageRoleWizard: (user: OrgMemberResponse) => void;
  recalcTopContentHeight: () => void;
};

enum MenuAction {
  ManageRole = "manage_role",
  TransferOwnership = "transfer_ownership",
  RemoveFromOrg = "remove_from_org",
}

export type OrgMembersTableRow = {
  name: string;
  role: string;
  joined_hubs: HubMemberResponse[];
  email: string;
  date_joined: string;
  date_last_activity: string;
  action: null;
  seat_inactive: boolean;
};

const parseOrgRole = (role?: string): string => {
  switch (role) {
    case OrgRole.OWNER:
      return "Org owner";
    case OrgRole.ADMIN:
      return "Org admin";
    case HubRole.ADMIN:
      return "Group admin";
    default:
      return "Member";
  }
};

const parseDateString = (dateStr: string) => {
  return getHumanReadableDate(dateStr, {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

const FILTER_NAMES = {
  role: [
    { label: "Org owner", value: "org_owner" },
    { label: "Org admin", value: "org_admin" },
    { label: "Group admin", value: "hub_admin" },
    { label: "Member", value: "hub_member" },
  ],
};

export default function MembersTable({
  searchText,
  openManageRoleWizard,
  recalcTopContentHeight,
}: OrgMembersTableProps): JSX.Element {
  const queryClient = useQueryClient();
  const history = useHistory();
  const userOrgContext = React.useContext(UserOrgContext);
  const isMediumScreen = useIsMediumScreen();
  const columnHelper = createColumnHelper<OrgMembersTableRow>();
  const [openRemoveUserModal, setOpenRemoveUserModal] = React.useState<boolean>(false);
  const [openTransferOwnershipModal, setOpenTransferOwnershipModal] =
    React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: ORG_MEMBER_FETCH_LIMIT,
  });
  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgMemberListSortOption.EMAIL_ASC as string
  );
  const [roleFilters, setRoleFilters] = React.useState<string>(undefined);
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();

  const { showNotificationBanner } = useNotification();

  const anyFilterEnabled = React.useMemo(() => {
    return roleFilters?.length > 0 || searchText?.length > 0;
  }, [roleFilters, searchText]);

  const membersQueryResult = useApiQuery({
    queryKey: [
      OrgMembersQueryKeys.OrgMembers,
      userOrgContext.orgId,
      {
        start: paginationStartInd,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : sortFilter ?? GetOrgMemberListSortOption.EMAIL_ASC,
        effective_roles: roleFilters,
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listOrgUsersV2(userOrgContext.orgId, {
        start: paginationStartInd,
        limit: ORG_MEMBER_FETCH_LIMIT.toString(),
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : (sortFilter as GetOrgMemberListSortOption) ?? GetOrgMemberListSortOption.EMAIL_ASC,
        effective_roles: roleFilters,
        fields: GetOrgMemberListField.HUBS,
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
  });

  // redefine the member list whenever the data changes
  const membersList = React.useMemo(
    () => membersQueryResult.data?.users ?? [],
    [membersQueryResult.data?.users]
  );
  React.useEffect(() => {
    recalcTopContentHeight();
  }, [membersList]);

  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);

  const handleMenuItemClick = async (row: Row<OrgMembersTableRow>, action: MenuAction) => {
    const user = membersList.find((user) => user.email === row.original.email);
    setSelectedUser({ ...user });
    switch (action) {
      case MenuAction.ManageRole:
        openManageRoleWizard(user);
        break;
      case MenuAction.TransferOwnership:
        setOpenTransferOwnershipModal(true);
        break;
      case MenuAction.RemoveFromOrg:
        setOpenRemoveUserModal(true);
        break;
    }
  };

  const transferOwnershipMutation = useMutation({
    mutationFn: async () => {
      if (selectedUser.user_id !== firebase.auth().currentUser?.uid) {
        await updateOrgUserV2(userOrgContext.defaultOrg.id, selectedUser.user_id, {
          role: OrgRole.OWNER,
        });
        await updateOrgUserV2(userOrgContext.defaultOrg.id, "me", { role: OrgRole.ADMIN });
      }
    },
    onSuccess: () => {
      showNotificationBanner(
        <>
          Ownership transferred to <strong>{selectedUser.name}</strong>
        </>,
        {
          variant: YoodliNotificationBannerVariant.Success,
        }
      );
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgMembers, userOrgContext.orgId],
      });
    },
    onError: (e: Error) => {
      showNotificationBanner(
        <>
          Failed to transfer ownership to <strong>{selectedUser.name}</strong>
        </>,
        {
          variant: YoodliNotificationBannerVariant.Danger,
        }
      );
      console.log("Error transfering org ownership: ", e);
    },
  });

  const removeMemberMutation = useMutation({
    mutationFn: async () => {
      await deleteOrgUserV2(userOrgContext.defaultOrg.id, selectedUser.user_id);
    },
    onSuccess: () => {
      void userOrgContext.invalidateDefaultOrgQuery();
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgMembers, userOrgContext.orgId],
      });
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, userOrgContext.orgId],
      });
    },
    onError: (e: Error) => {
      console.log("Error removing org member: ", e);
    },
  });

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Members</Typography>,
        cell: (info) => {
          const titleProps = {
            onClick: async () => {
              await handleMenuItemClick(info.row, MenuAction.ManageRole);
            },
            sx: {
              cursor: "pointer",
              fontWeight: 700,
              overflow: "hidden",
              width: "fit-content",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          };
          return (
            <Stack direction="row" gap={2} sx={{ alignItems: "center", pr: 0.5 }}>
              <YoodliAvatar
                name={info.getValue()}
                sx={{
                  ml: { xs: 3, md: 5 },
                  my: 2.5,
                }}
              />
              <Stack
                sx={{
                  maxWidth: { xs: "150px", md: "300px" },
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: getDynamicColor("purple3"),
                }}
              >
                <Typography {...titleProps} className="column-text">
                  {info.getValue()}
                </Typography>
                <Typography {...titleProps} className="column-text">
                  {info.row.original.email}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      }),
      ...(!isMediumScreen
        ? [
            columnHelper.accessor("date_last_activity", {
              id: "date_last_activity",
              header: () => <>Last active</>,
              cell: (info) => (
                <YoodliTooltip title={info.getValue() && getHumanReadableDate(info.getValue(), {})}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        color: getDynamicColor("dark5"),
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      className="column-text"
                    >
                      {getTimeDifference(info.getValue())}
                    </Typography>
                    {info.row.original.seat_inactive && (
                      <Stack direction="row" sx={{ gap: 0.5 }}>
                        <EventSeat
                          sx={{ width: 13, height: 13, color: getDynamicColor("dark4") }}
                        />
                        <Typography
                          sx={{
                            color: getDynamicColor("dark4"),
                            fontFamily: "poppins",
                            fontSize: "10px",
                            fontWeight: 600,
                          }}
                        >
                          Inactive
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </YoodliTooltip>
              ),
            }),
            columnHelper.accessor("date_joined", {
              id: "date_joined",
              header: () => <>Joined</>,
              cell: (info) => (
                <Typography
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  className="column-text"
                >
                  {parseDateString(info.getValue())}
                </Typography>
              ),
            }),
            columnHelper.accessor("role", {
              id: "role",
              header: () => <>Role</>,
              cell: (info) => (
                <Typography
                  className="column-text"
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {info.getValue()}
                </Typography>
              ),
              filterFn: "arrIncludesSome",
            }),
            columnHelper.accessor("joined_hubs", {
              id: "joined_hubs",
              header: () => <>Groups</>,
              cell: (info) => (
                <Typography
                  sx={{
                    maxWidth: "300px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: getDynamicColor("primary"),
                  }}
                >
                  {info.getValue().map((hub, index) => (
                    <React.Fragment key={`${hub.hub_id}-${index}`}>
                      <Link
                        onClick={() =>
                          history.push(
                            `${WebServerInternalPath.ORG_GROUPS}?${OrgHubsQueryParams.GROUP_ID}=${hub.hub_id}`
                          )
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        {hub.name}
                      </Link>
                      {index !== info.getValue().length - 1 && <>, </>}
                    </React.Fragment>
                  ))}
                </Typography>
              ),
            }),
          ]
        : []),
      columnHelper.accessor("action", {
        id: "action",
        header: () => <div style={{ width: "36px" }} />,
        cell: (info) =>
          info.row.original.email !== firebase.auth().currentUser.email &&
          info.row.original.role !== parseOrgRole(OrgRole.OWNER) && (
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              menuItems={[
                {
                  title: "Manage role",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.ManageRole),
                  type: YoodliMenuItemType.Default,
                },
                userOrgContext.defaultOrg.effective_role === EffectiveRole.ORG_OWNER && {
                  title: "Transfer org ownership",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.TransferOwnership),
                  type: YoodliMenuItemType.Warning,
                },
                {
                  title: "Remove from organization",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.RemoveFromOrg),
                  type: YoodliMenuItemType.Warning,
                },
              ].filter(Boolean)}
            />
          ),
      }),
    ],
    [membersList, isMediumScreen]
  );

  const filteredColumns = columns.filter(Boolean);
  const rowData = React.useMemo(
    () =>
      (membersList ?? []).map((user) => ({
        name: user.name,
        role: parseOrgRole(user.effective_role ?? user.role),
        joined_hubs: user.hubs,
        email: user.email,
        date_joined: user.date_joined,
        date_last_activity: user.date_last_activity,
        action: null,
        seat_inactive: user.seat_inactive,
      })),
    [membersList]
  );

  const table = useReactTable({
    data: rowData,
    columns: filteredColumns,
    initialState: {
      pagination,
    },
    rowCount: membersQueryResult.data?.total,
    getCoreRowModel: getCoreRowModel(),
  });

  const firstItem = parseInt(paginationStartInd) + 1;
  const lastItem = Math.min(
    parseInt(paginationStartInd) + pagination.pageSize,
    table.getRowCount()
  );

  const loading = [transferOwnershipMutation, removeMemberMutation].some(
    (mutation) => mutation.isPending
  );

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page - 1, 0) };
    });
  };
  return (
    <Stack
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      {membersQueryResult.isPending ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Box
          sx={{
            minHeight: membersList?.length > 4 ? 400 : "unset",
            overflowY: "auto",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: 2,
          }}
        >
          <table
            style={{
              width: "100%",
              boxShadow: Y_SHADOWS.dark_elevation,
              borderCollapse: "collapse",
              borderRadius: 2,
              borderSpacing: "0px",
            }}
          >
            <thead
              style={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "8px",
                height: "40px",
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Box
                  component="tr"
                  key={headerGroup.id}
                  sx={{
                    width: "100%",
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        borderBottom: "1px solid lightgrey",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: getDynamicColor("light1"),
                        borderRadius: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                          color: getDynamicColor("dark5"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 600,
                          mr: 1,
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {["name", "last_active", "date_joined", "date_last_activity"].includes(
                          header.id
                        ) && <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />}
                        {["role"].includes(header.id) && (
                          <TableFilter
                            setFilterState={setRoleFilters}
                            names={FILTER_NAMES[header.id]}
                            searchable={false}
                          />
                        )}
                      </Stack>
                      {/* Note: the reason the <TanbleFilters> are here instead of as a columnHelper is because when placed in a columnHelper,
                                  it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.
                              */}
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box
                    component="tr"
                    key={row.id}
                    sx={{
                      width: "max-content",
                      borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                      "&:last-of-type": {
                        borderBottomLeftRadius: 2,
                        borderBottomRightRadius: 2,
                        borderBottom: "unset",
                      },
                      "& .column-text": {
                        color: row.original.seat_inactive && getDynamicColor("dark4"),
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </Box>
                );
              })}
              {table.getRowCount() === 0 && !anyFilterEnabled && (
                <tr>
                  <td colSpan={columns.length}>
                    <Stack
                      direction="column"
                      sx={{
                        mx: "auto",
                        width: "100%",
                        color: getDynamicColor("dark4"),
                        minHeight: "250px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>No members yet</Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        Invite members to join this organization using the buttons above
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      )}
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <Pagination
            sx={{ justifyContent: "center" }}
            count={table.getPageCount()}
            showFirstButton
            showLastButton
            page={pagination.pageIndex + 1}
            onChange={(_, page) => handlePageChange(page)}
          />
        )}
        {table.getRowCount() > 0 && (
          <Typography
            sx={{
              mt: 0.5,
              color: getDynamicColor("dark4"),
              fontSize: "12px",
              textAlign: "center",
              height: MEMBER_COUNT_HEIGHT,
            }}
          >
            {`Displaying members ${firstItem} - ${lastItem} of ${table.getRowCount()}`}
          </Typography>
        )}
        {membersList.length === MAX_LICENSED_USERS_RESPONSE &&
          userOrgContext.defaultOrg.license_used > membersList.length && (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "center",
                pt: 3.5,
                pb: 6.5,
              }}
            >
              First {membersList.length} of {userOrgContext.defaultOrg.license_used} members
            </Typography>
          )}
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to remove{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{selectedUser?.name}</strong>{" "}
              from{" "}
              <strong style={{ color: getDynamicColor("primary") }}>
                {userOrgContext.defaultOrg?.name}
              </strong>
              ?
            </>
          ),
          subtitle:
            "This will remove them from all groups they are part of and will release an organization seat.",
        }}
        open={openRemoveUserModal}
        theme={YoodliCtaModalTheme.Danger}
        loading={loading}
        hideCloseButton={true}
        close={() => setOpenRemoveUserModal(false)}
        buttons={
          {
            primary: { text: "Remove from org", handler: removeMemberMutation.mutateAsync },
            secondary: { text: "Cancel", handler: () => setOpenRemoveUserModal(false) },
          } as CtaButtonHandlers
        }
      />
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Would you like to transfer ownership of this organization to{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{selectedUser?.name}</strong>?
            </>
          ),
          subtitle:
            "They will be granted full administrative permissions including managing admin and members and managing content.",
        }}
        open={openTransferOwnershipModal}
        theme={YoodliCtaModalTheme.Primary}
        hideCloseButton={true}
        loading={loading}
        close={() => {
          setOpenTransferOwnershipModal(false);
        }}
        buttons={
          {
            primary: { text: "Confirm", handler: transferOwnershipMutation.mutate },
            secondary: {
              text: "Learn more about ownership",
              handler: () => {
                window.open(SupportPages.ORG_OVERVIEW_OWNERSHIP, "_blank");
              },
              variant: "text",
              buttonSx: { color: getDynamicColor("primary") },
            },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
}
