import firebase from "firebase/app";
import React from "react";

// Components
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  WizardState,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CustomizePracticeTabSelector } from "components/Orgs/ManageContent/CustomizePractice/CustomizePracticeTabSelector";
import { InterviewsTab } from "components/Orgs/ManageContent/CustomizePractice/InterviewBanks/InterviewsTab";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllPersonasAdmin } from "lib-frontend/modules/AxiosInstance";
import { GetScenarioResponse, InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { HubModalStatus } from "utils/Enums";
import { ScenariosTab } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/ScenariosTab";
import { PersonasTab } from "components/Orgs/ManageContent/CustomizePractice/Personas/PersonasTab";
import { CustomGoalLibrary } from "../CustomGoals/CustomGoalLibrary";
import { OrgSubscriptionType } from "lib-fullstack/utils/enums";

type CustomizePracticeTabProps = {
  selectedOrg: OrgV2Response;
  selectedOrgInterviewBanks: InterviewBankResponse[];
  setModalStatus: React.Dispatch<React.SetStateAction<HubModalStatus>>;
  setSelectedInterviewBank: React.Dispatch<React.SetStateAction<InterviewBankResponse | null>>;
  setShouldBlockNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBackHeader: React.Dispatch<React.SetStateAction<boolean>>;
  showBackHeader: boolean;
  showScenarioTemplates: boolean;
  setShowScenarioTemplates: React.Dispatch<React.SetStateAction<boolean>>;
  templateSelected: GetScenarioResponse | null;
  setTemplateSelected: React.Dispatch<React.SetStateAction<GetScenarioResponse | null>>;
};

export enum CustomizePracticeTabEnum {
  Scenarios = "scenarios",
  Personas = "personas",
  CustomGoals = "custom_goals",
  InterviewBanks = "interview_banks",
}

export const CustomizePracticeTabLabels = {
  [CustomizePracticeTabEnum.Scenarios]: "Roleplay Scenarios",
  [CustomizePracticeTabEnum.CustomGoals]: "Custom Goals",
  [CustomizePracticeTabEnum.Personas]: "Personas",
  [CustomizePracticeTabEnum.InterviewBanks]: "Interview Banks",
};

export const CustomizePracticeTab = ({
  selectedOrg,
  selectedOrgInterviewBanks,
  setModalStatus,
  setSelectedInterviewBank,
  setShouldBlockNav,
  setShowBackHeader,
  showBackHeader,
  showScenarioTemplates,
  setShowScenarioTemplates,
  templateSelected,
  setTemplateSelected,
}: CustomizePracticeTabProps): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState<CustomizePracticeTabEnum>(
    CustomizePracticeTabEnum.Scenarios
  );

  const { defaultOrg } = React.useContext(UserOrgContext);
  const tabs = Object.values(CustomizePracticeTabEnum);
  if (defaultOrg?.subscription_type === OrgSubscriptionType.PAYG) {
    tabs.splice(tabs.indexOf(CustomizePracticeTabEnum.CustomGoals), 1);
  }

  // fetch personas once here so i can use the same query data whether within the wizard, or the personas tab
  useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
    queryFn: async () => (await getAllPersonasAdmin(defaultOrg?.id))?.personas,
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg,
    refetchOnWindowFocus: false,
  });

  const [hideTabsState, setHideTabsState] = React.useState<boolean>(false);

  const [personaIsSelected, setPersonaIsSelected] = React.useState<boolean>(false);
  const [customGoalIsSelected, setCustomGoalIsSelected] = React.useState<boolean>(false);

  const [wizardState, setWizardState] = React.useState<WizardState>("create");

  // optionally allow scernarioId to be overwritten, this is so when selecting a template
  // we can overwrite the default id (i.e. cold_sales_call) with the newly created scenario-to-update's id
  const handleTemplateSelected = (scenario: GetScenarioResponse | null, scenarioId?: string) => {
    let newScenario = scenario;
    if (scenarioId) {
      newScenario = { ...scenario, id: scenarioId };
    }
    setTemplateSelected(newScenario);
    setShowBackHeader(!!newScenario);
  };

  const handlePersonaIsSelected = (selected: boolean) => {
    setPersonaIsSelected(selected);
    setShowBackHeader(selected);
  };

  const handleCustomGoalIsSelected = (selected: boolean) => {
    setShowBackHeader(selected);
    setCustomGoalIsSelected(selected);
  };

  // reset the selected template whenever the user closes the scenario wizard
  React.useEffect(() => {
    if (!showScenarioTemplates) {
      handleTemplateSelected(null);
    }
  }, [showScenarioTemplates]);

  const hideTabs =
    (hideTabsState ||
      templateSelected ||
      personaIsSelected ||
      customGoalIsSelected ||
      showScenarioTemplates) &&
    showBackHeader;

  React.useEffect(() => {
    if (templateSelected) {
      setShouldBlockNav(templateSelected.isTemplate || ["duplicate", "edit"].includes(wizardState));
    }
  }, [templateSelected, wizardState]);

  const renderLoader = () => {
    return (
      <Box
        sx={{
          mx: "auto",
          py: 5,
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const renderScenariosTab = () => {
    return (
      <ScenariosTab
        selectedOrg={selectedOrg}
        templateSelected={templateSelected}
        handleTemplateSelected={handleTemplateSelected}
        setShouldBlockNav={setShouldBlockNav}
        wizardState={wizardState}
        setWizardState={setWizardState}
        allHubs={defaultOrg.hubs}
        renderLoader={renderLoader}
        showBackHeader={showBackHeader}
        setShowBackHeader={setShowBackHeader}
        showScenarioTemplates={!!showScenarioTemplates}
        setShowScenarioTemplates={setShowScenarioTemplates}
        setHideTabs={setHideTabsState}
      />
    );
  };
  const renderPersonasTab = () => {
    return (
      <PersonasTab
        setPersonaIsSelected={handlePersonaIsSelected}
        renderLoader={renderLoader}
        showBackHeader={showBackHeader}
      />
    );
  };
  const renderInterviewBanksTab = () => {
    return (
      <InterviewsTab
        interviewBanks={selectedOrgInterviewBanks}
        setModalStatus={setModalStatus}
        setSelectedInterviewBank={setSelectedInterviewBank}
        entityName={selectedOrg?.name}
        renderLoader={renderLoader}
        // The user is on the Organization Settings page, which means they have to be an Owner of the Organization & can update banks & hubs freely
        canUpdateBank
        showBankHubsDetails
      />
    );
  };

  const renderCustomGoalsTab = () => {
    return (
      <CustomGoalLibrary
        handleCustomGoalIsSelected={handleCustomGoalIsSelected}
        showBackHeader={showBackHeader}
      />
    );
  };

  return (
    <Stack
      gap={{ xs: 0, md: 4 }}
      sx={{
        p: { xs: 0, md: 4, lg: 5 },
        pb: { xs: 2, md: 6, lg: 8 },
      }}
    >
      {!hideTabs && (
        <CustomizePracticeTabSelector
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      {tabs.map((tab) => {
        return (
          <Box
            key={tab}
            sx={{
              display: activeTab === tab ? "initial" : "none",
            }}
          >
            {tab === CustomizePracticeTabEnum.Scenarios && renderScenariosTab()}
            {tab === CustomizePracticeTabEnum.Personas && renderPersonasTab()}
            {tab === CustomizePracticeTabEnum.CustomGoals && renderCustomGoalsTab()}
            {tab === CustomizePracticeTabEnum.InterviewBanks && renderInterviewBanksTab()}
          </Box>
        );
      })}
    </Stack>
  );
};
